<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Inertia } from "@inertiajs/inertia";
import { usePage, Link } from "@inertiajs/inertia-vue3";
import ModalBlank from "@/Components/Modal/ModalBlank.vue";
import { useLocalStorage } from "@tools/app";
import {
  B2C_FORGOT_PASSWORD_ERROR_ID,
  B2C_CANCEL_ERROR_ID,
  CAMPAIGN_CURRENT,
  ACTION_SIGNUP,
} from "@constants/main";
import {
  LOCAL_STORAGE_ACCOUNT_ID_KEY_NAME,
  LOCAL_STORAGE_ACCESSTOKEN_KEY_NAME,
  LOCAL_STORAGE_IDTOKEN_KEY_NAME,
} from "@constants/localStorage";
import Button from "@/Components/Button.vue";
import App from "@/Layouts/App.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const $store = useStore();
const $page = usePage();
const $localStorage = useLocalStorage();
const isError = ref(false);
const unknownPolicyCallback = (response) => { };
const signUpSignInPolicyCallback = async (response) => {
  try {
    const acr = response.idTokenClaims.acr;
    let currentCampaign = null;
    let currentCampaignUrl = null;
    if (
      acr === $store.state.app.azure.config.policyNames.signUpSignIn.realLeaf
    ) {
      currentCampaign = "realLeaf";
      currentCampaignUrl = route("realLeaf.landing");
    } else if (
      acr === $store.state.app.azure.config.policyNames.signUpSignIn.cokeZero
    ) {
      currentCampaign = "cokeZero";
      currentCampaignUrl = route("ocp.mainDashboard");
    }
    const res = await $store.dispatch("login", {
      msal: $store.state.app.msal,
      accountId: response.account.homeAccountId,
      accessToken: response.accessToken,
      idToken: response.idToken,
      api_auth_email: $page?.props?.value?.api_data?.api_auth_email,
      api_auth_password: $page?.props?.value?.api_data?.api_auth_password,
      utm: {
        utm_source: $localStorage.get("qrCode") ? "google" : "direct",
        utm_medium: $localStorage.get("qrCode") ? "qrcode" : "direct",
        utm_campaign: currentCampaign || "",
        utm_term: $localStorage.get("qrCode") || "",
        utm_content: $localStorage.get("qrCodeUrl") || currentCampaignUrl || "",
      },
    });

    if (res.success) {
      $localStorage.remove(CAMPAIGN_CURRENT);
      !res.user_exists && $localStorage.set("action", ACTION_SIGNUP);
      switch (currentCampaign) {
        case "realLeaf":
          const code = localStorage.getItem("qrCode");
          if (code) {
            localStorage.removeItem("qrCode");
            Inertia.visit(route("realLeaf.scan", { code }));
          } else {
            console.log("realLeaf.landing");
            Inertia.visit(route("realLeaf.landing"));
          }
          break;
        case "cokeZero":
          window.location.href = `${window.location.origin}/zs/landing/index.html`;
          break;
        default:
          Inertia.visit(route("ocp.mainDashboard"));
          break;
      }
    }
  } catch (error) {
    console.error(error.message);
  }
};
const forgotPasswordCallback = (response) => { };
const editProfileCallback = async (response) => {
  try {
    const res = await $store.dispatch("login", {
      msal: $store.state.app.msal,
      accountId: response.account.homeAccountId,
      accessToken: response.accessToken,
      idToken: response.idToken,
      api_auth_email: $page?.props?.value?.api_data?.api_auth_email,
      api_auth_password: $page?.props?.value?.api_data?.api_auth_password,
      event: 'event_editprofile'
    });

    Inertia.visit(route('member.myProfile'))
  } catch (error) {
    console.error(error.message);
  }
}
const goToForgotPasswordPage = () => { };
const goToLoginPage = () => { };

const handle = () => {
  console.log("Start to handle redirect.");
  $store.state.app.msal
    .handleRedirectPromise()
    .then(async (response) => {
      console.log(response);
      console.log(JSON.stringify($store.state.app.msal.getAllAccounts()));
      if (response) {
        console.groupCollapsed("Get MSAL response.");
        console.log("Response: ", response);
        console.log(
          `response.idTokenClaims.acr: ${response.idTokenClaims.acr}`
        );
        console.groupEnd();

        console.log(response.idTokenClaims.acr);

        switch (response.idTokenClaims.acr) {
          case $store.state.app.azure.config.policyNames.signUpSignIn.cokeZero:
          case $store.state.app.azure.config.policyNames.signUpSignIn.realLeaf:
            await signUpSignInPolicyCallback(response);
            break;

          case $store.state.app.azure.config.policyNames.forgotPassword:
            forgotPasswordCallback(response);
            goToLoginPage();
            break;

          case $store.state.app.azure.config.policyNames.editProfile:
            editProfileCallback(response);
            break;

          default:
            unknownPolicyCallback(response);
            break;
        }
      }

      // let idToken = await refreshMsalToken(root, $store.state.auth.msal_account, false);

      // console.groupCollapsed(console.formatting('Member Redirect'), 'Get refresh token.');
      // console.log(idToken);
      // console.groupEnd();

      // if (idToken === 'logout') {
      //     return logoutWithRedirectionUrl();
      // }

      // $localStorage.set(LOCAL_STORAGE_IDTOKEN_KEY_NAME, idToken);
      // $store.dispatch('getMemberInfo').catch((error) => {
      //     console.groupCollapsed(console.formatting('Member Redirect'), 'Failed to get member data.');
      //     console.error(error);
      //     console.groupEnd();
      // });
    })
    .catch((error) => {
      console.error(error);
      $localStorage.remove(LOCAL_STORAGE_ACCOUNT_ID_KEY_NAME);
      $localStorage.remove(LOCAL_STORAGE_ACCESSTOKEN_KEY_NAME);
      $localStorage.remove(LOCAL_STORAGE_IDTOKEN_KEY_NAME);
      isError.value = false;
      const currentCampaign = $localStorage.get(CAMPAIGN_CURRENT) || '';
      if (error.errorMessage) {
        if (error.errorMessage.indexOf(B2C_FORGOT_PASSWORD_ERROR_ID) > -1) {
          goToForgotPasswordPage();
        } else if (error.errorMessage.indexOf(B2C_CANCEL_ERROR_ID) > -1) {
          Inertia.visit(route("member.register"), { mission: currentCampaign });
          // } else if (error.errorMessage.indexOf(B2C_CANCEL_ERROR_ID) > -1) {
          //     goToLoginPage();
          // } else if (error.errorMessage.indexOf(B2C_REDIRECT_ERROR_ID) > -1) {
          //     console.error(console.formatting('Member Redirect'), error.errorMessage);
          // } else if (error.errorMessage.indexOf(B2C_MISS_TOKE_ERROR_CODE) > -1) {
          //     console.error(console.formatting('Member Redirect'), 'No Tokens were found.');
        } else {
          Inertia.visit(route("member.register"), { mission: currentCampaign });
        }
      } else {
      }
    })
    .then((response) => afterHandlingCallback());
};
const afterHandlingCallback = () => {
  // const currentRoute = $route;

  // if (!data.isCallAfterHandlingCallback) {
  //     return;
  // }

  console.log("Start to redirect.");

  // if (currentRoute.query.redirect) {
  //     $localStorage.set(LOCAL_STORAGE_REDIRECT_KEY_NAME, currentRoute.query.redirect);
  // }

  // if (currentRoute.query.to) {
  //     $timer.delay('member_redirect_wait_for_redirect_To', () => {
  //         console.log(console.formatting('Member Redirect'), 'Redirect to "' + currentRoute.query.to + '" route.');
  //         $router.push({ name: currentRoute.query.to, params: { locale: $i18n.locale } }).catch((error) => (error));
  //     }, 1500);
  // } else if (currentRoute.query.action) {
  //     switch (currentRoute.query.action) {
  //         case 'login':
  //             goToLoginPage();
  //             break;
  //         case 'register':
  //             goToRegistrationPage();
  //             break;
  //         case 'logout':
  //             logout();
  //             break;
  //         case 'change-password':
  //             goToChangePasswordPage();
  //             break;
  //         case 'forgot-password':
  //             goToForgotPasswordPage();
  //             break;
  //     }
  // } else {
  //     redirectBackIfNeeded();
  // }
};
const toHomePage = () => {
  Inertia.visit(route("ocp.mainDashboard"));
};
const retryLogin = () => {
  const currentCampaign = $localStorage.get(CAMPAIGN_CURRENT);
  Inertia.visit(route("member.register"), { mission: currentCampaign });
};
onMounted(() => {
  handle();
});
</script>

<style scoped>

</style>

<template>
  <App>
    <div class="min-h-screen w-full flex justify-center items-center bg-primary-01">
      <picture class="block w-20">
        <img src="/images/loading.png" alt="" class="w-full" />
      </picture>
      <ModalBlank :show="isError">
        <div class="
            flex flex-col
            justify-center
            align-center
            text-center text-primary-01
          ">
          {{ t("something_went_wrong") }}
          <div class="flex items-center justify-center">
            <Button type="secondary-sm" class="w-40 mt-4 mx-auto" @click="toHomePage">{{ t("register.home") }}</Button>
            <Button type="secondary-sm" class="w-40 mt-4 mx-auto" @click="retryLogin">{{ t("register.retry") }}</Button>
          </div>
        </div>
      </ModalBlank>
    </div>
  </App>
</template>

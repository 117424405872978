import axios from "axios"
import {
    prepareAPIInfomation,
} from '@tools/app';

const getMemberRaffleList = async () => {
    const {
        baseURL,
        token,
        uuid,
        profile,
        error
    } = await prepareAPIInfomation()
    if (error) return error
    const res = await axios({
        url: `${baseURL}/api/v1/lucky_draw/raffles`,
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`
        },
        data: {
            "user_id": uuid,
            "flow_label": "queryRaffles",
            "is_mission": true,
            "mission_type": 'rl',
            "profile": profile
        }
    }).then(result => {
        return result.data
    }).catch((error) => {
        const errorRes = error.response.data
        return errorRes
    })
    return res
}

export default getMemberRaffleList

<script setup>
import { computed } from 'vue';
const props = defineProps({
    type: {
        type: String,
        default: 'primary',
    },
    disabled: {
        type: Boolean,
        default: false
    }
});

const classes = computed(() => {
    switch (props.type) {
        case 'primary':
        default:
            return `bg-white border border-transparent font-bold text-xl text-black h-60px`
        case 'primary-sm':
            return `bg-white border border-transparent font-bold text-xl text-black h-10`
        case 'primary-sm-disabled':
            return `bg-secondary-04 border border-transparent font-bold text-xl text-secondary-05 h-10 pointer-events-none`
        case 'secondary':
            return `bg-transparent border-2 border-primary-01 font-bold text-xl text-primary-01 h-60px`
        case 'secondary-sm':
            return `bg-transparent border-2 border-primary-01 font-bold text-xl text-primary-01 h-10`
        case 'secondary-dark':
            return `bg-transparent border-2 border-primary-02 font-bold text-xl text-primary-02 h-60px`
        case 'secondary-dark-sm':
            return `bg-transparent border-2 border-primary-02 font-bold text-xl text-primary-02 h-10`
    }
})
</script>

<style scoped lang="scss">
.btn-primary {
    height: 60px;

    &:disabled {
        background-color: #939393;
        color: rgba(255, 255, 255, 0.5);
    }
}

.btn-primary-sm {
    height: 40px;

    &:disabled {
        background-color: #939393;
        color: rgba(255, 255, 255, 0.5);
    }
}

.btn-secondary-dark,
.btn-secondary-dark-sm {
    &:disabled {
        color: #939393;
        border-color: #939393;
    }
}
</style>



<template>
    <button :type="type" class="font-tccc-head flex items-center justify-center px-4 rounded-full focus:outline-none"
        :class="[classes, `btn-${type}`]" :disabled="disabled">
        <slot />
    </button>
</template>

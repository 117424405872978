<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import StandardLayout from '@/Layouts/StandardLayout.vue';
import Button from '@/Components/Button.vue';
import InputTel from '@/Components/InputTel.vue';
import Input from '@/Components/Input.vue';
import { ref, computed } from 'vue'

const cooldownTime = 60
const currentCooldown = ref(0)
const disableResend = ref(false)
const sent = ref(false)

const resendText = computed(() => {
    const cooldown = ` (${currentCooldown.value}s)`
    return sent.value ? `Resend verification code${currentCooldown.value ? cooldown : ''} ` : `Send verification code`
})
const resend = () => {
    sent.value = true
    disableResend.value = true
    currentCooldown.value = cooldownTime
    const itv = setInterval(() => {
        currentCooldown.value--
        if (currentCooldown.value <= 0) {
            clearInterval(itv)
            disableResend.value = false
        }
    }, 1000);
}


</script>
<style scoped>
</style>
<template>

    <Head title="Register" />
    <StandardLayout :showBack="true">
        <h1 class="font-tccc-head text-2xl font-bold">Sign Up</h1>
        <p class="text-lg font-medium mt-1">Please fill in some additional information</p>
        <p class="font-tccc-unity text-xs mt-1">* Required</p>
        <div class="w-full mt-auto mb-auto">
            <Input class="mt-4" label="Name*" placeholder="Enter namne" />
            <Input class="mt-4" label="Password*" placeholder="Enter password" />
            <Input class="mt-4" label="E-mail*" placeholder="Enter email" />
            <Input type="date" class="mt-4" label="Month of Birth (member must be at least 13 years old)*"
                placeholder="MM / YYYY" />
            <div class="mt-4">
                <div class="font-tccc-head text-base font-normal">I agree Coca-cola China Limited to use my name and
                    contact details to send
                    product information, service, offers, news, and promotion of brands of Coca-cola.</div>
                <div class="mt-4">
            <div class="font-tccc-head text-base font-normal">I agree Coca-cola China Limited to use my name and contact details to send
              product information, service, offers, news, and promotion of brands of Coca-cola.</div>
            <div class="flex items-center mt-4">
              <div class="flex items-center">
                <input type="checkbox" class="apearance-none w-6 h-6 border-2 border-white rounded bg-transparent focus:outline-none checked:outline-none" value="email" id="cb_email" />
                <label for="cb_email" class="ml-2">Email</label>
              </div>
              <div class="flex items-center ml-8">
                <input type="checkbox" class="apearance-none w-6 h-6 border-2 border-white rounded bg-transparent focus:outline-none checked:outline-none" value="sms" id="cb_sms" />
                <label for="cb_sms" class="ml-2">SMS</label>
              </div>
            </div>
          </div>
            </div>
            <Button class="w-full mt-4">Complete sign-up!</Button>
        </div>
    </StandardLayout>
</template>

<script setup>
import { Head, Link } from "@inertiajs/inertia-vue3";
import StandardLayout from "@/Layouts/StandardLayout.vue";
import Accordion from "@/Components/Accordion.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
    <Head title="FAQ" />

    <StandardLayout showBack>
        <h1 class="font-tccc-head text-2xl font-bold p-4">
            {{ t("my_profile.faq") }}
        </h1>
        <Accordion>
            <template #trigger>為何我需要註冊成為會員？</template>
            <template #content>
                <p>
                  登記後可參加在Coke+上「可口可樂」相關品牌的任務，透過上傳產品發票、掃描活動機制中的QR 碼，就有機會獲得「可口可樂」旗下品牌的限量獎品！品牌活動將不定期更新於 Coke+ 網站中，歡迎您隨時前往查詢。
                </p>
                <p>
                  可口可樂公司旗下品牌包括但不限於「可口可樂」（「可口可樂」、「可口可樂」ZERO SUGAR、「可口可樂」纖維＋）、「雪碧」（「雪碧」、「雪碧」ZERO SUGAR、「雪碧」纖維＋）、「芬達」、「舒味思」、「原萃」、「原萃」冷萃、「飛想茶」、「紅茶花伝」、「喬亞咖啡」、「美粒果」、「動元素」、「水森活」以及「OOHA」等，涵蓋氣泡飲料、氣泡水、果汁、茶飲、運動飲料等。
                </p>
                <!-- <picture class="block">
                    <img src="images/brand.png" alt="" xclass="w-full" />
                </picture> -->
            </template>
        </Accordion>
        <Accordion class="border-t-2 border-border">
            <template #trigger>如何註冊成為會員？</template>
            <template #content>
                <p>
                  您可使用個人LINE帳號進行登入綁定，填寫所需資料及驗證手機號碼後，註冊成為 Coke+ 會員。
                </p>
                <p>
                  LINE 帳號註冊步驟：根據指示點擊網頁中的登入/註冊按鈕，透過個人LINE帳號登入並授權綁定「可口可樂」旗下品牌LINE官方帳號後，根據指示輸入資料，完成手機號碼驗證流程，即可成為會員。
                </p>
            </template>
        </Accordion>
        <Accordion class="border-t-2 border-border">
            <template #trigger>如我無法成功註冊，我該怎麼辦？</template>
            <template #content>
                <p>
                  倘若您未能成功註冊，請先查看是否為以下原因。若不是，請於服務時間內致電活動專屬客服電話，02-25020119 (服務時間: 星期一至星期五10:00- 17:00 ; 星期六日及國定假日公休)。
                </p>
                <ul>
                    <li>您未完成 LINE 登入流程，請嘗試重新操作一次。</li>
                    <li>您的智慧型手機瀏覽器為「無痕模式」或「私密瀏覽模式」，請關閉並使用一般模式後，嘗試重新操作一次。
                    </li>
                    <li>您未完成手機號碼驗證，請嘗試重新操作一次。</li>
                    <li>
                        您必須年滿 13 歲。如您未滿 20 歲必需先徵求家長或監護人之同意方可申請成為會員。
                    </li>
                </ul>
            </template>
        </Accordion>
        <Accordion class="border-t-2 border-border">
            <template #trigger
                >如果我無法成功透過 LINE 帳號註冊或登入，我該怎麼辦？</template
            >
            <template #content>
                <p>
                  倘若您無法成功透過個人LINE帳號於頁面進行登入或註冊，請嘗試重新開啟智慧型手機瀏覽器，確認瀏覽模式非「私密瀏覽」或「無痕模式」，並嘗試重新登入或註冊。
                </p>
            </template>
        </Accordion>
        <Accordion class="border-t-2 border-border">
            <template #trigger>若我無法掃描活動 QR 碼，我該怎麼辦？</template>
            <template #content>
                <p>
                  如為活動產品瓶蓋內之QR碼，請將瓶蓋擦乾，讓QR碼可被清楚辨識。
                </p>
                <p>
                  您可透過以下4種方式掃描QR碼——通過手機內建相機、LINE QR碼掃描器、Coke+網站掃描功能、或其他QR碼掃描APP；若未能使用其中一種方法，請嘗試其他方式。
                </p>
                <p><strong>手機內建相機</strong></p>
                <ol>
                    <li>打開手機內建相機。</li>
                    <li>對準 QR 碼後，保持對焦狀態使 QR 碼清晰顯示。</li>
                    <li>將相機鏡頭拉近、放大 QR 碼，即可有效提升辨識品質。</li>
                    <li>當看到網頁連結出現，點擊並進入到 Coke+ 網頁。</li>
                </ol>
                <p>
                  註：iPhone 手機另有「代碼掃描器」可於主畫面點擊使用。
                  可前往「設定」 >「控制中心」，然後點一下「代碼掃描器」旁邊的 「插入」按鈕加入主畫面。
                </p>
                <p><strong>LINE QR 碼掃描器</strong></p>
                <ol>
                    <li>打開 LINE APP。</li>
                    <li>開啟行動條碼掃描功能。</li>
                    <li>當看到網頁連結出現，點擊並進入到 Coke+ 網頁。</li>
                </ol>
                <p><strong>Coke+網站</strong></p>
                <ol>
                    <li>
                        在您的智慧手機上透過瀏覽器（建議使用 Chrome）進入
                        <a href="https://www.cokeplus.tw/" target="_blank"
                            >https://www.cokeplus.tw/</a
                        >
                    </li>
                    <li>
                        點擊品牌任務頁面的「立即掃碼」或「掃碼或輸入序號」按鈕，然後掃描QR碼。
                    </li>
                    <li>
                        如您的瓶蓋下方或QR碼印製處具有兌換序號，也可透過「掃描QR碼」頁面選擇「輸入序號」，直接手動輸入序號。
                    </li>
                </ol>
                <p><strong>QR Code 掃描 APP</strong></p>
                <ol>
                    <li>下載並打開 QR Code 掃描 APP。</li>
                    <li>掃描 QR Code。</li>
                    <li>當看到網頁連結出現，點擊並進入到 Coke+ 網頁。</li>
                </ol>
                <p>
                  如透過上述方式操作，仍無法進行掃描，請透過以下方式聯繫我們：
                  於服務時間內致電活動專屬客服電話，02-25020119（服務時間：星期一至五 10:00 至 17: 00；星期六日及國定假日休息）。
                </p>
            </template>
        </Accordion>
        <Accordion class="border-t-2 border-border">
            <template #trigger>如果我無法拍攝發票照片，我該怎麼辦？</template>
            <template #content>
                <p>
                  您可於下方查看有關如何在 iOS 和 Android 設備中授權相機使用或相簿取用權限的提示。
                </p>
                <p><strong>在 iOS 設備上開啟取用權限</strong></p>
                <ol>
                    <li>
                        打開 iPhone 的設定頁面，向下滑動至「應用程式」並選擇Chrome 或您常用的網路瀏覽器。
                    </li>
                    <li>將「相機」開關從白色滑到綠色以允許取用相機。</li>
                    <li>點擊「照片」打開照片權限設置。</li>
                    <li>
                        選擇以下兩個選項之一：「允許存取所選照片」或「允許存取所有照片」。
                    </li>
                </ol>
                <p><strong>在 Android 設備上開啟取用權限</strong></p>
                <ol>
                    <li>在您的 Android 設備上，打開 Chrome 應用程式。</li>
                    <li>在網址欄的右側，點擊「更多」，然後點擊「設定」。</li>
                    <li>點擊「網站設定」。</li>
                    <li>點擊「相機」。</li>
                    <li>點擊以打開相機。</li>
                </ol>
                <p>如透過上述操作，仍無法進行拍攝，您可點擊拍照上傳功能介面中左下角的照片圖示，透過選取手機相簿照片方式，完成上傳步驟。</p>
            </template>
        </Accordion>
        <Accordion class="border-t-2 border-border">
            <template #trigger>若我的抽獎機會，於參與活動後未更新次數，我該怎麼辦？</template>
            <template #content>
                <p>
                  您可透過點擊頁面左上角之「回到首頁」按鈕，於回到首頁後重新進入任務首頁，即可刷新頁面並更新累積抽獎次數。
                </p>
            </template>
        </Accordion>
        <Accordion class="border-t-2 border-border">
            <template #trigger>如何得知兌換獎品詳情？</template>
            <template #content>
                <p>各品牌活動內容及獎品兌換詳情，請在各任務頁面的最下方點擊「條款及細則」展開完整資訊。</p>
            </template>
        </Accordion>
        <Accordion class="border-t-2 border-border">
            <template #trigger
                > 如何得知我是否中獎？</template
            >
            <template #content>
                <p>
                  中獎名單將公布於各品牌 LINE 官方帳號中的貼文串。
                </p>
                <p>
                  同時，主辦單位將發送中獎簡訊給中獎者，請依照簡訊指示完成兌獎流程。中獎者需自行透過簡訊中之連結網址下載領獎信函，將填妥後「領獎信函」+「身分證正反面影本」，於兌獎收件截止時間前將兌獎資料送達指定地址。
                </p>
                <p>*逾期視為放棄該得獎權利，不得向主辦單位要求任何補償。
                  </p>
                  <p>*中獎者需於兌獎期限內寄回領獎信函及兌獎資料，才算完成兌獎流程。</p>
            </template>
        </Accordion>
        <Accordion class="border-t-2 border-border">
            <template #trigger>家長如何為子女移除帳戶？ / 如何移除或修改個人帳戶？</template>
            <template #content>
                <p>請透過以下方式聯繫我們，註明「帳戶問題」並提供需要移除或修改帳戶之相關資料：</p>
                <p>
                  於服務時間內致電活動專屬客服電話，02-25020119（服務時間：星期一至五 10:00 至 17:00 ；星期六日及國定假日休息）。
                </p>
            </template>
        </Accordion>
        <Accordion class="border-t-2 border-border">
            <template #trigger>我還有其他問題，如何做進一步詢問？</template>
            <template #content>
                <p>如果您有任何疑問，請透過以下方式聯繫我們：</p>
                <p>
                  於服務時間內致電活動專屬客服電話，02-25020119（服務時間：星期一至五 10:00 至 17: 00 ；星期六日及國定假日休息）。
                </p>
            </template>
        </Accordion>
    </StandardLayout>
</template>

<style scoped lang="scss">
::v-deep {
    p,
    ul,
    ol {
        margin-bottom: 1rem;
        font-family: inherit;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul,
    ol {
        padding-left: 2rem;
    }

    ul li {
        list-style-type: disc;
    }

    ol {
        > li {
            list-style-type: decimal;

            > ol {
                li {
                    list-style-type: lower-roman;
                }
            }
        }
    }

    a {
        word-break: break-all;
        text-decoration: underline;
    }
}
</style>

<script setup>
import { Head, Link } from '@inertiajs/inertia-vue3'
import StandardLayout from '@/Layouts/StandardLayout.vue'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
const scrollContainer = ref(null)

const handleScroll = (el) => {
  const scrollEl = scrollContainer.value.querySelector('.js--scroll-el')
  const srollCheck = () => {
    if (scrollEl.scrollTop + scrollEl.clientHeight >= scrollEl.scrollHeight - 4) {
      el.classList.add('at-bottom')
    } else {
      el.classList.remove('at-bottom')
    }
  }

  srollCheck()

  scrollEl && scrollEl.addEventListener('scroll', () => {
    srollCheck()
  })
}

onMounted(() => {
  handleScroll(scrollContainer.value)
})
</script>

<style scoped lang="scss">
::v-deep {
  .ocp-page {
    overflow: hidden;
  }

  p,
  ul,
  ol {
    margin-bottom: 1rem;
    font-family: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  ul li {
    list-style-type: disc;
  }

  ol {
    >li {
      list-style-type: lower-alpha;

      >ol {
        li {
          list-style-type: lower-roman;

          >ol {
            li {
              list-style-type: decimal;
            }
          }
        }
      }
    }
  }

  a {
    word-break: break-all;
    text-decoration: underline;
  }
}


.container {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 18rem;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.5) 57%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    opacity: 1;
    transition: all .3s;
  }

  &.at-bottom::after {
    opacity: 0;
  }
}
</style>

<template>

  <Head title="Terms & Conditions" />


  <StandardLayout class="h-screen overflow-hidden" showBack>
    <h1 class="font-tccc-head text-2xl font-bold px-4 my-4">{{ t('my_profile.coke_plus_membership_terms') }}</h1>
    <div class="p-4 pb-8 flex-1 overflow-auto">
      <div class="container font-sfpd text-lg text-primary-02 bg-level-01 rounded-xl py-6 pl-6 pr-2 w-full max-w-3xl mx-auto
      h-full" ref="scrollContainer">
        <div class="h-full overflow-auto pr-4 scroll-el js--scroll-el">
          <p style="text-align: center;">
            <strong>Coke+</strong><strong>會員約定及條款<br /></strong><strong>（「本條款」）<br /></strong>生效日期：2022年10月5日</p>
          <p>
            歡迎加入Coke+會員計劃（「<strong>本計劃</strong>」），本計劃由香港商可口可樂有限公司台灣分公司（下稱「<strong>CCCL</strong>」、「<strong>我們</strong>」、「<strong>我們的</strong>」或「<strong>我方</strong>」）經營。本計劃透過我們指定的網站和/或行動應用程式（各稱「<strong>各平台</strong>」，統稱「<strong>平台</strong>」）提供，並各由或將由CCCL擁有及經營。
          </p>
          <p>本條款（可經CCCL在不事先通知&nbsp; 台端的情况下不時修訂），將有助於&nbsp; 台端了解作為Coke+會員（下稱「<strong>會員</strong>」）可使用的功能，以及&nbsp;
            台端在以下情况下的權利和義務：（a）使用和查閱各平台和我們的服務；及（b）以會員身份參與我們的推廣活動（統稱為「<strong>會員服務</strong>」）。</p>
          <p>請仔細閱讀本條款。&nbsp; 台端必須同意本條款方可成為會員並使用會員服務。</p>
          <p><strong>1. Coke+</strong><strong>會員帳戶註冊</strong></p>
          <ol>
            <li>要成為會員，台端必須提供我們不時要求的資料並完成我們要求的步驟（例如使用 台端註冊的行動電話號碼驗證&nbsp;
              台端的身份），以完成Coke+會員帳戶（下稱「<strong>帳戶</strong>」）的登記。</li>
            <li>我們可根據絕對裁量權，在不向 台端提供理由的情况下拒絕&nbsp; 台端註冊成為會員。</li>
            <li>通過註冊成為會員，即表示 台端同意：
              <ol>
                <li>本條款、Coke+平台使用約定及條款("<strong>平台條款</strong>")和隱私政策 (定義請見下文)；</li>
                <li>台端已提供或將提供予我們作註冊用途的資料為真實、準確、完整及最新的；</li>
                <li>會員服務僅供 台端個人使用，台端不會允許他人使用&nbsp; 台端的帳戶及/或會員服務；</li>
                <li>台端不可轉讓會籍；及</li>
                <li>保護 台端使用會員服務和/或帳戶的資料， 如&nbsp; 台端的行動電話號碼、密碼、電子郵件地址、儲值卡或支付卡號碼、付款資料及其他由我們不時指定的資料。</li>
              </ol>
            </li>
            <li>要註冊帳戶，台端必須：（i）持有有效臺灣身份證；及（ii）年滿13歲。如果你未滿20歲，以下規定將適用：
              <ol>
                <li>台端必須事先取得父母或監護人同意才能註冊成為會員。</li>
                <li>如果 台端的父母或監護人不同意本條款、平台條款或隱私政策，台端必須停止註冊。</li>
                <li>如果我們在註冊過程中獲悉，我們在未經未滿20歲的註冊人父母或監護人同意的情况下收集或接收了其個人資料，我們將刪除該等個人資料並拒絕其註冊。</li>
                <li>在 台端成為會員後，如果我們獲悉&nbsp; 台端的父母或監護人從未同意或撤回其同意，台端將立即被退出計劃，且我們將終止&nbsp; 台端的帳戶。</li>
                <li>台端的父母或監護人可通過第18條(<em>聯絡我們</em>) 指定的任何管道查詢、更正或刪除 台端的個人資料, 或終止&nbsp; 台端的會籍和帳戶。</li>
              </ol>
            </li>
            <li>每個有效的行動電話號碼或社交媒體帳戶（適用於 台端使用社交媒體帳戶註冊賬戶的情况）只能用於建立一個帳戶。所有重覆申請將不被接受。</li>
            <li>每個會員必須只保留一個帳戶。以同一會員名稱保留多個會籍是不允許的。</li>
            <li>如果 台端的帳戶資料不時出現任何變更，台端同意以我們指定的方式通知我們。尤其是&nbsp; 台端必須隨時更新&nbsp;
              台端的聯絡資料，以便我們就本計劃所作的任何通訊都能發送到正確的地址。台端可以登入&nbsp; 台端的帳戶以更改或更新&nbsp; 台端的個人資料。</li>
            <li>註冊成功後，CCCL將為
              台端建立一個帳戶，台端將能夠作為會員使用會員服務。例如，台端將有權領取Coke+樂賞星（下稱「<strong>樂賞星</strong>」），並參與CCCL或其商業伙伴舉辦的推廣活動。每項推廣活動均受其本身的約定及條款所規範，而&nbsp;
              台端同意在參與前先閱讀這些約定及條款。</li>
          </ol>
          <p><strong>2. 隱私政策</strong></p>
          <ol>
            <li>在註冊帳戶時，台端授權我們根據我們的隱私政策（下稱「<strong>隱私政策</strong>」）收集和使用 台端的個人資料。</li>
            <li>我們致力於保護 台端的個人資料和我們持有的其他資料。隱私政策解釋了我們將如何使用、處理和保護&nbsp;
              台端的個人資料，台端可以通過此超連結（https://cokeplus.tw/privacy-policy）或各平台的頁面查閱隱私政策。</li>
          </ol>
          <p><strong>3.&nbsp;</strong><strong>網</strong><strong>路</strong><strong>安全</strong><strong><br /> </strong>
          </p>
          <ol>
            <li>出於安全考慮，台端不得：（i）從公用或共享的電腦登入 台端的帳戶或使用會員服務；或（ii）與他人共享&nbsp; 台端的行動裝置。</li>
            <li>台端須對通過 台端的帳戶進行的所有活動負責。如果&nbsp; 台端的會員號碼或密碼被有意或無意地洩露，從而讓第三方（無論是否經&nbsp; 台端授權）進入&nbsp;
              台端的帳戶並使用會員服務，我們將概不負責。</li>
            <li>台端同意遵守並實施我們不時施加或建議的任何保安措施。</li>
            <li>如果 台端發現或懷疑&nbsp; 台端帳戶有任何未經授權的登入或使用，台端同意立即通知我們。</li>
            <li>詳情請參閱平台條款。</li>
          </ol>
          <p><strong>4.&nbsp;</strong><strong>如何賺取樂賞星</strong></p>
          <ol>
            <li>作為會員，台端可以於各平台透過完成我們不時指定的活動（如完成特殊任務或遊戲（下稱「<strong>動作</strong>」））獲得並累積樂賞星。</li>
            <li>例如，你可以透過完成以下動作獲得樂賞星：
              <ol>
                <li>成功註冊成為會員；</li>
                <li><strong>掃描或輸入樂賞星號碼：</strong>
                  輸入或掃描CCCL不時分發的宣傳材料及/或可口可樂商品上（例如瓶蓋內）的樂賞星號碼（例如QR碼），但須遵守CCCL不時規定的任何限制，如會員每天可掃描的樂賞星號碼的數量限制；</li>
                <li>
                  參與品牌活動任務：參與品牌活動任務，如完成調查、參與新產品開發活動和其他類型的任務或由CCCL和/或其商業夥伴不時開展的活動（下稱"<strong>品牌活動任務</strong>"）。請注意每個品牌活動任務均受制於CCCL及其商業夥伴規定的單獨約定及條款，包括就
                  台端參與相關品牌活動任務如何獲得樂賞星的規定。</li>
                <li><strong>參與合作夥伴推廣計劃：</strong>
                  參與我們的商業伙伴提供的推廣計劃（下稱「<strong>伙伴計劃</strong>」）。請注意，每個伙伴計劃都受該商業伙伴及/或CCCL施加的單獨約定及條款的約束，這些約定及條款包括對
                  台端通過參與相關伙伴計劃而獲得樂賞星的規定。</li>
              </ol>
            </li>
            <li>我們可自行決定，在不通知 台端的情况下，修改獲得樂賞星的機制以及因 &nbsp;台端完成行動而獲得的樂賞星數量。詳情請參閱各平台上最新公佈的資料。</li>
            <li>我們保留自行決定調整存入 台端帳戶樂賞星數量的權利。</li>
            <li>除非我們以書面形式另行說明，存入 台端帳戶的樂賞星是屬於 &nbsp;台端個人的，在任何情况下均不得轉移或轉讓。</li>
            <li>樂賞星沒有金錢價值，在任何情况下均不能兌換為現金。</li>
          </ol>
          <p><strong>5.&nbsp;</strong><strong>使用</strong><strong>&nbsp; </strong><strong>台端的樂賞星</strong><strong><br />
            </strong></p>
          <ol>
            <li>當
              台端累積了指定數量的樂賞星（下稱「<strong>里程碑</strong>」），台端可以解鎖一個「Coke+樂賞星獎賞」（下稱「<strong>獎勵箱</strong>」），並選擇可換領的某種獎賞，該免費獎賞的形式可為電子或實體禮券及/或商品或服務（各稱「<strong>獎賞</strong>」）。
            </li>
            <li>如果 台端達到了一個里程碑，但未能在我們發出的任何通知中指定的日期或之前解鎖相關獎勵箱， &nbsp;台端解鎖獎勵箱的權利將自動被沒收。</li>
          </ol>
          <p><strong>6.&nbsp;</strong><strong>樂賞星有效期</strong></p>
          <ol>
            <li>任何與累積樂賞星有關的涉嫌欺詐或濫用行為可能導致所有累積樂賞星的即時沒收、取消或任何獎賞換領的撤銷以及暫停或終止 台端的會籍。因此，我們保留就 &nbsp;台端已使用或獲取的任何獎賞向
              &nbsp;台端要求賠償的權利。</li>
          </ol>
          <p><strong>7.&nbsp;</strong><strong>換領</strong><strong>獎賞</strong></p>
          <ol>
            <li>台端理解某些獎賞可能會由我們的商業伙伴提供，並且在任何情况下都受其自身約定及條款所約束。</li>
            <li>可換領的獎賞類型：（i）取決於 台端所達到的里程碑；（ii）須視乎其供應情况而定；（iii）可能取決於換領時所在地點； 和/或（iv）受我們就換領獎賞方式施加的任何其他限制所約束。</li>
            <li>我們保留絕對裁量權：（i）調整每個里程碑可換領的獎賞；（ii）隨時中止獎賞或以其他獎賞代替；或（iii）在不向 台端提供任何理由的情况下拒絕 &nbsp;台端換領獎賞的要求。</li>
            <li>視乎有關獎賞，台端可能會被要求付款以換領該等獎賞，而 台端同意該等付款必須以新臺幣支付。該等款項將立即從 &nbsp;台端的信用卡中扣除，或須立即使用
              &nbsp;台端指定的付款方式支付。若付款交易因任何原因未能完成，換領獎賞的請求將被自動取消。</li>
            <li>實體貨品或服務形式的獎賞必須按照我們不時指定的方式獲取或使用。</li>
            <li>獎賞必須在我們指定的日期或之前領取或使用。未在該日期或之前領取或使用獎賞將導致該獎賞自動被沒收。</li>
            <li>獎賞在任何情况下均不能兌換為現金。</li>
            <li>在適用法律允許的範圍內，對因換領、未能換領或使用任何獎賞而産生或與之相關的任何性質的請求、損失、成本、開支或損害，我們概不負責。</li>
            <li>我們可以根據我們的裁量權，在不通知 台端的情况下修改解鎖獎勵箱的機制和達到某里程碑所需的樂賞星數量。</li>
          </ol>
          <p><strong>8.&nbsp;</strong><strong>推廣活動</strong></p>
          <ol>
            <li>我們可不時進行與本計劃相關的其他推廣活動（下稱「<strong>推廣</strong>」）。此類推廣受CCCL不時指定的特定約定及條款（下稱「<strong>推廣條款</strong>」）所約束。</li>
            <li>在參加推廣前，台端同意閱讀並理解相關的推廣條款。通過參與該等推廣，即表示 台端已閱讀並同意有關推廣條款。</li>
            <li>我們保留隨時修改推廣條款而不作另行通知的權利。</li>
          </ol>
          <p><strong>9.&nbsp;</strong><strong>帳戶結單</strong></p>
          <ol>
            <li>各平台上將提供反映 台端的任何交易和帳戶狀態的結單（下稱「<strong>結單</strong>」），其時間間隔由CCCL不時決定。</li>
            <li>台端同意審閱各平台不時向 台端提供的任何結單，並在相關結單發出之日起七（7）日內就任何錯誤、遺漏或爭議通知我們。如果
              &nbsp;台端沒有通知我們，台端將被視為同意該等結單的內容，並將不再有權質疑該等結單的準確性。本條款的任何內部均無礙我們單方面修改任何該等結單，以糾正我們發現的任何錯誤。</li>
          </ol>
          <p><strong>10.&nbsp;</strong><strong>責任限制及賠償</strong></p>
          <ol>
            <li>
              對因本計劃、台端使用會員服務、我們提供或拒絕提供任何樂賞星、台端換領或無法換領任何獎賞或因任何原因暫停或終止會籍或本計劃而引起或與上述各項有關的任何間接或連帶損失、損害或任何類型的支出，我們概不負責，除非該等損失、損害或支出是由我們重大過失或故意違約所致。
            </li>
            <li>
              除下文第（c）款所述外，台端應就針對我們、我方代理人、主管和員工以及我方代理人之代理人、主管和員工提起或由他們可能針對我們提起的所有訴訟、法律程序和請求，以及我們或他們可能因以下（或其中任何一項）而招致或遭受或與之相關的所有損失、損害並合理成本和支出，向我們、我方代理人、主管和員工以及我方代理人之代理人、主管和員工作出賠償和補償：
              <ol>
                <li>台端使用會員服務（包括 台端參與任何由我們或我們的商業伙伴舉辦的推廣計劃）；</li>
                <li>台端在履行本條款和/或平台條款下之義務時的任何違約行為（包括 台端在使用會員服務時的任何欺詐或不當行為）；</li>
                <li>維護或執行我方與本條款有關的權利或行使我方與本條款和/或平台條款有關的權力；及</li>
                <li>台端未能不時向我們提供完整、準確及最新的資料。</li>
              </ol>
            </li>
            <li>
              上文第（b）款所述之賠償於會員服務終止或帳戶關閉後將繼續生效。如果能證明第（b）款所述之任何訴訟、法律程序、請求、損失、損害、成本或開支是由（i）我方、（ii）我方代理人或（iii）我方主管或員員或我方代理人的主管或員工的重大過失或故意違約造成的，而且該等訴訟、法律程序、請求、損失、損害、成本或支出是合理可預見的及直接和純粹由該等重大過失或故意違約所引起的，則
              台端將毋須承擔第（b）款所述之責任。</li>
            <li>如果獎賞由我們的商業伙伴發出或提供，各該等商業伙伴須為以下負責：
              <ol>
                <li>該等獎賞的可用性、供應、交付、退貨、退款和 台端任何訂單的履行情况；及</li>
                <li>該等獎賞的品質、適用性或描述的準確性；並且</li>
              </ol>
            </li>
          </ol>
          <p>我們不就任何該等獎賞是否可商售品質或將適合其用途作出陳述或保證。所有與換領獎賞有關的查詢和爭議（包括產品保養）應直接向相關商業伙伴提出及與之解決。</p>
          <p><strong>11.&nbsp;</strong><strong>終止</strong><strong> &nbsp;</strong><strong>台端帳戶及會籍</strong></p>
          <ol>
            <li>台端可隨時以第18條（<em>聯絡我們</em>）規定的任何方法聯繫我們以終止會籍。</li>
            <li>為處理 台端的終止要求，台端的要求必須包含以下資料：（i）台端的用戶名稱；（ii）台端已於我方註冊的行動電話號碼；及（iii）我們為處理該等要求而合理要求 &nbsp;台端提供的任何其他資料。</li>
            <li>台端的帳戶及會籍終止將在收到 台端提供的所有必要資料後40個日曆日內生效。</li>
            <li>在下列情况下，我們可隨時暫停或終止 台端的會籍：（i）我們懷疑 &nbsp;台端濫用或不當使用本計劃、會員服務或 &nbsp;台端作為會員有權享有的任何特權（例如，我們懷疑
              &nbsp;台端在使用或累積樂賞星方面存在任何欺詐或濫用行為）；或（ii）台端未能遵守本條款中的任何規定。如 &nbsp;台端之會籍已依此款終止，台端不得再開設帳戶。</li>
            <li>當 台端的帳戶及會籍終止時：
              <ol>
                <li>台端已累積或未換領（如為獎賞）的所有樂賞星或獎賞將被取消或作廢而不作另行通知；及</li>
                <li>我們將不再有責任向 台端提供任何會員服務或作為會員的特權。</li>
              </ol>
            </li>
            <li>台端的會籍無論因任何原因而終止，均不影響我們在終止日期時所享有的任何權利或補救。</li>
          </ol>
          <p><strong>12.&nbsp;</strong><strong>修訂本條款</strong></p>
          <p>我方可自行决定不時修改、刪除或替換本條款中的任何條款，或在本條款中添加新條款。我們將通過我們認為適當的管道通知 &nbsp;台端該等修訂。該等修訂將即時生效，如 &nbsp;台端繼續使用會員服務，即對
            &nbsp;台端具有約束力。如 &nbsp;台端不同意該等修訂，應立即停止使用會員服務。</p>
          <p><strong>13.&nbsp;</strong><strong>雜項</strong></p>
          <ol>
            <li>CCCL保留隨時根據其絕對裁量權修改本計劃之架構、福利和其他特點或終止本計劃的權利。</li>
            <li>為確保各會員遵守本條款，CCCL可不時在不事先通知會員的情况下對帳戶進行審核。在審核期間，該帳戶將被暫停使用，在審核完成之前，會員不得登入其帳戶或使用任何會員服務（包括以該帳戶進行任何交易）。</li>
            <li>
              CCCL未行使或延遲行使本條款下的任何權利或救濟不得視為放棄其權利，而對任何權利或救濟的任何一次或部分放棄將無礙CCCL進一步或另外行使該權利或救濟，亦將無礙CCCL行使任何其他權利或救濟。本條款規定的權利和救濟是累積性的，並不排除法律規定的任何權利或救濟。
            </li>
          </ol>
          <p><strong>14.&nbsp;</strong><strong>轉讓</strong></p>
          <ol>
            <li>未經我們事先書面同意，台端不得轉讓或以其他方式轉移 台端在本條款項下的任何權利、責任或利益。</li>
            <li>我們有權自行決定將本條款項下的任何權利、義務和利益轉讓或以其他方式轉移給任何繼任實體、關係企業或其他人士。</li>
          </ol>
          <p><strong>15.&nbsp;</strong><strong>第三者權利</strong></p>
          <p>本條款並未對第三人創造得向我們執行本條款的任何條款或享有其項下利益之權利。</p>
          <p><strong>16.&nbsp;</strong><strong>通訊</strong></p>
          <ol>
            <li>台端同意我們使用 台端提供並保存在我們記錄中的任何聯絡資料（包括地址、行動電話號碼和電郵地址）不時與 &nbsp;台端聯絡（無論透過信件、電話、短訊、電子郵件或其他方式）。</li>
            <li>除非我們另有說明，否則 台端將在以下時間被視為已收到我們發出的通知：（a）（如由我們親自遞送）在親自送達 &nbsp;台端或將通知留在
              &nbsp;台端上次書面通知的地址後；（b）（如以郵遞方式寄往）如上述地址在臺灣，則寄往該地址後四十八（48）小時後；如該地址在臺灣以外，則寄往該地址後七（7）天後；（c）（如以電子郵件發送）在發送至
              &nbsp;台端上次書面通知的電郵地址後；或（e）（如於任何平台中發佈）在該平台中發出通告後。</li>
            <li>台端發送給我方的通訊將被視為我方實際收到當天收到。</li>
          </ol>
          <p>
            <strong>17.&nbsp;</strong><strong>管限法</strong><strong>律</strong><strong>、</strong><strong>司法管轄權及排解糾紛</strong>
          </p>
          <p>本條款受臺灣法律管轄及按臺灣法律解釋，並且 &nbsp;台端同意接受臺灣法院的非專有司法管轄權。</p>
          <p><strong>18.&nbsp;</strong><strong>聯絡我們</strong></p>
          <p>如果 &nbsp;台端對本條款有任何疑問，請透過以下方式與我們聯絡：</p>
          <ul>
            <li>台端可以使用Facebook Messenger向可口可樂的Facebook頁面發送私人訊息；或</li>
            <li>台端可郵寄至：<br />香港商可口可樂有限公司台灣分公司<br />105410 臺北市松山區敦化北路88號14樓之2<br />系統管理員收</li>
          </ul>
        </div>
      </div>
    </div>
  </StandardLayout>
</template>
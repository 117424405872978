<script setup>
import { computed, watch, ref } from "vue";
import { event } from "vue-gtag";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { usePage } from "@inertiajs/inertia-vue3";
import Button from "@/Components/Button.vue";
import ModalBlank from "@/Components/Modal/ModalBlank.vue";
// import { pushGTM } from "@tools/app";
// import { HK_OCP_TRACKING_CAMPAIGN_ID } from "@constants/main";

const $store = useStore();
const { t } = useI18n();
const $emit = defineEmits([
    "clickOk",
]);
const props = defineProps({
    status: {
        type: Boolean,
        default: false,
    },
    newEntry: {
        type: Number,
        default: 0,
    },
});

const statusDisableCta = ref(false);

const handleClickOk = event => {
    $emit('clickOk', event)
}
</script>

<template>
    <ModalBlank v-if="props.status" type="dark" class="modal-reward" @out="handleClickout">
        <div class="text-center inner overflow-auto px-5">
            <h2 class="font-tccc font-bold mb-2 leading-7 text-[22px] pl-4">
                {{ $t("real_leaf.modal_head") }}
            </h2>
            <p class="text-[14px]">{{ $t("real_leaf.modal_desc",[props.newEntry]) }}</p>

            <div class="list-reward flex flex-wrap justify-center items-start">
                <img src="/images/mission/icon-rl.png" alt="bg" class="" />
            </div>

            <Button
                type="primary"
                class="cta w-full z-20 relative"
                @click="event => handleClickOk(event)"
            >
                OK
            </Button>
        </div>
    </ModalBlank>
</template>

<style lang="scss" scoped>
.modal-reward {
    z-index: 20;
}

.inner {
    max-height: 80vh;
}

.cta {
    margin: 0 auto;
    min-width: 150px;
    height: 40px;
    pointer-events: all;
}

p {
    max-width: 285px;
    margin: 0 auto;
}

.list-reward {
    margin: -12% 0;
}

.item-reward {
    max-width: 120px;
    margin: 0 10px 25px;

    &.active {
        .bg-reward-active {
            display: block;
        }
    }

    .rounded-full {
        width: 110px;
        height: 110px;
        border: 1px solid #fff;
        box-shadow: 0 0 10px #fff;
        margin-bottom: 16px;
        border-radius: 100%;
        position: relative;
    }

    img:not(.bg-reward-active) {
        border-radius: 50%;
    }

    .bg-reward-active {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: none;
        display: none;
    }
}
</style>

<script setup>
import { Head, Link } from '@inertiajs/inertia-vue3'
import StandardLayout from '@/Layouts/StandardLayout.vue'
import { onMounted, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { t } = useI18n()
const $store = useStore()
const scrollContainer = ref(null)

const handleScroll = (el) => {
  const scrollEl = scrollContainer.value.querySelector('.js--scroll-el')

  scrollEl && scrollEl.addEventListener('scroll', () => {
    if (scrollEl.scrollTop + scrollEl.clientHeight >= scrollEl.scrollHeight - 4) {
      el.classList.add('at-bottom')
    } else {
      el.classList.remove('at-bottom')
    }
  })
}

onMounted(() => {
  handleScroll(scrollContainer.value)
})
</script>

<style scoped lang="scss">
::v-deep {
  .ocp-page {
    overflow: hidden;
  }

  p,
  ul,
  ol,
  table {
    margin-bottom: 1rem;
    font-family: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }

  table {
    width: 100%;

    td {
      border: 1px solid #9496A1;
      padding: 0.5rem;
    }
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  ul li {
    list-style-type: disc;
  }

  ol {
    >li {
      list-style-type: lower-alpha;

      >ol {
        li {
          list-style-type: lower-roman;
        }
      }
    }
  }

  a {
    word-break: break-all;
    text-decoration: underline;
  }
}


.container {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 18rem;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.5) 57%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    opacity: 1;
    transition: all .3s;
  }

  &.at-bottom::after {
    opacity: 0;
  }
}
</style>

<template>

  <Head title="Privacy Policy" />


  <StandardLayout class="h-screen overflow-hidden" showBack>
    <h1 class="font-tccc-head text-2xl font-bold px-4 my-4">{{ t('privacy_policy') }}</h1>
    <div class="p-4 pb-8 flex-1 overflow-auto">
      <div class="container font-sfpd text-lg text-primary-02 bg-level-01 rounded-xl py-6 pl-6 pr-2 w-full max-w-3xl mx-auto
      h-full" ref="scrollContainer">
        <div class="h-full overflow-auto pr-4 scroll-el js--scroll-el">
          <p>歡迎來到香港商可口可樂有限公司台灣分公司隱私政策（下稱「<strong>《隱私政策》</strong>」）的頁面。</p>
          <p>
            香港商可口可樂有限公司台灣分公司（下稱「<strong>我們</strong>」、「<strong>我們的</strong>」、「<strong>我方</strong>」或「<strong>可口可樂</strong>」）重視&nbsp;
            台端的隱私權。我們感謝&nbsp; 台端對我們的信任並向我們提供&nbsp; 台端個人資料，我們以尊重&nbsp; 台端的隱私為與&nbsp; 台端互動的核心。</p>
          <p>可口可樂對個人資料的處理遵循以下原則：</p>
          <ul>
            <li>透明</li>
            <li>尊重</li>
            <li>信任</li>
            <li>公平</li>
          </ul>
          <p>最後更新日期：2022年10月5日</p>
          <p>
            本《隱私政策》載明了可口可樂從以下各項之用戶處收集或關於其用戶的個人資料：（a）其網站（下稱「<strong>網站</strong>」），（b）其行動裝置應用程式、視窗小部件及行動裝置互動功能（統稱「<strong>應用程式</strong>」），及（c）可口可樂營運的、與其網站及應用程式相關的其他線上和線下服務，例如提供平台或服務以實現或促進線上和離線購買商品或服務或任何由可口可樂營運的獎賞、會員或忠誠計劃（統稱為「<strong>服務</strong>」）。本《隱私政策》亦載明了可口可樂如何使用及保護該等個人資料，並解釋了用戶如何就個人資料作出選擇。
          </p>
          <p>
            在本《隱私政策》中提及「<strong>個人資料</strong>」時，我們指的是任何能夠識辨或可用於識辨自然人的資料（但一般不包括匿名化資料）。這意味著「<strong>個人資料</strong>」包括直接身分標識（如姓名、用戶名、行動電話號碼、電郵地址及社交媒體帳戶名稱等）及間接身分標識（如電腦或行動裝置ID及IP地址等）。提及「<strong>台端</strong>」或「<strong>用戶</strong>」時，我們指的是使用我們任何服務的人士。提及「<strong>控制者</strong>」時，我們指的是決定向&nbsp;
            台端收集或收集關於&nbsp; 台端的哪些個人資料，及如何使用和保護該個人資料的人士或實體（即可口可樂）。</p>
          <p>我們如何收集、使用和保護&nbsp; 台端的個人資料受臺灣法律的約束，尤其是《個人資料保護法》（下稱「<strong>《個資法》</strong>」），其中規定控制者應當如何收集、處理及使用屬於他人的個人資料。
          </p>
          <p><strong>聯絡我們</strong></p>
          <p><strong>倘</strong><strong>&nbsp; </strong><strong>台端對我們如何處理</strong><strong>&nbsp;
            </strong><strong>台端個人資料有任何疑問，敬請通過以下指定方式聯絡我們：</strong></p>
          <ol>
            <li>台端可使用訊息功能向我們的FACEBOOK頁面發送私人訊息；<br />或</li>
            <li>台端可郵寄至：<br />香港商可口可樂有限公司台灣分公司<br />105410 臺北市松山區敦化北路88號14樓之2<br />系統管理員收</li>
          </ol>
          <p><strong>&nbsp;1.&nbsp;</strong><strong>本《隱私政策》何時適用？</strong></p>
          <p>本《隱私政策》於2022年10月5日發佈並生效。</p>
          <p><strong>2. 本《隱私政策》適用於何處？</strong></p>
          <p>當我們的服務已發佈或連結了本《隱私政策》，而服務中特別提及本《隱私政策》或可口可樂有要求&nbsp;
            台端確認知悉本《隱私政策》時，則本《隱私政策》適用於該等服務從用戶處收集的個人資料。本《隱私政策》亦涵蓋了我們通過電子郵件、電話和線下（例如在現場活動期間）與我們聯絡的消費者處所收集的個人資料。</p>
          <p>本《隱私政策》亦可能適用於透過我們運作的官方社交媒體頁面（下稱「<strong>社交媒體平台</strong>」）與我們互動的消費者所向我們提供的個人資料。倘&nbsp;
            台端對本《隱私政策》是否適用於與社交媒體平台相關的特定個人資料有疑問，&nbsp; 台端可以透過上述 「聯絡我們」部份中指定的任何通訊渠道與我們聯絡。</p>
          <p>
            本《隱私政策》不適用於其他機構運作的網站及其他線上服務。該等其他網站及服務遵循其隱私政策，而<strong><em><u>非</u></em></strong>本《隱私政策》。煩請務必查看該等隱私政策，以便了解這些機構正在或將會如何處理及使用&nbsp;
            台端的個人資料。</p>
          <p><strong>3. 可口可樂收集哪些類型的個人資料及原因</strong></p>
          <p><strong>a. <u>台端選擇向我們提供的資料</u></strong></p>
          <p>我們收集&nbsp; 台端選擇與我們分享的個人資料，</p>
          <p>這通常包括以下類型的個人資料：</p>
          <table width="624">
            <thead>
              <tr>
                <td style="width: 188.578px; vertical-align: top;">
                  <p>可口可樂收集的個人資料</p>
                </td>
                <td style="width: 419.422px; vertical-align: top;">
                  <p>為何收集該等個人資料</p>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 188.578px; vertical-align: top;">
                  <p><strong>個人識別、聯絡資料和帳戶資料</strong></p>
                  <p>姓名、用戶名及密碼、電郵地址、行動電話號碼、出生日期、年齡、性別（及其他人口統計資料）、</p>
                  <p>郵寄地址和類似的聯絡方式，從&nbsp; 台端參與的忠誠度和會員計劃中以及&nbsp;
                    台端的社交媒體帳戶或通訊平台帳戶（如Facebook、LINE、Instagram、WhatsApp等）中檢索到的帳戶資料。</p>
                </td>
                <td style="width: 419.422px; vertical-align: top;">
                  <ul>
                    <li>創建和維持&nbsp; 台端的線上帳戶</li>
                    <li>驗證&nbsp; 台端的身份和使用某些服務的資格</li>
                    <li>客制化&nbsp; 台端的服務體驗</li>
                    <li>為&nbsp; 台端提供獨家內容、折扣和其他機會</li>
                    <li>管理或方便&nbsp; 台端參與抽獎、競賽或其他推廣計劃以及我們的任何忠誠或會員計劃或由我們的商業合作伙伴營運的其他忠誠或會員計劃</li>
                    <li>完成購買、交付產品、履行訂單及為&nbsp; 台端提供任何相關的售後服務</li>
                    <li>發送我們認為&nbsp; 台端會感興趣的資訊，這些資訊有時會根據&nbsp; 台端帳戶關聯的資料進行個人化定製</li>
                    <li>當&nbsp; 台端附近有產品、推廣或活動時通知&nbsp; 台端</li>
                    <li>請求&nbsp; 台端提供回饋，例如通過關於新產品或服務的調查並分析&nbsp; 台端與可口可樂之間的任何通訊</li>
                    <li>回答&nbsp; 台端的問題並為&nbsp; 台端提供客戶服務</li>
                    <li>有助我們產品及服務的研究和創新，當&nbsp; 台端參加現場活動時，例如可口可樂贊助或主辦的活動或產品抽樣</li>
                    <li>與可口可樂的商業務伙伴、服務供應商和裝瓶商合作伙伴分享</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style="width: 188.578px; vertical-align: top;">
                  <p><strong>支付或交易資料</strong></p>
                  <p>信用卡資料（例如持卡人姓名、信用卡號碼及到期日）、儲值卡或支付卡資料（例如持卡人姓名和卡號）
                    和其他付款或帳單資料；購物車商品狀態、積分記錄及獎品換領狀態；台端在完成交易時的位置（從IP地址、與WiFi網路的連接或行動設備獲得的位置資訊）；以及從獨特的QR碼或密碼或其他來源獲得的交易資訊（如&nbsp;
                    台端以什麼價格購買了什麼）。</p>
                </td>
                <td style="width: 419.422px; vertical-align: top;">
                  <ul>
                    <li>支付或換領商品、服務、禮物或獎品</li>
                    <li>完成購買、交付產品、履行訂單並為&nbsp; 台端提供任何相關的售後服務</li>
                    <li>了解消費或購買行為</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style="width: 188.578px; vertical-align: top;">
                  <p><strong>用戶原創內容（</strong><strong>UGC</strong><strong>）</strong></p>
                  <p>台端選擇透過服務提交的貼文、評論、意見、錄音、照片及影片。</p>
                </td>
                <td style="width: 419.422px; vertical-align: top;">
                  <ul>
                    <li>監控線上社群</li>
                    <li>記錄&nbsp; 台端的評論及意見並採取行動，例如在調查、客戶服務查詢及其他自由格式的文本框中</li>
                    <li>管理&nbsp; 台端參與的推廣活動，包括提交UGC</li>
                    <li>收集參與特定推廣或其他服務的用戶之照片</li>
                    <li>客製化&nbsp; 台端的服務體驗</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style="width: 188.578px; vertical-align: top;">
                  <p><strong>社交媒體平台</strong><strong>或通訊平台</strong><strong>帳戶相關的資訊</strong></p>
                  <p>當&nbsp; 台端透過Meta、Twitter、Instagram、 WhatsApp及 LINE
                    等社交媒體帳戶或通訊平台帳戶連接或登入服務時，我們會收集社交媒體平台或通訊平台所允許的個人資料及&nbsp; 台端的帳戶許可權，例如&nbsp;
                    台端的頭像、電子郵件、「讚」、興趣及好友、追蹤者或類似列表。</p>
                </td>
                <td style="width: 419.422px; vertical-align: top;">
                  <ul>
                    <li>個人化定製&nbsp; 台端的服務體驗</li>
                    <li>回覆&nbsp; 台端在社交媒體平台或通訊平台上發佈或傳遞的評論及詢問，並分析與可口可樂或關於可口可樂的通訊<br />（例如推文，貼文或訊息），以更了解消費者如何看待可口可樂</li>
                  </ul>
                  <p>（倘&nbsp; 台端稍後決定不再向我們提供社交媒體帳戶中的資料，煩請調整&nbsp; 台端社交媒體或通訊平台帳戶中的隱私設定。）</p>
                </td>
              </tr>
              <tr>
                <td style="width: 188.578px; vertical-align: top;">
                  <p><strong>位置資料</strong></p>
                  <p>o&nbsp;&nbsp;&nbsp; 透過行動裝置的運作系統（例如地區/城市）允許的全球定位系統（GPS）</p>
                  <p>o&nbsp;&nbsp;&nbsp; 來自IP地址或連接無線網絡、藍牙或無線網絡服務的大致位置，在&nbsp; 台端使用服務時自動收集（例如地區/城市）</p>
                </td>
                <td style="width: 419.422px; vertical-align: top;">
                  <ul>
                    <li>定製&nbsp; 台端的服務體驗</li>
                    <li>告知&nbsp; 台端附近何時有可用的產品、推廣或活動，或在&nbsp; 台端選擇許可時，允許其他用戶查看&nbsp; 台端位置</li>
                    <li>發送地點相關的廣告</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style="width: 188.578px; vertical-align: top;">
                  <p><strong>經合併而無法識辨任何服務用戶的個人資料</strong></p>
                </td>
                <td style="width: 419.422px; vertical-align: top;">
                  <ul>
                    <li>開展資料分析以分析用戶如何與服務互動，識別使用及活動趨勢，以便我們開發新功能及內容，並提供更有針對性或個人化的商品及服務，以滿足特定部分或類型用戶的期望</li>
                    <li>改善服務和用戶對服務的體驗</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style="width: 188.578px; vertical-align: top;">
                  <p><strong>透過服務共用的其他個人資料</strong></p>
                </td>
                <td style="width: 419.422px; vertical-align: top;">
                  <ul>
                    <li>管理線上社群</li>
                    <li>管理允許&nbsp; 台端分享個人資料之服務的推廣和其他功能</li>
                    <li>收集參與特定推廣或其他服務的用戶之照片，例如可口可樂的智能冷卻器</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <p><strong>b. <u>使用我們應用程式相關的資料</u></strong></p>
          <p>當&nbsp; 台端下載及安裝我們任何的應用程式時，我們收集的&nbsp; 台端相關資料取決於&nbsp; 台端行動裝置的操作系統及許可權。我們的應用程式需要使用&nbsp;
            台端行動裝置或網路瀏覽器中的某些功能和資料才能運作。例如，倘&nbsp; 台端想要無縫線上（網站）應用程式體驗，我們需要從&nbsp; 台端的網路瀏覽器收集和連結資料。</p>
          <p>如欲更多了解應用程式收集之特定資料，敬請查閱&nbsp; 台端的裝置設定或審閱&nbsp; 台端下載該應用程式的平台（例如Google Play和App
            Store）上可用的許可權資料。某些應用程式亦允許&nbsp; 台端在應用程式設定中查看或更改&nbsp; 台端對某些資料收集的選項。倘&nbsp; 台端更改設定，某些應用程式功能可能無法正常運作。</p>
          <p>如希望不再透過應用程式被收集任何資料，請刪除該應用程式。</p>
          <p><strong>c. <u>使用服務期間自動收集的資料</u></strong></p>
          <p>
            我們會自動從用戶的電腦及行動裝置中收集與用戶使用服務（尤其是網站）有關的某些資料。部分資料是自動收集的，可能涉及「個人資料」（如《隱私條例》中所定義）。這些資料是使用cookies、像素、網絡信標及類似的資料收集技術（統稱為「<strong>資料收集技術</strong>」）自動收集。
          </p>
          <p>我們自動收集的資料包括：</p>
          <ul>
            <li>有關 台端電腦或行動裝置的資料，例如裝置類型及標識號、瀏覽器類型、互聯網服務供應商、行動網絡及運作系統</li>
            <li>IP地址和大概地理位置（例如，國家或城市所在位置）</li>
            <li>電腦或行動裝置如何與服務互動，包括訪問服務的日期及時間、搜索請求及結果、滑鼠點擊及行動、查閱的特定網頁、點擊的連結及觀看的影片</li>
            <li>流量及使用量</li>
            <li>有關在與服務互動之前到訪過的第三方網站或服務的資料，這些資料會用於使廣告更加適合用戶</li>
            <li>與我們促銷資訊的互動，例如是否以及何時打開可口可樂的電子郵件</li>
          </ul>
          <p><strong>d. <u>當</u></strong><u> </u><strong><u>台端使用我們的</u></strong><strong><u>Coke+
              </u></strong><strong><u>電子商店進行購買和完成商品及</u></strong><strong><u>/</u></strong><strong><u>或服務交付時收集的資料</u></strong>
          </p>
          <p>當&nbsp; 台端在我們的Coke+電子商店完成購買或參與我們的推廣活動或互動遊戲贏取獎品時，我們將收集&nbsp;
            台端的聯絡方式、送貨資料（如收件人姓名、送貨地址及聯絡方式）、付款資料（如相關）及與&nbsp;
            台端訂單或送貨相關的其他資料。該等資料可能會移轉給我們的關係企業、第三方送貨商及裝瓶商合作伙伴，以確保將商品及/或服務交付給&nbsp;
            台端。台端知悉我們的關係企業、第三方送貨商及裝瓶商合作伙伴可能使用上述資料來完成訂單及/或交付。</p>
          <p><strong>e. <u>為了向客戶提供服務或售後服務而收集的資料</u></strong></p>
          <p>我們將收集&nbsp; 台端的帳戶資料，以便為&nbsp; 台端提供與我們服務相關所需的協助。倘與訂單有關，我們亦將收集&nbsp;
            台端的訂單資料。在與我們的人員溝通時，台端可能需要提供其他資料，以便我們提供適當的協助。</p>
          <p><strong>f. <u>為我們開展研究及分析而收集的資料</u></strong></p>
          <p>為了提高用戶體驗及我們商品和服務的品質，我們將使用向&nbsp; 台端收集的資料（例如&nbsp; 台端的身份識別資料及位置資料、購買及消費行為、購買趨勢、參與活動、獎賞積分狀態等）進行研究和資料分析。</p>
          <p><strong>g. <u>使用位置資料提供個人化推薦</u></strong></p>
          <p>我們會查閱及收集&nbsp; 台端裝置位置的資料（倘&nbsp; 台端授予必要許可權），並使用該等資料根據&nbsp; 台端位置及喜好提供產品及/或服務的推薦。</p>
          <p><strong>h. <u>從第三方收集的資訊</u></strong></p>
          <p>
            我們會不時收到來自第三方的個人資料，例如我們的服務供應商、供應商、支付服務供應商、裝瓶商合作伙伴及商業伙伴等，我們使用（並分析）該等資料從而更多了解我們的用戶、個人化用戶體驗，並更有效地促銷及改進服務。在該等情況下，倘&nbsp;
            台端的個人資料是由該等第三方（而非我們）收集，則該等資料收集（及使用或披露）將受該第三方的隱私政策而非本《隱私政策》的約束。我們無法控制，且在適用法律允許的範圍內，不對該等第三方使用或披露&nbsp;
            台端個人資料負責。</p>
          <p>我們從第三方收到的個人資料類型包括：</p>
          <ul>
            <li>有關購買的個人資料。以支付卡進行的交易由第三方支付服務供應商處理。我們無法獲得完整的銀行帳戶資料、信用卡號碼或簽帳金融卡號碼</li>
            <li>可在市場上從促銷服務供應商處獲得或由促銷合作伙伴透過活動收集的個人資料，用於識辨可能有興趣了解我們更多的人士，並補充我們已有的個人資料</li>
            <li>我們從第三方廣告合作伙伴處收到的、有助於我們提供符合度更高的廣告之個人資料</li>
            <li>我們的裝瓶商合作伙伴向我們提供的個人資料</li>
            <li>從公開管道可獲得的個人資料</li>
            <li>來自執法部門及其他政府機構的個人資料（但僅在極少數情況下）</li>
          </ul>
          <p>我們可能會將我們已有有關&nbsp; 台端的資訊與來自第三方的資料進行整合。我們要求每個第三方資料供應商確認其與我們共用個人資料是透明的，且在其他方面均屬合法。</p>
          <p><strong>i. <u>經</u><u> </u><u>台端同意收集的其他資料</u></strong></p>
          <p>我們可能會徵求&nbsp; 台端同意以收集特定類型的個人資料，以便&nbsp; 台端參與新活動、接收獨家內容或測試新功能。</p>
          <p><strong>j. <u>台端是否需要向我們提供</u></strong><u> </u><strong><u>台端的個人資料？</u></strong></p>
          <p>台端是否選擇向我們提供個人資料完全屬&nbsp; 台端個人選擇。但倘未提供我們要求的資料，我們可能無法：（a）處理&nbsp; 台端成為我們任何服務之會員或使用我們的任何服務的申請，（b）允許&nbsp;
            台端使用我們的任何部分或所有服務（包括我們的網站或應用程式），（c）於我方開設或繼續任何帳戶，或（d）向&nbsp; 台端提供（或繼續提供）任何服務，除非我們已載明提供此類資料均屬自願。</p>
          <p><strong>4. 可口可樂如何使用個人資料？</strong></p>
          <p>可口可樂使用個人資料以提供服務、管理其業務、保護用戶及行使其合法權利。</p>
          <p>我們使用個人資料以提供、個人化定製和改進服務（均在適用法律允許的情況下），包括：</p>
          <ul>
            <li>建立和更新用戶帳戶並滿足用戶要求</li>
            <li>驗證 台端身份及查閱或使用我們服務的資格</li>
            <li>將消費者的個人資料集中於由我們或代表我們的第三方所管理之資料庫中，並隨附第三方收集的資料</li>
            <li>向用戶發送促銷和非促銷通訊</li>
            <li>實現用戶間交流，例如線上社群，並允許用戶透過社交媒體平台參與社交分享</li>
            <li>向 台端提供有關我們產品、推廣、促銷活動及計劃的最新消息和公告，並透過網站或應用程式向&nbsp; 台端發送參加特別計劃的邀請</li>
            <li>通過展示為 台端量身打造的產品和優惠，使&nbsp; 台端能夠個人化&nbsp; 台端使用我們的網站和應用程式的體驗</li>
            <li>基於用戶線上活動所衍生的資料之促銷產品或服務（亦可稱為個人化或基於興趣的廣告），例如到訪列有我們廣告合作伙伴之廣告或cookies的網站，其中部分是基於地理位置。</li>
            <li>了解有關我們用戶的更多資料，以便推薦我們認為特定用戶會感興趣的內容</li>
            <li>徵求 台端的回饋意見，例如透過新產品或服務的相關調查，並分析&nbsp; 台端與可口可樂之間的任何通訊</li>
            <li>舉辦、管理和促進用戶參與我們的推廣或忠誠計劃及由我們的商業合作伙伴舉辦的其他忠誠或推廣計劃</li>
            <li>提供客戶服務或會員支援</li>
            <li>處理或向 台端提供獎賞或獎品，並根據我們的推廣或忠誠計劃管理&nbsp; 台端的收益和換領安排</li>
            <li>在 台端選擇參與時，管理由我們或我們的商業合作伙伴舉辦的活動、競賽和抽獎活動</li>
            <li>完成購買、交付產品、履行訂單，並為用戶提供相關的售後服務</li>
            <li>用於我們的業務目的，例如分析用戶如何與服務互動、辨別使用和活動趨勢，以便我們能夠開發新功能和內容，並提供更有針對性或個人化的商品和服務，以滿足消費者的期望</li>
            <li>改善服務和用戶體驗</li>
            <li>用於資料分析、研究、產品開發和機器學習，使我們能夠更好地了解消費者並為其提供創新</li>
            <li>用於監控和測試服務，包括排除運作困難</li>
            <li>為分析目的而編制不受本《隱私政策》約束的匿名資料，用於改進可口可樂的產品和服務及類似的商業目的，並適用法律允許的其他用途</li>
            <li>偵測和防止欺詐、濫用和未經授權使用服務</li>
            <li>用於風險管理和類似的管理目的，例如監控和強制使人遵守用戶協議以及遵守適用於可口可樂的法律</li>
          </ul>
          <p><strong>5. 可口可樂是否使用</strong><strong>COOKIES</strong><strong>及其他資料收集技術？</strong></p>
          <p>我們使用cookies及其他資料收集技術以辨別&nbsp; 台端及/或&nbsp; 台端的裝置，並在&nbsp; 台端使用服務時收集&nbsp; 台端相關的個人資料。</p>
          <p><em>某些為服務一部分的網站載有關於適用於特定網站和消費者的</em><em>cookies</em><em>及其他資料收集技術的特定通知。倘</em><em>&nbsp;
            </em><em>台端到訪包含</em><em>cookies</em><em>通知的網站，則適用該網站的通知。</em></p>
          <p><strong><u>什麼是</u></strong><strong><u>cookies</u></strong><strong><u>？</u></strong></p>
          <p>Cookies是發送到&nbsp;
            台端的網路瀏覽器或電腦硬碟或從其中存取的小型文字檔。Cookie通常包含產生cookie的域名（網路位置）、cookie的「生命週期」（即何時到期）及隨機衍生的獨特編號或類似識別碼。Cookie亦可能包含有關&nbsp;
            台端的電腦或裝置資料，例如設定、瀏覽歷史及使用服務時進行的活動。</p>
          <p>可口可樂亦使用「像素」（有時稱為網路信標）。像素是透明圖像，可以橫跨不同網站和時間收集有關開啟電子郵件和網站使用情況的資料。</p>
          <p>
            可口可樂在服務中設置的cookies稱為第一方cookies。任何其他方在服務中設置的cookies稱為第三方cookies。第三方cookies在服務上或透過服務啟用第三方功能，例如分析和促銷自動化。設置第三方cookies的各方能夠在&nbsp;
            台端使用裝置使用服務以及使用該裝置到訪某些其他網站時予以識別。如欲了解有關cookies的更多資訊，敬請訪問<a href="http://www.allaboutcookies.org"
              target="_blank" rel="noopener">www.allaboutcookies.org</a>。</p>
          <p>某些網路瀏覽器（包括Safari、Internet Explorer、Firefox和Chrome）包含「請勿追蹤」(<strong><em>DNT</em></strong>)
            或類似功能，可向網站發出信號，表明用戶不希望其線上活動和行為被追蹤。倘對特定DNT信號作出回應的網站接收到DNT信號，則瀏覽器將會阻止該網站從瀏覽器緩存中收集某些資料。並非所有瀏覽器都提供DNT選項，且DNT信號尚未統一。因此包括可口可樂在內的許多網站營運商尚未對DNT信號作出回應。
          </p>
          <p><strong><u>可口可樂為什麼使用</u></strong><strong><u>cookies</u></strong><strong><u>及其他跟蹤技術？</u></strong></p>
          <p>服務需要某些cookies才能運作。其他cookies使我們能夠追蹤用戶對針對性廣告的興趣，並為我們的用戶改善服務體驗。</p>
          <p>在&nbsp; 台端使用服務時提供的cookies類型及其使用原因如下：</p>
          <ul>
            <li>服務運作所需之<strong>嚴格必要的</strong><strong>cookies</strong></li>
            <li><strong>性能或分析型</strong><strong>cookies</strong>收集關於如何使用服務的資料，以便我們能夠分析和改進服務。瀏覽器關閉後且在
              台端刪除之前，<strong>性能或分析型</strong><strong>cookies</strong>通常會保留在&nbsp; 台端的電腦中。</li>
            <li><strong>廣告型</strong><strong>cookies</strong>通過幫助我們展示
              台端可能感興趣的廣告、防止同一廣告過於頻繁地出現及確保為廣告商正確展示廣告，使廣告資訊更加貼近&nbsp; 台端。</li>
            <li><strong>社交媒體型</strong><strong>cookies</strong>允許用戶更輕鬆地與社交媒體平台互動。我們並無權控制社交媒體型cookies，其不允許我們在未經
              台端許可的情況下到訪&nbsp; 台端的社交媒體帳戶。如欲了解所使用的cookies資料，敬請參閱相關社交媒體平台的隱私政策。</li>
          </ul>
          <p>
            資料收集技術使可口可樂能夠監控網頁間的流量模式，傳送或與cookies通訊，以了解用戶在看到我們於第三方網站所展示的線上廣告後是否會查閱服務，提高服務性能並衡量我們的電子郵件促銷活動是否成功。可口可樂的cookies政策載明了可口可樂對資料收集技術的使用。<em>https://us.coca-cola.com/privacy-policy#cookies</em>
          </p>
          <p>我們亦想確保&nbsp; 台端了解，在適用法律允許的情況下，我們會與第三方機構合作，當中包括分析和監測&nbsp; 台端對服務（包括網站和應用程式）的使用和活動，並進行有針對性的廣告宣傳（或
            「再促銷」）。這些第三方機構可能包括（但不限於）Google、Doubleclick、Yahoo!、Facebook、Nielsen/NetRatings和Adobe等。他們使用資料收集技術，如cookies、聚光燈監測（這用於管理標籤，例如用於跟蹤用戶點擊了哪個廣告將他們帶到網站）和
            「像素」（或網路信標，用於監測&nbsp; 台端的行為，例如&nbsp;
            台端有否有拉動頁面），為這項研究收集資訊。透過此類資料收集技術收集的資料，有助於可口可樂分析服務的使用方式，並針對某些服務及在某些司法管轄區，對促銷資訊和電子廣告進行個人化定製。</p>
          <p>這些第三方機構會代表可口可樂使用相關資料彙編報告，以協助可口可樂運作和提供服務。</p>
          <p><strong><u>台端的</u></strong><strong><u>Cookie</u></strong><strong><u>選擇</u></strong></p>
          <p>台端有權決定是否接受cookies。台端可將瀏覽器設定為拒絕所有cookies或當有設置cookie時提示&nbsp; 台端（大多數瀏覽器會自動接受cookies，但允許&nbsp;
            台端禁用cookies，煩請注意倘沒有cookies，服務的某些功能可能無法正常運作。）</p>
          <p>例如，倘&nbsp; 台端擬退出用於Google分析的cookies，Google已經研發出一款供&nbsp; 台端選擇退出的瀏覽器外掛程式。台端可在此處下載並為&nbsp; 台端的網絡瀏覽器安裝外掛程式
            [連結<a href="https://tools.google.com/dlpage/gaoptout" target="_blank"
              rel="noopener"><em>https://tools.google.com/dlpage/gaoptout</em></a>]。台端可在瀏覽器上選擇適當的設定以拒絕使用該等cookies。</p>
          <p><strong>6. 可口可樂如何分享個人資料？</strong></p>
          <p>
            可口可樂在法律允許的情況下或在被要求時，將與協助運作服務和開展業務的個人及企業分享個人資料。當用戶要求我們分享個人資料時，我們也會按其要求分享。我們要求接收個人資料的人員遵守本《隱私政策》，除非用戶獲悉不同的隱私政策或通知將適用。
          </p>
          <p>可口可樂與以下各類人員分享個人資料，用於本《隱私政策》中所述之目的（無論該等接收人員是否在臺灣境內）：</p>
          <ul>
            <li>我們的母公司（可口可樂公司）及其關係企業、我們的任何裝瓶商合作伙伴和商業合作伙伴。敬請點擊此處參閱我們的關係企業列表：<a
                href="http://www.coca-colacompay.com/brands">http://www.coca-colacompay.com/brands</a></li>
            <li>推廣服務的促銷供應商（例如透過電子郵件促銷）並不時補充我們現有的用戶相關個人資料。例如，Meta接收並使用與使用服務相關的某些資料，以協助我們在其平台上投放個人化廣告並評估該廣告的有效性</li>
            <li>
              服務供應商，使其能夠代表我們履行服務，包括網站託管、資料分析、支付服務處理、信用卡處理、訂單履行、提供基礎設施、資訊科技服務、資料安全、電子商務營運、活動及推廣管理、客戶服務、審核服務、調查、研究、推廣、優惠和忠誠或會員計劃管理以及其他有助於我們開展業務的活動。其中一些服務供應商具有全球責任
            </li>
            <li>我們可能與商業合作伙伴建立特殊關係，例如某些忠誠或會員計劃的營運商。在向特定商業合作伙伴分享用戶的個人資料之前，我們將為&nbsp;
              台端提供資料分享相關的選擇。由於這些第三方將根據其隱私慣例使用個人資料，台端應當自行查看其網站以獲取有關其隱私慣例的資訊</li>
            <li>抽獎、即贏促銷、競賽和其他推廣活動的贊助商</li>
            <li>倘&nbsp; 台端參與社交分享（例如，倘&nbsp; 台端將社交媒體帳戶連接到我們的網站或應用程式或使用&nbsp; 台端社交媒體帳戶登入我們的網站或應用程式），與&nbsp;
              台端社交媒體帳戶關聯的朋友或連絡人、網站或應用程式的其他用戶，及&nbsp; 台端社交媒體帳戶供應商。倘&nbsp; 台端不希望向服務的其他用戶或&nbsp; 台端社交媒體帳戶供應商分享&nbsp;
              台端個人資料，煩請&nbsp; 台端別將社交媒體帳戶連接至我們的網站或應用程式，亦別使用我們的網站或應用程式參與社交分享</li>
            <li>雲端儲存供應商</li>
            <li>關於任何實際或擬議合併、收購或投資於我們全部或部分業務，潛在或實際的收購方或投資者及其專業顧問。我們將盡最大努力確保本《隱私政策》條款在交易後適用於個人資料，或確保用戶提前收到變更個人資料處理的通知</li>
            <li>專業顧問，例如律師、會計師、保險公司及資訊安全和鑑識專家</li>
            <li>主管執法機構、政府監管機構及法院，當我們認為為了下述目的有必要披露時：（i）遵守法律，（ii）行使、確立或捍衛合法權利，或（iii）保護用戶、商業合作伙伴、服務供應商或其他第三方的切身利益</li>
            <li>經&nbsp; 台端許可的其他第三方</li>
          </ul>
          <p>倘我們分享個人資料，我們要求接收人員按照本《隱私政策》及我們的保密和安全要求處理個人資料。個資人資料將被以任何形式分享(實體與電子形式)。</p>
          <p><strong>7. 可口可樂如何保護個人資料？</strong></p>
          <p>可口可樂致力於保護其儲存的個人資料。我們實施各種措施以協助我們保護個人資料免遭未經授權的查閱和使用。</p>
          <p>可口可樂使用技術上、實際上及行政上，旨在保護我們所處理之個人資料的措施。我們的保障措施旨在達到與處理&nbsp;
            台端個人資料的風險相應的安全級別，並包括（如適用）確保處理系統的持續保密性、完整性、可用性和彈性的措施，及定期測試、評估和審視確保安全處理個人資料之技術和組織措施有效性的程序。但是，可口可樂無法完全消除處理個人資料相關的安全風險。
          </p>
          <p>台端有責任維護&nbsp; 台端帳戶憑證資料的安全性。可口可樂會將&nbsp; 台端透過其帳戶憑證資料使用服務視為&nbsp; 台端授權的使用。</p>
          <p>倘我們懷疑或偵測到任何違反安全的行為，可口可樂可能會暫停&nbsp; 台端使用全部或部分服務，恕不另行通知。倘&nbsp; 台端認為提供給可口可樂的任何資料或&nbsp; 台端帳戶不再安全，請立即透過上述
            「聯絡我們」一節中規定的任何通訊渠道通知我們。</p>
          <p>禁止未經授權存取個人資料和服務（包括抓取），並可能導致刑事起訴。</p>
          <p><strong>8. 可口可樂將保留個人資料多長時間？</strong></p>
          <p>除非適用法律要求或允許更長的保留期限，我們會在實現本《隱私政策》所述目的所需之時間內保留用戶相關的個人資料。</p>
          <p>我們欲保持&nbsp; 台端個人資料準確和反映最新情况。我們根據本《隱私政策》及我們的資料保留政策保留所處理的個人資料。在決定保留期限時，我們會考慮各項標準，例如&nbsp; 台端所要求或我們提供給&nbsp;
            台端的產品和服務類型、我們與&nbsp;
            台端關係的性質和期限，及適用法律規定的任何強制性保留期限。在相關保留期屆滿時，我們會刪除或匿名化個人資料，或者，倘我們無法刪除或匿名化個人資料，我們將會分隔並安全地儲存個人資料，直至能夠刪除或匿名化該等個人資料為止。
          </p>
          <p>一旦我們將個人資料匿名化，其就不再是個人資料。我們使用匿名資料時將受限於其適用法律。</p>
          <p><strong>9. 在直接促銷中使用個人資料</strong></p>
          <p>我們可能會使用&nbsp; 台端的個人資料為&nbsp; 台端提供直接促銷，為此我們需要取得&nbsp; 台端同意（包括表示不反對）。因此，請注意：</p>
          <p>(a)我們可能會出於直接促銷目的使用以下各類資料：</p>
          <ul>
            <li>我們不時持有的用戶姓名、聯絡資料、產品及服務資訊、使用模式及行為、位置及人口統計資料；及</li>
            <li>與&nbsp; 台端不時使用我們網站和應用程式有關的資料；</li>
          </ul>
          <p>(b) 我們可能會向&nbsp; 台端促銷以下各類服務和產品：</p>
          <ul>
            <li>可口可樂公司、其附屬機構（包括我們）（下稱「<strong>集團</strong>」）和/或我們的商業合作伙伴或裝瓶商合作伙伴提供的商品和服務；</li>
            <li>獎賞、忠誠或特權計劃及相關服務和產品；</li>
            <li>集團及/或我們的商業合作伙伴或裝瓶商合作伙伴提供之福利、推廣優惠、服務及產品；</li>
            <li>商家產品及服務，包括獎勵和優惠；</li>
            <li>銀行、金融和投資機構以及保險公司的金融、銀行服務、抵押貸款、保險和其他相關服務和產品；</li>
            <li>在私人俱樂部的使用和特權；</li>
            <li>專業的客戶服務諮詢，包括但不限於活動策劃、假期、酒店、旅遊、門票預訂等；</li>
            <li>
              由零售店和/或線上業務提供的消費產品和服務，包括服裝、鞋、時裝、時尚配飾和美容、健康和個人護理、奢侈品、家居和生活、家用產品、食品、酒、酒類和飲料、糖果、雜貨、書籍和文具、玩具、香煙和雪茄、香水和化妝品、嬰兒用品、電話和行動設備、電子和電器、電腦軟體，以及遊戲、箱包、傢俱、體育和休閒設備；
            </li>
            <li>醫藥產品、醫療和醫藥服務、治療師；</li>
            <li>輔導、指導、家庭清潔、清潔服務、景觀和植物維護服務、農業服務、兼職家政服務、水管服務、鎖匠、理髮和沙龍服務、健身房和健身服務、洗衣服務、花店、車輛牽引和機動車輛服務；</li>
            <li>手錶、珠寶和配件；</li>
            <li>餐館，食品、酒精和飲料的餐飲服務；</li>
            <li>旅行、接待和住宿服務和產品，包括但不限於酒店和服務式公寓、旅行社提供的度假套餐產品和服務、景點門票；</li>
            <li>運輸和物流服務，包括但不限於航空旅行和非航空運輸服務，如酒店接送、汽車租賃、巴士營運商、火車營運商和郵輪營運商，以及快遞服務和航運服務；</li>
            <li>會議和展覽管理服務，諮詢和行銷研究服務，供應鏈管理，活動和專案管理服務，遊戲服務，廣告和公共關係服務，媒體制作服務；</li>
            <li>音樂會、畫廊、展覽、電影、戲劇、體育、特別和文化活動的門票；</li>
            <li>綠色和自然活動；</li>
            <li>技術、電信、網路和行動通訊服務、社交網路和媒體；</li>
            <li>平面和室內設計服務，攝影服務，印刷和出版服務；</li>
            <li>法律服務、醫療服務、會計、審計、簿記和稅務服務、工程服務、建築服務、測量服務、設備租賃服務、接待服務；</li>
            <li>有關教育和/或個人提升的專業建議和/或諮詢；以及</li>
            <li>支持包括體育、娛樂、藝術和文化專案在內的，及有助減輕貧困、促進教育、推動宗教和其他有利社區的慈善活動。</li>
          </ul>
          <p>(c) 上述服務及產品可能由我們及/或下述各方提供或招攬：</p>
          <ul>
            <li>本集團的任何成員；</li>
            <li>本集團的商業合作伙伴和/或裝瓶商伙伴；及</li>
            <li>第三方獎賞、忠誠、合作品牌或特權計劃供應商；</li>
          </ul>
          <p>(d) 除促銷上述服務及產品外，我們亦擬將上文（a）段所述資料提供予本集團其他成員，供其用於促銷該等服務及產品，為此我們要求用戶提供書面同意（包括表示不反對）。</p>
          <p>倘&nbsp; 台端不希望我們在直接促銷中使用或提供&nbsp; 台端個人資料，台端可選擇不接收直接促銷材料及/或通訊。如欲了解如何退出直接促銷，敬請使用上述
            「聯絡我們」部分中指定的任何通信管道或我們不時規定的任何其他通信渠道聯絡我們。</p>
          <p>同時，台端可以透過使用上述「聯絡我們」部分中所列之聯絡資料以書面形式通知我們，以恢復接收材料及/或通訊（倘&nbsp; 台端之前選擇不再接收該等來自相關服務的材料及/或通訊）。</p>
          <p><strong>10. 個人資料有哪些選擇？</strong></p>
          <p>台端可選擇可口可樂處理&nbsp; 台端個人資料的方式。台端可按照「聯絡我們」部分中指定的任何通信管道聯絡可口可樂行使&nbsp; 台端的隱私權。在某些情況下，&nbsp; 台端查閱或控制&nbsp;
            台端個人資料的能力會受到適用法律的限制。</p>
          <p><strong>行動裝置喜好</strong></p>
          <p>行動操作系統和應用程式平台（例如Google Play、App
            Store）有針對特定類型的行動裝置資料和通知的許可權設定，例如查閱聯絡人、地理位置服務和推送通知。台端可使用行動設備上的設置來同意或拒絕某些資訊收集及/或推送通知。某些應用程式亦可能有允許&nbsp;
            台端更改許可權和推送通知的設定。對於某些應用程式，更改設定可能會導致應用程式的某些功能無法正常運作。</p>
          <p>台端可透過刪除應用程式以停止其所有資料收集。倘&nbsp; 台端刪除該應用程式，亦請&nbsp; 台端考慮檢查操作系統設定，以確認與&nbsp; 台端使用該應用程式相關的唯一識辨碼及其他活動已從&nbsp;
            台端的行動裝置中刪除。</p>
          <p><strong>11.&nbsp;</strong><strong>可口可樂如何保護兒童隱私？</strong></p>
          <p>某些服務有年齡限制，這意味著我們可能會在允許&nbsp; 台端使用這些服務之前問問題以驗證&nbsp; 台端年齡。</p>
          <p>
            未滿20歲的用戶必須事先徵得其父母或監護人對本《隱私政策》的同意，否則不得使用本服務。倘我們獲悉我們已在未經其父母或監護人同意的情況下收集或接收了未滿20歲用戶的個人資料，我們將採取措施從我們的資料庫中刪除該等個人資料。
          </p>
          <p>用戶的父母或監護人可使用本頁頂部「聯絡我們」部分中列出的聯絡方式聯絡我們，以查詢、更正或刪除用戶個人資料。</p>
          <p>根據我們的《<a href="https://www.coca-colacompany.com/policies-and-practices/responsible-marketing-policy"
              target="_blank" rel="noopener">Responsible Marketing Policy</a> （責任促銷政策）》，可口可樂不會向未滿13歲兒童直接促銷我們的產品和服務。</p>
          <p><strong>12. 可口可樂是否會將個人</strong>資料移轉<strong>到其他國家？</strong></p>
          <p>可口可樂可能會將個人資料跨境移轉到我們及我們供應商和合作伙伴開展業務所在的任何地區（臺灣以外）。其他司法管轄區的資料保護法可能與臺灣法律不同（在某些情況下，保護程度較低）。</p>
          <p>倘&nbsp; 台端個人資料被我們或代表我們移轉到臺灣以外地區，我們將根據本《隱私政策》使用適當的保障措施來保護&nbsp;
            台端的個人資料。這些保障措施包括就可口可樂關係企業之間及我們供應商和合作伙伴之間移轉個人資料的標準合約條款或範本合約達成協議。訂立該等合約後，合約即要求我們的關係企業、供應商及合作伙伴根據適用的隱私法律保護個人資料。
          </p>
          <p>如欲索取有關我們標準合約條款或範本合約，及跨境個人資料移轉的其他保護措施資訊，請通過[<a
              href="mailto:privacy@coca-cola.com">privacy@coca-cola.com</a>] 聯絡我們的隱私辦公室。</p>
          <p>通過使用我們的服務（包括我們的網站和應用程式），即表示&nbsp; 台端同意將個人資料移轉到臺灣以外的任何地區，且&nbsp; 台端知悉該等地區的資料保護法可能不同於臺灣的個資法（在某些情況下，保護程度較低）。
          </p>
          <p><strong>13. 本《隱私政策》何時發生變更？</strong></p>
          <p>面對不斷變化的法律、技術或業務發展，我們可能會不時更新本《隱私政策》。最新版本隨時可透過服務獲得。</p>
          <p>當我們更新本《隱私政策》時，我們將發佈更新版本並更改上述生效日期。 我們亦將採取適當措施，在作出我們認為會影響&nbsp; 台端隱私權的重大變化之前通知&nbsp; 台端，以便&nbsp;
            台端有機會在生效之前查閱修訂後的《隱私政策》。煩請定期查看本《隱私政策》，確保&nbsp; 台端了解最新版本。</p>
          <p><strong>14. 台端</strong><strong>的</strong><strong>權利</strong></p>
          <p>台端有權：</p>
          <ul>
            <li>查看我們是否持有有關&nbsp; 台端的資料並查閱這些資料；</li>
            <li>要求我們更正與&nbsp; 台端有關的任何不準確資料；</li>
            <li>要求我們提供&nbsp; 台端個人資料的副本；</li>
            <li>要求我們停止蒐集、處理與利用&nbsp; 台端的個人資料；</li>
            <li>要求我們刪除&nbsp; 台端的個人資料；及</li>
            <li>確認我們的資料相關政策及慣例，並了解我們持有的個人資料類型。</li>
          </ul>
          <p>我們有權就處理任何查閱資料要求收取合理的費用。倘要求查閱資料或更正資料，或要求我們提供所持資料的政策、慣例及類型相關的資訊，煩請郵寄至下述地址以聯絡可口可樂人員：</p>
          <p>（通過郵寄）<br />香港商可口可樂有限公司台灣分公司<br />105410 臺北市松山區敦化北路88號14樓之2<br />系統管理員收</p>
          <p>或</p>
          <p>(通過Facebook通訊功能)<br />可口可樂Facebook頁面</p>
          <p>本通知中的任何內容均不會限制資料當事人在個資法下的權利。</p>
        </div>
      </div>
    </div>
  </StandardLayout>
</template>

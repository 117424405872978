<script setup>
import { ref, onMounted, defineEmits } from 'vue'
const tooltips = ref([])

const status = ref({
  currentActive: 0,
  isShowing: false
})

const emit = defineEmits(['closeTooltip', 'initEventStar'])

onMounted(() => {
  tooltips.value = document.querySelectorAll('.js--tourguide')
  init()
})

function init () {
  const activeItem = tooltips.value[status.value.currentActive]
  if (!activeItem) {
    emit('closeTooltip', false);
    return
  }
  showItem(activeItem)
  tooltips.value.forEach((item, index) => {
    item.style.display = "block";
    if(item !== activeItem) {
      item.classList.add('inActive')
    }
  })
}

function handleIntroductionTour () {
  if (status.value.isShowing) return
  status.value.isShowing = true
  const activeItem = tooltips.value[status.value.currentActive]
  const nextItem = tooltips.value[status.value.currentActive + 1]

  if(nextItem) {
    hideItem(activeItem)
    showItem(nextItem)
    ++status.value.currentActive
  } else {
    hideItem(activeItem)
    emit('closeTooltip', false);
  }
  setTimeout(() => {
    status.value.isShowing = false
  }, 1000);
}

function hideItem(inActiveItem) {
  inActiveItem.classList.remove('active')
  inActiveItem.parentElement.classList.remove('activeElementTour')
}

function showItem(activeItem) {
  activeItem.classList.add('active')
  activeItem.parentElement.classList.add('activeElementTour')
  // activeItem.dataset.init == emit('initEventStar', true) ? console.log('runStar') : '' // run star Journey
  activeItem.dataset.init == 'runStar' ? emit('initEventStar', true) : '' // run star Journey
}

</script>

<template>
  <div class="tooltip-backdrop">
    
  </div>
  <div class="tooltip-nextLayper" @click="handleIntroductionTour">
    
  </div>
</template>

<style lang="scss">
.tooltip-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
.tooltip-nextLayper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1001;
}

.js--tourguide {
  /* display: none; */
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;
  z-index: -1;
  

  &.active {
    /* display: block; */
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
}

.activeElementTour {
  z-index: 1000;
  position: relative;
}
</style>
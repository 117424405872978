<script setup>
import Button from "@/Components/Button.vue";
import Icons from "@/Components/Icons.vue";
import { Head, Link, usePage } from "@inertiajs/inertia-vue3";
import { Inertia } from "@inertiajs/inertia";
import Modal from "@/Components/Modal/Modal.vue";
import ModalBlank from "@/Components/Modal/ModalBlank.vue";
import StandardLayout from "@/Layouts/StandardLayout.vue";
import Tabs from "@/Components/Tabs.vue";
import Input from "@/Components/Input.vue";
import { ref, onMounted, computed, reactive, nextTick } from "vue";
import { Html5Qrcode } from "html5-qrcode";
// import dailyScanLimit from "@/services/dailyScanLimit";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import {
    convertColorInvert,
    convertColorContrast,
    colorTransform,
    pushGTM,
    prepareAPIInfomation,
    isLoginRL
} from "@tools/app";
import redeemPinRealLeaf from "@/services/redeemPinRealLeaf";
import ModalReward from "@/Components/RealLeaf/ModalReward.vue";
// import { HK_OCP_TRACKING_CAMPAIGN_ID } from "@constants/main";

const $page = usePage();
const $store = useStore();
const code = ref($page.props.value.code);
const isScanning = ref(false);
const statusReward = ref(false);
const checkTypeCode = ref("scan");
const loading = ref(true);
const newEntry = ref(0);
const totalCap = ref(0);
const remainingCap = ref(0);
const inputs = reactive([{ val: "", error: false, message: "" }]);
const showModal = ref(false);
const modalMessage = ref("");
const modalIcon = ref("face-smile");
const showGuideModal = ref(false);
const promptRewards = ref(false);
const needRedirectOnCloseModal = ref(false);
const { t } = useI18n();
const isMission = ref(!!$page.props.value.isRGB);
let scanner;

const doScan = async () => {
    checkTypeCode.value = "scan";
    isScanning.value = true;
    // const { uuid } = await prepareAPIInfomation();
    codeFromScan().then((val) => {
        isScanning.value = false;
        code.value = val;
        console.log("scanval", val);
        if (!code.value) {
            showModal.value = true;
            modalMessage.value = t("scan.error_qr_code_invalid");
            return;
        }
        verifyCode(
            (errors) => {
                showModal.value = true;
                modalMessage.value = errorMap(errors);

                pushGTM({
                    event: "event_qr_result",
                    response: "failure"
                });
            },
            () => {
                // when scan success
                pushGTM({
                    event: "event_qr_result",
                    response: "success"
                });
            }
        );
    });
};
const doInputCode = async () => {
    checkTypeCode.value = "manual";
    let isAnyError = false;
    inputs.forEach((input) => {
        if (!input.val.trim()) {
            input.error = true;
            input.message = t("scan.error_message_required");
            isAnyError = true;
        }
    });
    if (isAnyError) return;
    code.value = inputs.map((input) => input.val).join(",");

    // const { uuid } = await prepareAPIInfomation();
    verifyCode(
        (errors) => {
            if (errors.errorCode > 0) {
                inputs[0].error = true;
                inputs[0].message = errorMap(errors);
                return;
            }
            if (!errors.success) {
                inputs[0].error = true;
                inputs[0].message = errorMap(errors);
            }

            pushGTM({
                event: "event_serialsubmit_result",
                response: "failure"
            });
        },
        () => {
            // when input PIN success
            pushGTM({
                event: "event_serialsubmit_result",
                response: "success"
            });
        }
    );
};
let callbackId;
const codeFromScan = () => {
    return new Promise((resolve, reject) => {
        const resolveHandle = (text) => {
            const parsedText = (text || "").split("/");
            const totalLength = parsedText.length;
            const code = parsedText.pop();
            const includeRL = (parsedText.pop() || "")
                .toLowerCase()
                .includes("rl");
            resolve(totalLength >= 3 && includeRL ? code : "");
            scanner.stop();
        };
        Html5Qrcode.getCameras()
            .then((devices) => {
                if (devices && devices.length) {
                    scanner = new Html5Qrcode("qr-reader");
                    scanner
                        .start(
                            { facingMode: "environment" },
                            {
                                fps: 1,
                                qrbox: {
                                    width: window.innerWidth,
                                    height: window.innerHeight,
                                },
                                disableFlip: true,
                                videoConstraints: {
                                    focusMode: "continuous", // manual
                                    facingMode: "environment",
                                    focusDistance: {
                                        min: 0.04,
                                        ideal: 0.12,
                                        max: 0.2,
                                    },
                                    advanced: [{ zoom: 2.0 }],
                                    width: { ideal: 4096 },
                                },
                            },
                            (text, result) => {
                                clearInterval(callbackId);
                                resolveHandle(text);
                                scanner.stop();
                            }
                        )
                        .catch((err) => {
                            console.log(err);
                            clearInterval(callbackId);
                            resolve("");
                        });
                    // at the same time hook and apply filter before passing to qr regconize
                    let ctx, video, videoSetting;
                    let canvas = document.getElementById("qr-canvas");
                    let wrap = document.getElementById("qr-reader");
                    callbackId = setInterval(() => {
                        if (!canvas) {
                            canvas = document.getElementById("qr-canvas");
                            video = wrap.querySelector("video");
                            return;
                        }
                        // canvas.style.display = "block";
                        if (!ctx) {
                            ctx = canvas.getContext("2d");
                        }
                        if (!videoSetting) {
                            videoSetting = video.srcObject
                                ?.getTracks()[0]
                                ?.getSettings();
                        }
                        const vw = videoSetting.width || window.innerWidth;
                        const vh = videoSetting.height || window.innerHeight;
                        // try to fix crash/oom bug?
                        if (!scanner.isScanning) {
                            clearInterval(callbackId);
                            console.log("scanner stopped unknow reason.");
                            // showModal.value = true;
                            // modalMessage.value = "scanner stopped";
                            return;
                        }
                        try {
                            // zoom to scan
                            ctx.drawImage(
                                video,
                                ~~(vw * 0.32),
                                ~~(vh * 0.32),
                                ~~(vw * 0.36),
                                ~~(vh * 0.36),
                                0,
                                0,
                                canvas.width,
                                canvas.height
                            );
                            scanner.scanContext(resolveHandle, () => {});
                            // for other regconize and improvoment
                            let imgData = ctx.getImageData(
                                0,
                                0,
                                canvas.width,
                                canvas.height
                            );
                            ctx.putImageData(colorTransform(imgData), 0, 0);
                            scanner.scanContext(resolveHandle, () => {});

                            // zoom more
                            ctx.drawImage(
                                video,
                                ~~(vw * 0.3),
                                ~~(vh * 0.3),
                                ~~(vw * 0.4),
                                ~~(vh * 0.4),
                                0,
                                0,
                                canvas.width,
                                canvas.height
                            );
                            scanner.scanContext(resolveHandle, () => {});
                            // for other regconize and improvoment
                            imgData = ctx.getImageData(
                                0,
                                0,
                                canvas.width,
                                canvas.height
                            );
                            ctx.putImageData(colorTransform(imgData), 0, 0);
                            scanner.scanContext(resolveHandle, () => {});

                            // zoom even more
                            ctx.drawImage(
                                video,
                                ~~(vw * 0.35),
                                ~~(vh * 0.35),
                                ~~(vw * 0.3),
                                ~~(vh * 0.3),
                                0,
                                0,
                                canvas.width,
                                canvas.height
                            );
                            scanner.scanContext(resolveHandle, () => {});
                            // for other regconize and improvoment
                            imgData = ctx.getImageData(
                                0,
                                0,
                                canvas.width,
                                canvas.height
                            );
                            ctx.putImageData(colorTransform(imgData), 0, 0);
                            scanner.scanContext(resolveHandle, () => {});
                        } catch (e) {
                            console.log("scan error: ", e);
                            clearInterval(callbackId);
                            showModal.value = true;
                            modalMessage.value = "Something went wrong!";
                        }
                    }, 1000 / 30);
                }
            })
            .catch((err) => {
                showModal.value = true;
                modalMessage.value = t("scan.camera_permission_denied");
            });
    });
};

//
const verifyCode = async (onError, onSuccess) => {
    loading.value = true;
    const response = await redeemPinRealLeaf(code.value, checkTypeCode.value);

    loading.value = false;
    if (response.success) {
        statusReward.value = true;
        newEntry.value = ~~response?.data?.rewardInfo?.token || 0;
        onSuccess && onSuccess();
    } else {
        // show error
        if (response.code && response.code !== 422) {
            onError && onError({ message: response.message });
            return;
        }
        onError && onError(response);
    }
    localStorage.removeItem("qrCode");
    localStorage.removeItem("qrCodeUrl");
};
const changeTab = (activedTab) => {
    if (scanner) {
        if (scanner.isScanning) {
            scanner.stop();
            isScanning.value = false;
            clearInterval(callbackId);
        }
    }
};

const addInputCode = () => {
    if (inputs.length < remainingCap.value) {
        inputs.push({
            val: "",
            error: false,
            message: t("scan.error_message_required"),
        });
    }
};

onMounted(() => {
    const isLogin = isLoginRL($store.state.app.msal);
    const codeParam = $page.props.value.code;
    if (isLogin && !codeParam) {
        loading.value = false;
    }
    // case scan QR code from outside and direct browse to the campaign
    // code.value = route().params.code || ''
    if (code.value && code.value.length) {
        isMission.value = true;
        checkTypeCode.value = "scan";
        showGuideModal.value = false;
        isLogin &&
            verifyCode((errors) => {
                // show errors
                showModal.value = true;
                modalMessage.value = errorMap(errors);
                code.value = "";

                pushGTM({
                    event: "event_qr_result",
                    response: "failure"
                });
            },
            () => {
                // when scan success
                pushGTM({
                    event: "event_qr_result",
                    response: "success"
                });
            });
        return;
    }
    const localShowGuideModal = localStorage.getItem("showGuideModal_Scan");
    if (!localShowGuideModal) {
        showGuideModal.value = true;
        localStorage.setItem("showGuideModal_Scan", true);
    }
});

const onStarCollected = () => {
    setTimeout(() => {
        Inertia.visit(route("ocp.mainDashboard"));
    }, 0);
};

const errorMap = (errorObject) => {
    const result = errorObject.message || "";
    if (errorObject.data) {
        if (errorObject.data.pinRejection) {
            return errorObject.message;
            //   return errorObject.data.pinRejection[0].reason;
        }
        if (errorObject.data.length) {
            if (typeof errorObject.data[0] === "string")
                return errorObject.data[0];
            if (errorObject.data[0].length) return errorObject.data[0][0];
            return Object.keys(errorObject.data[0]).map(
                (key) => errorObject.data[0][key]
            )[0];
        }
        if (typeof errorObject === "object") {
            const deepErrorRes = Object.keys(errorObject.data).map(
                (key) => errorObject.data[key]
            )[0];
            console.log(Object.keys(errorObject.data));
            if (typeof deepErrorRes === "string") return deepErrorRes;
            if (deepErrorRes.length) return deepErrorRes[0];
            return Object.keys(deepErrorRes).map((key) => deepErrorRes[key])[0];
        }
    }
    return result;
};

const onModalClose = () => {
    if (needRedirectOnCloseModal.value) {
        Inertia.visit(route("rgb.missionLanding"));
    }
};

const handleClickBack = () => {
    Inertia.visit(route("realLeaf.landing"));
};

const handleClickOk = () => {
    statusReward.value = false;
};
</script>

<template>
    <StandardLayout
        :isLoading="loading"
        :showBack="true"
        @clickBack="handleClickBack"
    >
        <template #topBar>
            <Icons
                class="ml-auto mt-auto mb-auto mr-[20px] rounded-full w-[25px] h-[25px] bg-white flex justify-center items-center"
                icon="question-mark-small"
                @click="showGuideModal = true"
            />
        </template>
        <div class="min-h-screen flex flex-col">
            <h1 class="font-tccc-head text-2xl font-bold px-4">
                {{ $t("scan.collect_caps") }}
            </h1>
            <div class="flex mt-10">
                <Tabs @change="changeTab">
                    <template #tab1-title>{{
                        $t("scan.scan_qr_code")
                    }}</template>
                    <template #tab2-title>{{ $t("scan.scan_tab2") }}</template>
                    <template #tab1-content>
                        <div
                            class="flex w-full h-full items-center justify-center"
                        >
                            <Button
                                class="mt-20 w-1/2"
                                v-if="isScanning == false"
                                @click="doScan"
                                >{{ $t("scan.scan_qr_code") }}</Button
                            >
                            <div class="scan-block" v-if="isScanning">
                                <div class="w-full" id="qr-reader"></div>
                                <div class="scan-overlay">
                                    <div class="scan-mask">
                                        <span class="mask-content"></span>
                                        <span class="top-left"></span>
                                        <span class="top-right"></span>
                                        <span class="bottom-left"></span>
                                        <span class="bottom-right"></span>
                                    </div>
                                    <div
                                        class="scan-info flex flex-col items-center justify-center mt-12"
                                    >
                                        <!-- <span class="text-center">
                                            {{
                                                $t("scan.total_cap_today", [
                                                    totalCap,
                                                ])
                                            }}<br />
                                            {{
                                                $t("scan.remaining_cap_today", [
                                                    remainingCap,
                                                ])
                                            }}
                                        </span> -->
                                        <span
                                            class="note"
                                            v-html="
                                                t('scan.use_pincode_instead')
                                            "
                                        >
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #tab2-content>
                        <div class="mx-4 my-20">
                            <Input
                                v-for="(input, index) in inputs"
                                :key="input"
                                class="mt-5 input-form"
                                :label="t('scan.pin_code')"
                                :placeholder="t('scan.enter_the_pin_code')"
                                v-model="input.val"
                                @input="input.error = false"
                                :isError="input.error"
                                :errorMsg="input.message"
                            >
                                <template #btnValidator>
                                    <Icons
                                        v-if="index > 0"
                                        class="icon-right"
                                        icon="minus"
                                        @click="inputs.splice(index, 1)"
                                    />
                                </template>
                            </Input>
                            <div class="mt-12">
                                <Button
                                    class="mt-5 w-full"
                                    @click="doInputCode"
                                    >{{ $t("scan.submit") }}</Button
                                >
                            </div>
                        </div>
                        <div
                            class="fixed bottom-0 left-0 w-full flex px-4 pb-10"
                        >
                            <!-- <span v-if="remainingCap > 0">
                                {{ $t("scan.total_cap_today", [totalCap])
                                }}<br />
                                {{
                                    $t("scan.remaining_cap_today", [
                                        remainingCap,
                                    ])
                                }}
                            </span>
                            <span v-if="remainingCap === 0">
                                {{ $t("scan.limited_cap_today") }}
                            </span> -->
                        </div>
                    </template>
                </Tabs>
            </div>
            <Modal
                type="dark"
                :show="showGuideModal"
                @close="showGuideModal = false"
            >
                <div
                    class="flex flex-col w-full justify-center align-center text-left"
                >
                    <h2
                        class="font-tccc-head text-2xl font-bold text-black flex items-center gap-2 mb-2"
                    >
                        <Icons class="icon" icon="question-mark" />
                        <span>{{ $t("scan.how_to_collect_caps") }}</span>
                    </h2>
                    <p>
                        {{ $t("scan.scan_note") }}
                        <a class="underline font-bold" :href="route('faq')">{{
                            $t("scan.scan_faq")
                        }}</a>
                    </p>
                    <div class="guide pt-2 mt-2 w-full flex flex-col">
                        <div class="guide-item flex items-center gap-[10px]">
                            <img src="/images/scan-guide-qr.jpg" />
                            <div>
                                <div class="font-bold text-[16px]">
                                    {{ $t("scan.scan_the_qr_code") }}
                                </div>
                                <div class="text-[14px]">
                                    {{
                                        $t(
                                            "scan.scan_the_qr_code_under_bottle_cap"
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="guide-item flex items-center mt-[25px] gap-[10px]"
                        >
                            <img src="/images/scan-guide-pin.jpg" />
                            <div>
                                <div class="font-bold text-[16px]">
                                    {{ $t("scan.input_pin_code") }}
                                </div>
                                <div class="text-[14px]">
                                    {{
                                        $t(
                                            "scan.input_pin_code_under_bottle_cap"
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                :icon="modalIcon"
                type="dark"
                :show="showModal"
                @close="
                    showModal = false;
                    onModalClose();
                "
            >
                {{ modalMessage }}
            </Modal>
            <ModalReward
                :status="statusReward"
                :newEntry="newEntry"
                @clickOk="handleClickOk"
            ></ModalReward>
        </div>
    </StandardLayout>
</template>
<style lang="scss" scoped>
.input-form {
    position: relative;

    .icon-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0.25rem;
        display: block;
    }
}

.scan-block {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 75vh;
}

.scan-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .scan-mask {
        width: 50%;
        min-width: 260px;
        height: auto;
        position: relative;
        left: 50%;
        margin-top: 20%;
        transform: translateX(-50%);
    }

    .mask-content {
        width: 100%;
        height: 0;
        display: block;
        padding-bottom: 110%;
        position: relative;
        animation: scale 2s linear infinite both;
        color: #ffffff;

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 46px;
            height: 1px;
            background: currentColor;
            transform: translate(-50%, -50%);
        }

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 1px;
            height: 46px;
            background: currentColor;
            transform: translate(-50%, -50%);
        }
    }

    .top-left {
        position: absolute;
        left: 0;
        top: 0;
        width: 60px;
        height: 60px;
        border-left: 6px solid #ffffff;
        border-top: 6px solid #ffffff;
    }

    .top-right {
        position: absolute;
        right: 0;
        top: 0;
        width: 60px;
        height: 60px;
        border-right: 6px solid #ffffff;
        border-top: 6px solid #ffffff;
    }

    .bottom-left {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 60px;
        height: 60px;
        border-left: 6px solid #ffffff;
        border-bottom: 6px solid #ffffff;
    }

    .bottom-right {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 60px;
        height: 60px;
        border-right: 6px solid #ffffff;
        border-bottom: 6px solid #ffffff;
    }

    .note {
        color: #8a8a8a;
        font-size: 13px;
        text-shadow: 0 0 30px #fff;
    }
}

.icon {
    width: 65px;
    height: 65px;
    flex: 0 0 65px;
    border-radius: 100%;
    background: #000000;
    align-items: center;
    display: flex;
    justify-content: center;
}

.guide {
    border-top: 1px solid rgba(#000000, 0.08);
}

.guide-item {
    img {
        max-width: 100px;
    }
}

@keyframes scale {
    0% {
        transform: scale(0.9);
        color: #ffffff;
    }

    50% {
        transform: scale(1.1);
        color: #ffeec6;
    }
}
</style>

<script setup>
import { Head, Link } from '@inertiajs/inertia-vue3'
import StandardLayout from '@/Layouts/StandardLayout.vue'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
const scrollContainer = ref(null)

const handleScroll = (el) => {
  const scrollEl = scrollContainer.value.querySelector('.js--scroll-el')
  const srollCheck = () => {
    if (scrollEl.scrollTop + scrollEl.clientHeight >= scrollEl.scrollHeight - 4) {
      el.classList.add('at-bottom')
    } else {
      el.classList.remove('at-bottom')
    }
  }

  srollCheck()

  scrollEl && scrollEl.addEventListener('scroll', () => {
    srollCheck()
  })
}

onMounted(() => {
  handleScroll(scrollContainer.value)
})
</script>

<style scoped lang="scss">
::v-deep {
  .ocp-page {
    overflow: hidden;
  }

  p,
  ul,
  ol {
    margin-bottom: 1rem;
    font-family: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  ul li {
    list-style-type: disc;
  }

  ol {
    >li {
      list-style-type: lower-alpha;

      >ol {
        li {
          list-style-type: lower-roman;
        }
      }
    }
  }

  a {
    word-break: break-all;
    text-decoration: underline;
  }
}


.container {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 18rem;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.5) 57%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    opacity: 1;
    transition: all .3s;
  }

  &.at-bottom::after {
    opacity: 0;
  }
}
</style>

<template>

  <Head title="Terms & Conditions" />


  <StandardLayout class="h-screen overflow-hidden" showBack>
    <h1 class="font-tccc-head text-2xl font-bold px-4 my-4">{{ t('my_profile.coke_plus_platform_terms_of_use') }}</h1>
    <div class="p-4 pb-8 flex-1 overflow-auto">
      <div class="container font-sfpd text-lg text-primary-02 bg-level-01 rounded-xl py-6 pl-6 pr-2 w-full max-w-3xl mx-auto
      h-full" ref="scrollContainer">
        <div class="h-full overflow-auto pr-4 scroll-el js--scroll-el">
          <p style="text-align: center;"><strong>Coke+</strong><strong>平台使用條款<br /></strong>生效日期：
            2022年10月5日<br />[本條款最近更新日期為2022年10月5日]</p>
          <p>歡迎使用Coke+平台使用條款（下稱「<strong>條款</strong>」）。</p>
          <p>本條款構成香港商可口可樂有限公司台灣分公司（下稱「<strong>CCCL</strong>」或「<strong>我們</strong>」）與&nbsp; 台端之間的法律協議，敬請&nbsp; 台端仔細閱讀。
          </p>
          <p><strong>1. 簡介</strong></p>
          <p>本條款規定適用於&nbsp;
            台端進入及使用Coke+平台的條款，台端可透過指定網站（<em>cokeplus.tw</em>）（下稱「<strong>網站</strong>」）及行動應用程式（下稱「<strong>應用程式</strong>」）獲得。應用程式及網站各自稱為「<strong>平台</strong>」，合稱「<strong>各平台</strong>」。各平台歸我們所有並由我們運作。
          </p>
          <p>台端將能夠透過各平台使用我們提供的某些線上及線下功能或服務，例如Coke+線上商店（下稱「<strong>服務</strong>」）。</p>
          <p><strong>無論</strong><strong>&nbsp;
            </strong><strong>台端是否</strong><strong>Coke+</strong><strong>會員（下稱「會員」），本條款適用於</strong><strong>&nbsp;
            </strong><strong>台端對各平台（及我們透過各平台提供之任何服務）的使用。</strong></p>
          <p>敬請&nbsp; 台端仔細閱讀本條款。在使用各平台（及任何服務）之前，台端須同意本條款。倘若&nbsp; 台端不同意本條款，請勿使用任何平台（及任何透過平台提供之服務）。</p>
          <p>另請查看我們的隱私政策（下稱「<strong>隱私政策</strong>」），其中詳細說明了我們如何處理從用戶處收集之個人資料，並且倘若&nbsp;
            台端選擇成為會員，請查看Coke+的條款（下稱「<strong>會員條款</strong>」），其中載明了&nbsp; 台端作為會員的權利及義務。</p>
          <p><strong>2. 本條款何時適用？</strong></p>
          <p>每次&nbsp; 台端使用或登入平台時，即代表&nbsp; 台端向我們作出陳述，表示&nbsp; 台端有資格使用平台並同意受本條款之約束。</p>
          <p>
            如欲使用各平台及服務，台端必須年滿13歲。台端倘未滿20歲（下稱「<strong>未成年人</strong>」），台端的父母或法定監護人須同意本條款，台端才能使用各平台。此外，本平台、其內容及服務僅供在臺灣及當地法律不禁止使用的地方使用。若因此有機會觸犯任何適用臺灣境外或任何司法管轄區的法規，我們無意向該等臺灣境外或任何司法管轄區的人士發行或提供本平台、其內容及服務。
          </p>
          <p>倘若&nbsp; 台端沒有資格使用各平台及服務，台端須立即停止使用各平台及服務。</p>
          <p><strong>父母及法定監護人</strong>：倘若欲使用或登入各平台的人士為未成年人，台端向我們作出陳述，表示&nbsp;
            台端為該未成年人的父母或法定監護人。台端接受本條款並授權該未成年人使用各平台（及透過各平台提供之任何服務）。</p>
          <p><strong>3. 本條款在何時適用？</strong></p>
          <p>本條款適用於任何使用或登入各平台（及任何透過各平台提供之服務）的人士，無論該人士是否為會員。</p>
          <p>倘若&nbsp;
            台端選擇成為會員，附加條款可能適用於服務的某些功能，例如會員條款（下稱「<strong>附加條款</strong>」）。所有附加條款均構成本條款的一部分（並藉此提述納入本條款）。獲展示附加條款時，台端須同意該等附加條款，才能使用其所適用之服務功能。本條款及附加條款同等適用，除非附加條款的任何部分與本條款不一致，而此時概以附加條款為準，但僅限於不一致的內容。
          </p>
          <p>倘若&nbsp; 台端選擇接收我們的簡訊，即表示&nbsp; 台端知悉我們使用自動撥號技術發送簡訊。敬請&nbsp; 台端注意，我們可能無法向所有電信服務商的用戶發送簡訊。</p>
          <p><strong>4. 我們會否變更服務？</strong></p>
          <p>
            我們不斷努力改善服務。我們可能會推出新特性及功能以增強服務（下稱「<strong>新功能</strong>」）。我們亦可能不時推出更新、糾正錯誤及作出其他更改，以改善各平台的功能及用戶體驗（下稱「<strong>更新</strong>」）。倘若新功能會收取費用，我們會讓&nbsp;
            台端選擇是否使用這些新功能。台端同意我們可以隨時加入新功能及進行更新，恕不另行通知。</p>
          <p>我們可能會在新功能正式向公眾開放之前向&nbsp; 台端提供試用新功能的機會。當新功能僅供&nbsp; 台端試用而其他人未能使用時，我們將新功能稱為「<strong>試用服務</strong>」。作為&nbsp;
            台端享用試用服務的條件（無論是否作為會員），台端同意按照我們的合理要求提供有關試用服務的反饋。在試用服務被視為服務的一部分或我們決定不將試用服務納入服務之前，我們保留修改試用服務的權利，恕不另行通知。</p>
          <p>儘管本條款的其他規定載有關於我們對&nbsp; 台端的責任，試用服務是以「現有狀況」提供的，不帶任何形式的保證，對&nbsp;
            台端選擇使用試用服務引起的任何事宜，我們概不負責。倘若根據適用法律無法執行試用服務相關的任何免責條款，我們就試用服務須負的責任僅限於&nbsp; 台端為試用服務所支付之金額。</p>
          <p>本第4部分中的任何內容均不限制我們限制服務某些部分的使用權或不再提供任何服務或我們透過各平台所提供之任何內容的權利，恕不另行通知或對&nbsp; 台端承擔任何責任。</p>
          <p>我們將竭盡全力確保各平台時刻正常運作，但倘若出於任何原因各平台變得不可用，我們概不負責。</p>
          <p><strong>5. 我們是否會變更本條款？</strong></p>
          <p>本條款的生效日期載列於本網頁頂部。</p>
          <p>我們可能需要不時修改本條款，以反映任何更新、新功能或適用法律的變更。倘若&nbsp; 台端不同意經修改的條款，則須立即停止使用各平台。通過繼續使用各平台，即視為&nbsp; 台端已接受並同意經修改的條款。</p>
          <p>經修改的條款會取代我們之前關於本條款的約定、通知或聲明的所有版本。</p>
          <p><strong>6. 是否需要帳戶？</strong></p>
          <p>台端無需開設帳戶以瀏覽各平台的某些部分，但&nbsp;
            台端可能需要開設一個Coke+會員帳戶（下稱「<strong>帳戶</strong>」）並成為會員才能充分使用各平台提供之服務，例如透過Coke+線上商店購買商品（下稱「<strong>商品</strong>」）或參加推廣活動。作為會員，台端亦須遵守會員條款。
          </p>
          <p>註冊帳戶時，台端須提供我們要求的個人資料，例如&nbsp; 台端的行動電話號碼、電郵地址及出生日期，以驗證&nbsp; 台端能否在無&nbsp; 台端父母或法定監護人同意下簽訂本條款。詳情請參閱會員條款。</p>
          <p>如欲了解更多關於我們如何處理在&nbsp; 台端開設帳戶或使用各平台時向&nbsp;
            台端收集的個人資料，請參閱隱私政策&lt;https://cokeplus.tw/privacy-policy&gt;頁面了解詳情。</p>
          <p>
            根據《可口可樂的責任推銷政策》&lt;https://www.coca-colacompany.com/policies-and-practices/responsible-marketing-policy&gt;，我們不會直接向未滿13歲兒童進行推銷。因此，各平台（及透過各平台提供之服務）不會亦無意供未滿13歲的兒童使用。倘若&nbsp;
            台端得悉或懷疑未滿13歲的兒童正在使用我們的平台或已經開設（或試圖開設）帳戶，敬請&nbsp; 台端通過<a href="https://www.coca-colacompany.com/contact-us"
              target="_blank" rel="noopener">https://www.coca-colacompany.com/contact-us</a>通知我們。</p>
          <p><strong>7. 服務是否收費？</strong></p>
          <p>一般而言，我們不會就使用各平台（或透過各平台提供之任何服務）收取費用。倘若各平台的某些功能需要收費，CCCL會讓&nbsp; 台端選擇是否使用這些須收費的功能。</p>
          <p>台端可從Coke+線上商店購買CCCL的商品。該等購買將受本條款第11部分的約束&mdash;&mdash;敬請&nbsp; 台端仔細閱讀。</p>
          <p>台端全權承擔與使用各平台有關的所有收費、費用及其他成本，包括登入及使用各平台所需的裝置（如行動裝置或電腦）和網路連接或行動電話服務。倘若&nbsp;
            台端在行動裝置上登入及使用各平台，台端同意全權承擔電信服務商收取的所有費用。</p>
          <p><strong>8. 誰享有各平台及服務內容的擁有權？</strong></p>
          <p>在CCCL和&nbsp;
            台端之間，CCCL是各平台的經營商及服務供應商。CCCL及其第三方授權者為（且將繼續作為）透過各平台提供之所有內容及其設計、選擇和編排以及其中所有智慧財產權（下稱「<strong>服務內容</strong>」）的全部權利、所有權及權益的唯一並專有擁有人。意指CCCL擁有並經營各平台，以及擁有服務內容或享有服務內容的許可，而&nbsp;
            台端則擁有用戶內容（如適用），具體描述如下。</p>
          <p>
            服務內容包括可口可樂公司及其關係企業的名稱以及所有相關名稱、標識、產品及服務名稱、設計和標語（下稱「<strong>可口可樂標記</strong>」）。未經CCCL事先書面許可，台端不得使用可口可樂標記。所有出現在各平台上或各平台中的其他第三方名稱、標識、產品及服務名稱、設計和標語均為其各自擁有人的商標。
          </p>
          <p>除非本條款明確規定，否則&nbsp; 台端對任何服務內容均未獲得任何權利、許可或授權。服務內容受國際著作權、商標、專利、營業秘密及其他智慧財產權或專有權法律的保護。為清楚起見，台端同意不會（且不會允許任何第三方）：
          </p>
          <ul>
            <li>刪除各平台中所載列任何著作權、商標或其他專有權的聲明；</li>
            <li>未經CCCL事先明確書面同意，建立及/或出版包含部分或全部平台及/或服務但屬於 台端自己的資料庫；</li>
            <li>除非本條款明確授權，複製、散布、修改、創作衍生作品、公開展示、公開演出、公開播送、公開傳輸、重新出版、下載、儲存或傳輸任何服務內容；</li>
            <li>使用任何機器人、蜘蛛、搜索/檢索應用程式或其他自動化設備、流程或手段以存取、檢索、抓取或索引平台的任何部分；及</li>
            <li>在任何時候以其他方式違反或侵犯CCCL或其第三方授權者對服務內容的專有權。</li>
          </ul>
          <p><u>用戶內容為</u><u>&nbsp; </u><u>台端所擁有，但授權我們使用用戶內容</u></p>
          <p>各平台可能提供允許&nbsp; 台端及其他用戶發表、提交、發佈、顯示或發放內容或材料（統稱為「<strong>用戶內容</strong>」）的論壇及其他互動功能。除非適用於&nbsp;
            台端提交用戶內容之條款另有規定，台端在平台上創作的用戶內容為&nbsp; 台端所擁有。</p>
          <p>台端理解並知悉&nbsp; 台端自行對用戶內容負責，包括其合法性、可靠性、準確性及適當性。</p>
          <p>無論&nbsp;
            台端透過各平台或可口可樂公司或其關係企業（下稱「<strong>集團</strong>」）的社交媒體頁面提交用戶內容，台端均授予集團（包括協助我們經營各平台的任何服務供應商及我們各自的繼受人和受讓人）不受限制、永久、全球性、非專屬、免授權費、已全額支付的權利及許可（及多層再轉授權的權利），以出於任何目的且在法律允許的範圍內，在當前已知或未來將會開發的媒介中使用、託管、儲存、複製、修改、公開展示、表演、翻譯、散布及以其他方式向第三方披露&nbsp;
            台端全部或部分用戶內容。台端在本條款中就用戶內容向CCCL授予之權利及許可不會轉移任何人格權。</p>
          <p>台端通過提供用戶內容，即代表&nbsp; 台端向CCCL作出以下陳述並保證：</p>
          <ul>
            <li>用戶內容的所有權利為 台端所擁有或控制，台端亦有權授予在條款中向CCCL所授予的權利及許可；</li>
            <li>台端的用戶內容並沒侵犯任何其他個人或實體的權利，例如隱私權、公開權及智慧財產權；</li>
            <li>台端的用戶內容屬真實準確；及</li>
            <li>台端的用戶內容符合本條款及所有適用法律、規則和法規。</li>
          </ul>
          <p>台端的用戶內容不得：</p>
          <ul>
            <li>包含任何誹謗、淫穢、不雅、辱駡、冒犯、騷擾、暴力、仇恨、煽動或其他令人反感的材料；</li>
            <li>宣傳色情材料、暴力或基於種族、宗教、國籍、殘疾、性別、性取向、性別認同或年齡的歧視；</li>
            <li>欺騙或意圖欺騙任何人士；</li>
            <li>宣傳任何非法活動或提倡、宣傳或協助任何非法行為；及</li>
            <li>就 台端身份或與任何個人或組織的從屬關係作出失實陳述，或給人&nbsp; 台端用戶內容得到集團（包括CCCL）或任何其他個人或實體認可的印象（若情況並非如此）。</li>
          </ul>
          <p>倘若&nbsp; 台端認為某些用戶內容違反了本條款，敬請&nbsp; 台端通過<a href="https://www.coca-colacompany.com/contact-us"
              target="_blank"
              rel="noopener">https://www.coca-colacompany.com/contact-us</a>通知我們，包括描述特定用戶內容及其在各平台或集團社交媒體的位置。在法律允許的範圍內，台端同意就第三方所有因&nbsp;
            台端用戶內容或與&nbsp; 台端用戶內容相關而對本集團提出之請求向本集團作出賠償。</p>
          <p>除用戶內容外，台端可以不時選擇向CCCL提出有關服務或各平台的想法、建議或其他回饋。回饋內容為&nbsp; 台端所擁有，但通過向CCCL提出回饋，即表示&nbsp;
            台端同意並特此授予CCCL已全額支付、免授權費、永久、不可撤銷、全球性、非專屬及完全可再轉授權的權利及許可，以使用、複製、執行、展示、散布、改編、修改、重新製作格式和創作衍生作品，並以任何方式利用&nbsp;
            台端的所有回饋。台端亦知悉並同意，只要無法直接辨識&nbsp; 台端身份（除非&nbsp; 台端事先書面同意），CCCL可以使用&nbsp; 台端的回饋來改善服務或各平台。台端的所有回饋均被視為非保密及非專有。
          </p>
          <p><strong>9. 什麼是獲准使用服務？</strong></p>
          <p>在&nbsp; 台端遵守本條款的前提下，CCCL授予&nbsp; 台端（僅供非商業用途）個人的、非專屬、不可轉讓、可撤銷及有限的權利，以登入及使用各平台，並下載一個（1）應用程式。</p>
          <p>台端僅可出於合法、個人及非商業目的使用各平台（及其中提供之服務），不得用於任何欺詐或不正當目的與任何非法活動。在不限制上述規定的情況下，台端不得且同意不會，亦不會試圖或鼓勵或允許任何第三方：</p>
          <ul>
            <li>透過駭客攻擊、密碼挖掘或其他方式未經授權登入（或試圖登入）各平台（或帳戶，如適用）或CCCL的電腦系統或網絡，或侵犯任何電腦或安全網絡的安全性；</li>
            <li>以 台端知道或應當知道任何可能會損壞或減損CCCL伺服器或網路或使CCCL伺服器或網路無法使用或超載的方式使用各平台（或帳戶，如適用）；</li>
            <li>歧視、騷擾、威脅、欺騙、令人窘迫、傷害或給他人造成煩惱、不便或焦慮，或以其他方式干擾（或試圖干擾）任何其他方使用及享受各平台和服務；</li>
            <li>代表 台端以外的任何人使用各平台（或帳戶，如適用）；</li>
            <li>複製、修改、改編、翻譯、逆向工程、解碼或以其他方式嘗試獲取或進入平台的任何部分；</li>
            <li>刪除任何各平台中載列的著作權、商標或其他專有權聲明或以其他方式侵犯或違反任何第三方的智慧財產權；</li>
            <li>使用任何機器人、蜘蛛、搜尋/檢索應用程式或其他自動化設備、流程或手段來存取、檢索、抓取或索引平台的任何部分；</li>
            <li>以任何理由出租、租賃、出借、出售、再授權、轉讓、散布、出版、轉移或以其他方式向任何第三方提供各平台或各平台的任何特性或功能；</li>
            <li>重新制定格式或構建屬於平台及/或服務一部分之網頁中的任何部分，包括意圖轉售或散布產品；或</li>
            <li>使用各平台（或帳戶，如適用）發送任何未經授權或非應邀的商業或推廣內容。</li>
          </ul>
          <p>CCCL保留在適用法律允許的最大範圍內並根據隱私政策監控並記錄在各平台所進行之活動的權利。CCCL保留隨時以任何理由刪除、禁止使用或編輯用戶內容的權利，恕不另行通知。</p>
          <p>倘若CCCL有合理理由相信&nbsp; 台端在使用各平台時違反了本條款，除了任何其他可用的救濟以外，CCCL亦有裁量權終止&nbsp; 台端使用各平台，恕不另行通知。</p>
          <p><strong>10. 本條款是否專門適用於使用應用程式？</strong></p>
          <p>除第10部分明確規定外，本條款適用於&nbsp; 台端下載及使用應用程式以使用服務。</p>
          <p>與網站不同，行動應用程式是下載並安裝在&nbsp; 台端行動裝置上的軟體。即使已下載到&nbsp; 台端的行動裝置，台端僅獲得許可使用該應用程式&mdash;&mdash;並非將其出售予&nbsp;
            台端。除根據本條款使用應用程式之外，台端並未獲得應用程式的任何所有權權益或任何其他權利。</p>
          <p>台端可以將應用程式下載到&nbsp; 台端擁有或以其他方式控制的行動裝置上，僅用於&nbsp; 台端自己專屬及非商業的用途。為向&nbsp; 台端提供某些功能，應用程式必須存取&nbsp;
            台端行動裝置上的各種功能及資料。如欲了解更多應用程式如何處理個人資料，敬請&nbsp; 台端查看隱私政策（或下載應用程式時提供給&nbsp; 台端的其他隱私政策或通知）。</p>
          <p>應用程式與網站可能具有不同的內容。</p>
          <p>我們可能會不時開發並提供應用程式更新（如第4部分中所定義）。根據&nbsp; 台端行動裝置的設定，當&nbsp; 台端行動裝置連接到網路時，應用程式將自動下載並在&nbsp;
            台端行動裝置上安裝所有可用更新，或者&nbsp; 台端可能會收到通知或被提示下載並安裝可用更新到&nbsp; 台端的行動裝置。台端同意下載並安裝所有更新，並知悉且同意如&nbsp;
            台端未能下載並安裝更新，應用程式可能無法正常運作。應用程式的所有更新均被視為應用程式及服務的一部分，因此受本條款的約束。</p>
          <p>除在本條款中明確授予&nbsp; 台端的權利外，CCCL保留並保有應用程式的全部權利、所有權及權益，包括所有可口可樂標記（如第8部分中所定義）及（在CCCL與&nbsp;
            台端之間）其中或相關的所有其他著作權、商標及其他智慧財產權。</p>
          <p>當&nbsp; 台端從Apple的App Store或Google Play（各自稱為「<strong>應用平台</strong>」）下載應用程式時，台端知悉並同意：</p>
          <ul>
            <li>在CCCL與應用平台之間，CCCL對應用程式承擔全部責任。</li>
            <li>應用平台沒有義務就應用程式提供任何維護及支援服務。</li>
            <li>
              倘若我們的應用程式不符合任何相關之保證：（i）台端可以通知應用平台，應用平台可以退還應用程式的購買價格（如適用），（ii）在適用法律允許的最大範圍內，應用平台不會就應用程式承擔任何其他保證義務，及（iii）因任何未能遵守任何保證而導致的任何請求、損失、責任、損害、成本或費用應由CCCL承擔（在CCCL和應用平台之間）。
            </li>
            <li>應用平台不負責處理 台端可能就應用程式或&nbsp; 台端擁有及使用應用程式而提起的任何請求。</li>
            <li>倘若第三方聲稱本應用程式侵犯了他人的智慧財產權，CCCL負責對該等智慧財產權侵權請求之調查、防禦、和解及撤銷（在CCCL和應用平台之間）。</li>
            <li>應用平台及其關係企業是因 台端使用應用程式而適用的使用條款之第三方受益人。&nbsp; 台端一經接受本條款，應用平台將有權（並將被視為已接受該權利）作為第三方受益人就&nbsp;
              台端的應用程式許可，向&nbsp; 台端執行本條款。</li>
            <li>台端在使用應用程式時亦須遵守透過應用平台適用的所有第三方條款。</li>
          </ul>
          <p>如欲了解更多有關應用程式收集的特定資料，敬請查看&nbsp; 台端行動裝置的設定或查閱&nbsp; 台端下載應用程式所載的應用平台上的揭露資訊。如欲停止應用程式收集資料，敬請&nbsp; 台端解除安裝該應用程式。
          </p>
          <p><strong>11. 透過</strong><strong>Coke+</strong><strong>線上商店購買商品</strong></p>
          <p><strong>商品供應</strong></p>
          <ol>
            <li>作為會員，台端可以透過我們的Coke+線上商店購買可口可樂商品。</li>
            <li>
              Coke+線上商店由CCCL經營，但所有透過Coke+線上商店出售的商品均由英屬維京群島商太古食品(股)公司台灣分公司（下稱「<strong>台灣太古可口可樂</strong>」）獨家供應。因此，台灣太古可口可樂全權負責：
              <ol>
                <li>台端透過我們的Coke+線上商店下達之任何訂單的可用性、供應、交付、退貨、退款及履行；</li>
                <li>該等商品品質、適用性或描述的準確性；及</li>
                <li>與 台端購買或使用商品相關及引致的所有義務和責任。</li>
              </ol>
            </li>
            <li>台灣太古可口可樂保留絕對裁量權以任何理由決定拒絕或取消訂單的權利，例如：（i）商品不足以完成訂單；（ii）無法為
              台端所在地區安排送貨；（iii）台端訂購的一種或多種商品因失誤而以錯誤價格上架；（iv）台灣太古可口可樂因任何原因未收到款項；或（v）台灣太古可口可樂認為有必要拒絕或取消訂單的其他情况。</li>
            <li>CCCL並未就商品將具有可銷售性或適合某用途作出陳述或保證，且對任何商品的適用性、可銷售性或品質概不負責。</li>
            <li>任何與商品有關的查詢或爭議將交由台灣太古可口可樂解決，並由台灣太古可口可樂與 台端予以解決。</li>
            <li>台灣太古可口可樂就有關商品的任何事宜及爭議作出的任何決定或釐定均為終局。</li>
          </ol>
          <p><strong>所有權</strong></p>
          <ol>
            <li>台端同意，在台灣太古可口可樂收到全額款項及其他適用費用（例如運費）之前，商品的所有權仍歸台灣太古可口可樂所有。</li>
          </ol>
          <p><strong>交付</strong></p>
          <ol>
            <li>台灣太古可口可樂負責向 台端交付商品。在任何情況下，CCCL均不對商品的交付或延遲交付或未能交付承擔任何責任。</li>
            <li>台灣太古可口可樂交付商品須受由其不時施加的相關條款的約束。敬請 台端仔細閱讀該等條款。</li>
            <li>在任何情況下，台端應自行承擔在交付過程中及自商品交付到指定交付地點後發生的貨物遺失或損壞的風險。</li>
          </ol>
          <p><strong>12. 本條款何時終止？</strong></p>
          <p>本條款在&nbsp; 台端或CCCL終止之前具有效力。</p>
          <p>台端可隨時以任何理由停止使用各平台（包括服務）。此後，台端應該刪除該應用程式。</p>
          <p>倘若我們發現或懷疑&nbsp; 台端有違反安全的行為，或者若CCCL不再提供或支援任何服務，CCCL有絕對裁量權，可以隨時暫停&nbsp; 台端使用服務或各平台、取消&nbsp;
            台端的帳戶（如適用）或終止本條款，恕不另行通知。</p>
          <p>我們可以但沒有義務在終止&nbsp; 台端使用服務或各平台之前提前通知&nbsp; 台端，以便&nbsp; 台端能夠取回任何重要的用戶資料（在法律及本條款允許的範圍內），但倘若我們認為提前通知&nbsp;
            台端並不可行、非法、不符合某人的安全或保密利益或會以其他方式損害CCCL的權利或財產，則我們將不會提前通知&nbsp; 台端。</p>
          <p>當本條款終止時，CCCL授予的權利亦將終止，台端須停止使用服務及各平台，並從&nbsp; 台端的行動裝置中刪除應用程式。</p>
          <p>
            本條款中任何就其性質而言應在本條款終止後繼續有效的規定應在本條款終止後繼續有效。例如，以下條款將在終止後繼續有效：我們的任何責任限制、有關所有權或智慧財產權的條款、台端向我們支付任何尚未結清款項的義務及有關我們之間爭議的條款。本條款的終止不會且將來亦不會限制CCCL或&nbsp;
            台端在法律或在衡平法上的任何權利及補救。</p>
          <p><strong>13. 誰將為連結服務承擔責任？</strong></p>
          <p>各平台可能包含第三方（包括我們的推銷代理合作伙伴及各個社交媒體平台）提供之其他網站和資源以及廣告（統稱為「<strong>連結服務</strong>」）的連結。即使某些連結服務允許&nbsp;
            台端向其匯出&nbsp; 台端資料，例如社交媒體上的「讚」或「分享」功能，CCCL無法且不會控制連結服務。敬請&nbsp; 台端仔細閱讀任何連結服務的隱私政策和其他使用條款。倘若&nbsp;
            台端決定查閱或使用任何連結服務，台端將完全自行承擔風險，且&nbsp; 台端亦同意受適用於連結服務的條款及細則的約束。</p>
          <p>
            各平台可能包含由第三方開發商根據開源或類似許可、通常免費提供的第三方軟體（下稱「<strong>第三方</strong><strong>軟體</strong>」）。儘管使用各平台受本條款的約束，但作為各平台一部分的任何第三方軟體可能受其他許可或使用條款的約束，該等許可或使用條款將在適用時向&nbsp;
            台端提供。</p>
          <p><strong>14. 本條款如何限制我們的責任？</strong></p>
          <p><u>我們的免責聲明</u></p>
          <p>在適用法律允許的情況下，各平台（包括其中的服務）以「現有狀況」及「既有狀況」的基礎提供，不提供任何形式的保證。</p>
          <p>
            在適用法律允許的最大範圍內，CCCL代表其自身及集團和各自授權者及供應商（統稱為<strong><em>可口可樂各方</em></strong>）明確否認所有明示、默示、法定或其他形式的平台相關保證，包括所有對可銷售性、對某特定用途的適用性、所有權和非侵權的默示保證，及可能在交易表現、使用或貿易慣例而產生的保證。
          </p>
          <p>在不局限上述規定的原則下，可口可樂各方均未就各平台能夠滿足&nbsp;
            台端要求、實現任何預期結果、與任何其他軟體、系統或服務相容或能一同使用、運作無中斷、符合任何性能或可靠性標準或無錯誤、或任何錯誤或缺陷將得以糾正提供任何保證或承諾，或作出任何聲明。</p>
          <p><u>我們的責任限制</u></p>
          <p>在本條款的規限下，CCCL對因CCCL嚴重違反本條款而造成的合理、直接及可預見的損害承擔責任。</p>
          <p>在適用法律允許的最大範圍內，CCCL不會且未來亦不會對以下情況承擔責任：</p>
          <ul>
            <li>不可預見的損害，包括相應而生的損害；</li>
            <li>懲戒性或懲罰性損害；</li>
            <li>與 台端使用平台及服務有關的任何利潤損失、業務損失、契約損失、業務中斷、預期儲蓄損失、商譽損失或商業機會損失；</li>
            <li>因進入各平台而感染之病毒或其他惡意軟體或各平台中的錯誤、故障、延遲或中斷；及</li>
            <li>任何第三方行為相關之損失，包括使用或無法使用任何連結服務。</li>
          </ul>
          <p>除適用法律明確要求外，對於因使用或無法使用各平台（包括服務）或因本條款引起或相關的所有請求，無論是合約、侵權或其他方面，可口可樂各方在任何情況下對&nbsp;
            台端需要承擔的全部責任總計不得超過新台幣四百元（TW$400.00元）。</p>
          <p>
            本條款中的任何內容均無意以任何非法方式排除或限制責任，包括因我們的疏忽或有欺詐成分的失實陳述或其他故意行為而致人傷亡的責任。如上述任何限制被認定無效，可口可樂各方對任何種類或性質的所有損害、損失或訴因的全部責任應限於適用法律允許的最大範圍內。
          </p>
          <p>作為消費者，台端將受益於臺灣法的任何強制性條文。本條款中的任何內容均不得影響&nbsp; 台端作為消費者依賴臺灣法下任何強制性條文的權利。</p>
          <p><strong>15. 我們如何與客戶溝通？</strong></p>
          <p>當&nbsp; 台端使用各平台或向我們發送電子郵件時，則&nbsp; 台端是通過電子方式與我們通訊。根據&nbsp; 台端的選擇及帳戶設定，我們將以多種電子方式與&nbsp;
            台端通訊，例如透過電子郵件、簡訊或應用程式推送通知，或在平台上發佈消息。其中一些通訊是自動發送的，而另一些則是作為服務的一部分預設發送的。我們可能會不時增加或刪除某些通訊類型。台端可以在帳戶設定中調整是否接收某些電子通訊。儘管&nbsp;
            台端可以停用某些通訊方式，但我們仍可能根據需要向&nbsp; 台端發送通知，以允許我們向&nbsp; 台端提供服務的某些部分，例如通知&nbsp; 台端有關安全事件的資訊。</p>
          <p>台端同意，除非強制性適用法律明確要求採用不同的通訊形式，我們以電子方式向&nbsp;
            台端提供的所有協議、通知、揭露及其他通訊均符合有關此類以書面形式進行的通訊之任何法律要求。台端的電信服務商或服務供應商者可能會就某些電子通訊向&nbsp; 台端收取費用。</p>
          <p>倘若&nbsp; 台端的電郵地址或其他聯絡資料有變更，台端有責任通知CCCL。對&nbsp; 台端電郵地址的更改將適用於我們向&nbsp; 台端作出的所有通訊。</p>
          <p>某些通訊可能包括有關&nbsp; 台端帳戶的資料，例如重設密碼的請求。任何有權登入&nbsp; 台端電子郵件帳戶或行動裝置的人士均可查看這些通訊的內容。</p>
          <p>當我們發送推銷或推廣資訊時，我們會根據&nbsp; 台端的意願進行。台端亦可以透過按一下每封推銷電子郵件底部中的取消訂閱連結來控制我們的電子郵件推銷通訊。詳情請參閱隱私政策。</p>
          <p><strong>16. 管轄法律、管轄權及爭議解決</strong></p>
          <p>CCCL希望能直接與&nbsp; 台端解決有關平台及服務的任何問題。倘若&nbsp; 台端因任何事宜需要聯絡我們，台端可以第19部分載列的任何方式與我們聯絡。</p>
          <p>台端同意按照第16部分之規定解決因&nbsp; 台端使用平台及服務而引起或與之相關的爭議、請求和訴因。</p>
          <p>本條款受臺灣法管轄並據此進行詮釋，台端同意接受臺灣法院的非專屬管轄權。</p>
          <p><strong>17. 第三者權利</strong></p>
          <p>除非本條款另有規定，本條款並未對第三人創造得向我們強制執行本協議或享有本協議任何條款之利益之權利。</p>
          <p><strong>18. 雜項</strong></p>
          <ol>
            <li>本條款（包括提供給 台端的隱私政策或其他隱私政策或通知，及會員條款（如&nbsp; 台端已註冊成為會員））載明了CCCL與&nbsp; 台端之間關於&nbsp;
              台端使用各平台及相關事宜的全部理解，並取代所有先前和同時期關於各平台的理解和協議（無論書面或口頭）。</li>
            <li>台端同意，台端使用鍵盤、滑鼠或其他裝置選擇按鈕或圖示或類似行為或以其他方式向CCCL提供任何確認或同意構成 台端的電子簽名，相當於&nbsp; 台端的書面簽名。台端亦同意無需第三方驗證即可核實&nbsp;
              台端的電子簽名。台端進一步同意，每次就各平台使用電子簽名均構成&nbsp; 台端同意受本條款的約束，如同本條款於&nbsp; 台端電子簽名之日已存在。</li>
            <li>本條款符合CCCL及 台端各自的繼受人和受讓人的利益，並對其具有約束力。</li>
            <li>CCCL可以將本條款轉讓給利益繼受人（全部或部分），但未經CCCL事先明確書面同意，台端不得轉讓本條款。</li>
            <li>倘若有司法管轄權的法院以任何理由斷定本條款的任何規定無效、非法或不可執行，則該規定應被刪除或予以最低限度限制，以使剩餘之規定繼續具有同等效力，如同該等不可執行或無效規定未被使用。</li>
            <li>
              CCCL未行使或延遲行使本條款項下的任何權利或救濟均不得視為放棄該權利或救濟，任何單獨或部分行使任何權利或救濟亦不妨礙任何進一步或以其他形式行使或行使任何其他權利或救濟。本條款中規定之權利及救濟是累積性的，並不排除法律規定的任何權利或救濟。
            </li>
            <li>本條款中的任何內容均不構成CCCL與 台端之間成立合夥、合資、代理或其他該等關係。</li>
            <li>如由於援引本條款的一方無法合理控制的任何原因導致CCCL或 台端不能履行或無法履行本條款項下的任何義務，受影響方的履約將被延長至由於該等原因導致的延遲或無法履約的期限。</li>
            <li>標題和說明僅為了方便而設。除非上下文另有規定或明確說明，否則使用的詞語或短語包括「包括」、「特別是」、「例如」或任何類似表達應被理解為說明性的，並不限制該等詞語、描述、定義、短語或其之前或之後的術語之含義。。
            </li>
          </ol>
          <p><strong>19. 如何聯絡可口可樂？</strong></p>
          <p>如&nbsp; 台端對本條款有任何疑問，敬請透過以下指定方式聯絡我們：</p>
          <ol>
            <li>台端可使用Facebook Messenger訊息功能向我們的Facebook頁面發送私人訊息；</li>
            <li>台端可寄信至下述地址與我們聯絡：<br />香港商可口可樂有限公司台灣分公司<br />105410 臺北市松山區敦化北路88號14樓之2<br />系統管理員收</li>
          </ol>
        </div>
      </div>
    </div>
  </StandardLayout>
</template>
<script setup>
import { ref, computed } from "vue";
import ButtonPrimary from "@/Components/ButtonPrimary.vue";
import { Head, Link } from "@inertiajs/inertia-vue3";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Button from "@/Components/Button.vue";

const { t } = useI18n();
const props = defineProps({
    checkWinner: {
        type: Boolean,
        default: false,
    },
    isLogin: {
        type: Boolean,
        default: false,
    },

});
const $store = useStore();
</script>

<template>
    <div class="instruction-container bg-level-02">
        <div class="instruction-inner">
            <div class="pl-4 pr-4 pt-4">
                <h3 class="mb-3">{{ $t("real_leaf.head") }}</h3>
                <p>{{ $t("real_leaf.desc") }}</p>
            </div>
            <div class="justify-center flex">
                <div class="instruction-item basis-8/12">
                    <p>
                        <span class="hl text-[14px] font-bold">{{
                            $t("real_leaf.1_entry_head")
                        }}</span>
                    </p>
                    <p
                        class="text-[12px]"
                        v-html="t('real_leaf.1_entry_desc')"
                    ></p>
                    <p class="mt-2">
                        <span class="hl text-[14px] font-bold">{{
                            $t("real_leaf.2_entry_head")
                        }}</span>
                    </p>
                    <p
                        class="text-[12px]"
                        v-html="t('real_leaf.2_entry_desc')"
                    ></p>
                </div>
                <div class="instruction-item basis-4/12">
                    <img src="/images/mission/rl-gold.png" alt="rl goldÏ" />
                </div>
            </div>
        </div>
    </div>
    <div
        class="cta-container flex flex-col justify-center items-center"
    >
        <Link
            class="mt-3 flex justify-between"
            v-if="!props.isLogin"
            :href="route('member.register', { mission: 'real-leaf' })"
        >
            <Button type="primary" class="cta px-9">{{
                t("real_leaf.login")
            }}</Button>
        </Link>
        <Link
            class="mt-3 flex justify-between"
            v-if="props.isLogin"
            :href="route('realLeaf.scan', { code : 'scan' })"
        >
            <Button type="primary" :disabled="props.checkWinner" class="cta px-9">{{
                t("real_leaf.scan")
            }}</Button>
        </Link>
    </div>
</template>

<style lang="scss" scoped>
.cta {
    color: #fff;
    background: #005745;
    font-size: 18px;
    height: 50px;
}
.instruction-container {
    margin: 20px 15px;
    color: #fff;
    border-radius: 16px;
    h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
}

.instruction-item {
    position: relative;
    padding: 12px 16px 20px;

    + .instruction-item {
        padding: 12px 10px 20px 0px;
    }
}

.item-lists {
    display: flex;
    padding: 12px 0;

    li {
        flex: 1 1 auto;
        position: relative;
        margin-right: 12px;
        padding-top: 50%;
        background: #2d2d2d;

        border: 1px solid #404252;
        border-radius: 16px;

        > * {
            width: 53.125%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:last-child {
            margin: 0;
        }
    }
}

.hl {
    background: linear-gradient(90.14deg, #ffb400 8.72%, #f40000 98.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    .instruction-item & {
        background: linear-gradient(90.14deg, #ffb400 8.72%, #ff795b 98.47%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
}

.limit-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #777986;
}

.cap-list {
    padding-top: 10px;

    li {
        padding: 8px 4px;
    }
}

.cap-item {
    img {
        border: 2px solid #ffffff;
        border-radius: 100%;
        display: block;
        box-shadow: 0px 0px 66px rgba(255, 255, 255, 0.25);
    }
}
</style>

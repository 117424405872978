<script setup>
import Icons from "@/Components/Icons.vue";
defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: ""
  },
  closeText: {
    type: String,
    default: "Ok"
  }
});
</script>

<template>
  <transition name="modal">
    <div class="coke-modal" v-show="show">
      <div class="coke-modal-inner">
        <div class="coke-modal-content flex items-center justify-between pt-7 pb-7 gap-4">
          <Icons v-if="icon" class="icon" :icon="icon" />
          <div class="content">
            <slot />
          </div>
        </div>
        <div class="flex justify-center">
          <button class="coke-btn-modal" type="btton" @click="$emit('close')">{{closeText}}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.coke-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.coke-modal-inner {
  min-width: 350px;
  max-width: calc(100% - 40px);
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.coke-modal-content {
    color: #000000;

    .icon {
        width: 65px;
        height: 65px;
        flex: 0 0 65px;
        border-radius: 100%;
        background: #000000;
    }

    .content {
        flex: 1 1 auto;
    }
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .coke-modal-inner,
.modal-leave-active .coke-modal-inner {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  /* opacity: .6; */
}

.coke-btn-modal {
  height: 40px;
  min-width: 150px;
  left: 80px;
  top: 79px;
  border-radius: 60px;
  padding: 12px, 30px, 12px, 30px;
  border: 2px solid #000;
  color: #000000;
}

.coke-btn-modal p {
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 700;
}
</style>

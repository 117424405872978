<script setup>
import { ref } from 'vue'
import Icons from '@/Components/Icons.vue'

const props = defineProps({})

const open = ref(false)
</script>

<template>
  <div class="w-full">
    <div
      class="flex justify-between items-center font-sfpd text-xl p-4 bg-level-01"
      @click="open = !open"
    >
      <slot name="trigger" />
      <Icons icon="arrow-down" class="grow-0 shrink-0 w-6"  />
    </div>

    <transition name="accordion">
      <div v-if="open" class="text-lg p-4 bg-level-02 overflow-hidden">
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">

.accordion-enter-active,
.accordion-leave-active {
  transition: 0.25s ease;
  max-height:100vh;
  padding: 16px 16px;
}

.accordion-enter,
.accordion-leave-to {
  max-height:0;
  padding: 0 16px;
}
</style>

<script setup>
import { Link } from '@inertiajs/inertia-vue3'
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
</script>

<style scoped lang="scss">
</style>



<template>
    <footer class="flex flex-wrap justify-center w-full mt-auto mb-0 pt-2 pb-4 font-tccc-head font-medium text-[#8A8A8A] text-[11px]">
        <Link class="mx-2 mt-2 underline text-center" :href="route('privacyPolicy')">{{ t('footer.privacy_policy') }}</Link>
        <Link class="mx-2 mt-2 underline text-center" :href="route('termsConditions')">{{ t('footer.terms_conditions') }}</Link>
        <Link class="mx-2 mt-2 underline text-center" :href="route('memberTermsConditions')">{{ t('footer.membership_terms_conditions') }}</Link>
    </footer>
</template>

import axios from "axios"
import {
    prepareAPIInfomation
} from '@tools/app';

const uploadReceipt = async (file, name) => {
    const {
        baseURL,
        token,
        uuid,
        error
    } = await prepareAPIInfomation()
    if (error) return error
    const form = new FormData();
    console.log(file, name, uuid);
    form.append("member_uuid", uuid);
    form.append("activity_user_agent", navigator.userAgent);
    form.append("is_mission", 0);
    form.append("receipt", file);
    const res = await axios({
        url: `${baseURL}/api/v1/receipt/upload`,
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-type": "multipart/form-data"
        },
        data: form
    }).then(result => {
        return result.data
    }).catch((error) => {
        const errorRes = error.response.data
        return errorRes
    })
    return res
}

export default uploadReceipt

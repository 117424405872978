<script setup>
import { ref, onMounted } from 'vue'

defineProps({
  type: {
      type: String,
      default: '',
  },
  clss: {
      type: String,
      default: '',
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

</script>

<template>
  <button :class="clss" :type="type" :disabled="disabled" @click="$emit('clickEvent')">
    <slot/>
  </button>
</template>

<style scoped>
  .btn-primary {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    gap: 10px;

    background: #FFFFFF;
    border-radius: 60px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }

  .red-bg {
    background-color: #F40000;
    color: #fff;
  }
  .green-bg {
    background-color: #005745;
    color: #fff;
  }
  .black-bg {
    background-color: #000;
    color: #fff;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.45);
    border-radius: 60px;
  }

  .btn-primary:disabled {
    background: #939393;
    color: rgba(255, 255, 255, 0.5);
  }
</style>
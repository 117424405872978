<script setup>
import { Head, Link } from '@inertiajs/inertia-vue3';
import { Inertia } from '@inertiajs/inertia'
import StandardLayout from '@/Layouts/StandardLayout.vue';
import { ref, computed, onMounted, reactive, onUnmounted } from 'vue';
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n';
import { msalClearAuthStatus } from '@tools/app';

const { t } = useI18n()
const $store = useStore()

const isLoading = ref(false)
const member = computed(() => {
  return $store.state?.auth?.member || {}
})
const fullName = computed(() => {
  return `${member.value?.given_name || ''} ${member.value?.family_name || ''}`
})
const birth = computed(() => {
  const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
  if (!member.value?.birthday) return
  const date = member.value.birthday.split(' ')[0]
  const dateArr = date.split('-')
  return `${t(months[dateArr[1] * 1 - 1])}, ${t(dateArr[0])}`
})

const nav = computed(() => [
  // {
  //   id: 1,
  //   name: t('how_coke_works'),
  //   onClick: () => {
  //     Inertia.visit(route('rgb.introduction'))
  //   }
  // },
  {
    id: 2,
    name: t('my_profile.privacy_policy'),
    onClick: () => {
      Inertia.visit(route('privacyPolicy'))
    }
  },
  {
    id: 3,
    name: t('my_profile.coke_plus_platform_terms_of_use'),
    onClick: () => {
      Inertia.visit(route('termsConditions'))
    }
  },
  {
    id: 4,
    name: t('my_profile.coke_plus_membership_terms'),
    onClick: () => {
      Inertia.visit(route('memberTermsConditions'))
    }
  },
  {
    id: 5,
    name: t('my_profile.faq'),
    onClick: () => {
      Inertia.visit(route('faq'))
    }
  },
])

const logout = async () => {
  try {
    isLoading.value = true
    msalClearAuthStatus()
    await $store.dispatch('logout', { msal: $store.state.app.msal, route })
  } catch (error) {
    console.log(error)
  } finally {
    isLoading.value = false
  }
}

const editProfile = () => {
  msalClearAuthStatus()
  isLoading.value = true
  $store.state.app.msal.loginRedirect({
    authority: $store.state.app.azure.policyAuthorities.editProfile.authority,
    extraQueryParameters: {
      ui_locales: 'zh-hant'
    },
  }).then(() => {
    isLoading.value = false
  })
}
const handleClickBack = () => {
    Inertia.visit(route("ocp.mainDashboard"));
};

onMounted(() => {
})
</script>
<style scoped>
</style>
<template>

  <Head title="Register" />
  <StandardLayout :showBack="true" :isLoading="isLoading" @clickBack="handleClickBack">
    <div class="p-4">
      <h1 class="font-tccc-head text-2xl font-bold">{{ t('my_account') }}</h1>
      <div class="flex mt-10">
        <picture class="block w-14 h-14 rounded-full">
          <img src="/images/profile-photo.png" alt="Profile Photo">
        </picture>

        <div class="flex pl-6 grow justify-between items-center">
          <span class="text-lg font-semibold capitalize dark:text-gray-300">
            {{ fullName }}
          </span>
          <div class="w-5" @click="editProfile">
            <svg
              viewBox="0 0 21 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3757 0.327148L19.032 3.9834L16.2447 6.7719L12.5885 3.11565L15.3757 0.327148ZM0.750732 14.9375V18.5938H4.40698L14.5214 8.49399L10.8651 4.83774L0.750732 14.9375ZM0.750732 21.5H20.2507V24.6875H0.750732L0.750732 21.5Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="profile-nav mt-8 mb-auto">
      <ul class="pro-nav-list">
        <li class="nav-item flex items-center justify-between text-xl py-4 px-5 bg-secondary-01 cursor-pointer"
          v-for="item in nav" :key="item.id" @click="item.onClick">
          {{ item.name }}
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 10.5L5 6L0.5 1.5L2 -6.55671e-08L8 6L2 12L0.5 10.5Z"
              fill="white" />
          </svg>
        </li>
      </ul>
    </div>
    <div class="flex items-center justify-between py-4 px-5 bg-secondary-01 cursor-pointer mt-auto mb-0"
      @click="logout">
      <p class="text-xl">{{ t('my_profile.log_out') }}</p>
    </div>
  </StandardLayout>
</template>

<style lang="scss" scoped>
.profile-infor {
  .profile-item {
    padding-top: 1.75rem;

    &:first-child {
      padding-top: 1rem;
    }
  }
}

.profile-setting {
  h2 {
    font-size: 18px;
  }
}

.pro-setting-list {
  .setting-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
}

.pro-nav-list {
  .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
}
</style>
<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  status: {
    type: Boolean,
    default: false,
  }
});

defineEmits(['close']);

</script>

<template>
  <div class="modal-menu fixed top-0 left-0 w-full h-full z-20" :class="{ active: props.status }">

    <div class="bg-layer fixed bottom-0 bg-black z-10"></div>

    <nav class="menu flex flex-wrap justify-center items-center absolute z-20 w-full">
      <slot />
    </nav>

    <div class="close-modal flex items-center justify-center rounded-full absolute border z-20" @click="$emit('close')">
      <img src="/images/star-milestone/close.png" alt="close">
    </div>

  </div>
</template>

<style lang="scss" scoped>
.modal-menu {
  transform: translateY(100%);
  overflow: hidden;

  &.active {
    transform: translateY(0);

    .bg-layer {
      transform: scale(80);
    }

    .menu::v-deep {
      opacity: 1;
      right: 0;

      >* {
        transform: none;
        position: static;
        opacity: 1;
      }
    }

    .close-modal {
      opacity: 1;
    }
  }
}

.bg-layer {
  opacity: 0.85;
  border-radius: 50%;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  bottom: 69px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
}

.menu::v-deep {
  bottom: 140px;
  opacity: 0;
  transition: all 0.3s 0.3s;
  left: 50%;
  bottom: 150px;
  transform: translateX(-50%);

  >* {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: scale(0);
    transition: all 0.3s 0.3s;
    transform-origin: bottom center;
    opacity: 0;
  }

  .top & {
    top: 250px;
    flex-direction: row;
    flex-wrap: nowrap;
    bottom: auto;
  }
}

.close-modal {
  width: 40px;
  height: 40px;
  bottom: 69px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.8s;
  border-width: 2px;

  .top & {
    top: 180px;
    bottom: auto;
  }
}
</style>
import './bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { createStore } from 'vuex'
import moduleApp from '@store/app'
import moduleAuth from '@store/auth'
import { createI18n } from 'vue-i18n'
import * as enGB from '@/locales/en.json'
import * as zhHK from '@/locales/zh-hk.json'
import * as zhTW from '@/locales/zh-tw.json'

const appName = window.document.getElementsByTagName('title')[0]?.innerText;
const store = createStore({
    modules: {
        app: moduleApp,
        auth: moduleAuth,
    }
})

const i18n = createI18n({
    locale: 'tw',
    allowComposition: true, // you need to specify that!
    warnHtmlInMessage: 'off',
    messages: {
        en: enGB,
        'hk': zhHK,
        'tw': zhTW,
    }
})
window.$store = store
store.commit('setI18n', i18n)

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => require(`./Pages/${name}.vue`),
    setup({ el, app, props, plugin }) {
        return createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(store)
            .use(i18n)
            .mixin({ methods: { route } })
            .mount(el);
    },
});

// Set azure config for create MSAL instance.
InertiaProgress.init({ color: '#4B5563' });

<script setup>
import { computed, reactive, onMounted, ref, onUpdated } from "vue";
import { useStore } from "vuex";
import App from "@/Layouts/App.vue";
import MissionSlider from "@/Components/RealLeaf/MissionSlider.vue";
import MissionInstruction from "@/Components/RealLeaf/MissionInstruction.vue";
import EntryTracker from "@/Components/RealLeaf/EntryTracker.vue";
import AllPrizes from "@/Components/RealLeaf/AllPrizes.vue";
import Icons from "@/Components/Icons.vue";
import { useI18n } from "vue-i18n";
import ModalReward from "@/Components/RealLeaf/ModalReward.vue";
import getMemberRaffleList from "@/services/getMemberRaffleList";
import Modal from "@/Components/Modal/Modal.vue";
import { prepareAPIInfomation, isLoginRL } from "@tools/app";

const { t } = useI18n();
const isLoading = ref(false);
const showSms = ref(false);
const textSms = ref("");
const $store = useStore();
const modalIcon = ref("face-smile");

const state = reactive({
    firstEntry: 0,
    secondEntry: 0,
    thirdEntry: 0,
    checkWinner: false,
});

const showModalMsg = (text) => {
    showSms.value = true;
    textSms.value = text;
};

const callListRaffle = async () => {
    const list = await getMemberRaffleList();

    const { uuid } = await prepareAPIInfomation();
    if (list?.success) {
        const raffleList = list?.data?.raffles[uuid];
        state.firstEntry = raffleList[1] || 0;
        state.secondEntry = raffleList[2] || 0;
        state.thirdEntry = raffleList[3] || 0;

        const checkWinner = list?.data?.lucky_draw?.winner;
        state.checkWinner = checkWinner;
    } else {
        // showModalMsg(list?.message || t("load_fail"));
        console.log(list?.message || t("load_fail"));
    }
    isLoading.value = false;
};

const isLogin = () => {
    return isLoginRL($store.state.app.msal);
};

onMounted(() => {
});
</script>

<template>
    <App>
        <div class="mission">
            <div class="nav-mission">
                <div class="flex justify-center items-center">
                    <a class="icon-home w-5" href="/">
                        <Icons icon="home" />
                    </a>
                    <h2>{{ $t("real_leaf.title") }}</h2>
                </div>
            </div>
        </div>
        <div class="bg-level-02 rounded-2xl p-4 max-w-3xl mx-auto mt-8">
            <p class="my-4">原萃Ⓡ「喝日本茶 遊日本去」活動已於2022/11/15結束，感謝您的熱情參與。</p>
            <p class="my-4">本次活動得獎名單，可至【原萃】 <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=real-leaf" target="_blank" rel="noopener noreferrer" class="underline font-bold">LINE 官方帳號</a> 查詢。</p>
            <p class="my-4">探索更多Coke+任務，贏得更多獎勵！可口可樂 ZERO SUGERⓇ「<a href="/zs/landing/" class="underline font-bold">週週抽大獎</a>」活動倒數中，快來試手氣、抽大獎！​</p>
        </div>
    </App>
</template>
<style lang="scss" scoped>
.mission {
    background-color: #000;
    color: #fff;
    overflow: hidden;
    position: relative;
}

.nav-mission {
    position: relative;
    padding: 20px 0;

    .icon-home {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
    }

    h2 {
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        font-family: "TCCC-UnityHeadline", Helvetica, Arial, sans-serif;
    }
}
</style>

<script setup>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// const pagination = {
//   el: '.swiper-pagination',
//   clickable: true,
// }

defineProps({
    bonusProgress: {
        type: Number,
        default: 0,
    },
});

// const modules = [Pagination]
const onSwiper = (swiper) => {};
const onSlideChange = () => {
    console.log("slide change");
};
</script>

<template>
    <div class="mission-slider">
        <!-- <div class="swiper-pagination"></div> -->
        <swiper
            :slides-per-view="1"
            :space-between="50"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
        >
            <swiper-slide class="mission-slider-item">
                <!-- <div class="slide-content" style="background-image: url('/images/mission/bg-slide02.png');" > -->
                <div class="slide-content pb-4">
                    <img
                        class="mission-image mx-auto zoom-in-zoom-out"
                        src="/images/mission/mission-real-leaf.png"
                        alt=""
                    />
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<style lang="scss">
.mission-slider {
    .swiper-pagination {
        position: relative;
        display: flex;
        justify-content: center;
        bottom: 0;
        padding: 1rem 0;
    }

    .swiper-pagination-bullet {
        border: 1px solid rgba(255, 255, 255, 0.4);
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        background: #ffffff;
        box-shadow: 0px 0px 8px #ffffff;
        border: none;
    }
    .mission-slider-item {
        color: #fff;
    }

    .mission-image {
        width: calc(240 / 390 * min(100vw, 768px));
    }

    .slide-content {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center top;
        padding-top: 15px;
    }

    .star-progress-cotainer {
        transform: translateY(-100%);
    }

    .swiper-slide-active {
        .zoom-in-zoom-out {
            animation: zoom-in-zoom-out 2s ease-out;
        }
    }

    @keyframes zoom-in-zoom-out {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.1, 1.1);
        }
        100% {
            transform: scale(1, 1);
        }
    }
}
</style>

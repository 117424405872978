<script setup>
import { Head, Link } from '@inertiajs/inertia-vue3';
import StandardLayout from '@/Layouts/StandardLayout.vue';
import Button from '@/Components/Button.vue';
import InputTel from '@/Components/InputTel.vue';
import Input from '@/Components/Input.vue';
import Icons from '@/Components/Icons.vue'
import Checkbox from '@/Components/Checkbox.vue'
import SelectDropdown from '@/Components/SelectDropdown.vue'
import useValidate from '@vuelidate/core'
import { ref, computed, reactive } from 'vue'
import { required, helpers } from '@vuelidate/validators'
import { Inertia } from '@inertiajs/inertia'

const imageData = ref('')
const picked = ref([])
const arrGender = ref([])

const genders = reactive([
  {
    key: 'male',
    text: 'Male',
  },
  {
    key: 'female',
    text: 'Female',
  },
  {
    key: 'non-binary',
    text: 'Non-binary',
  },
  {
    key: 'other',
    text: 'Other',
  },
])


const state = reactive({
  name: '',
  gender: '',
  dob: '',
  email: '',
  phone: '',
  emailAd: false,
  smsAd: false,
  lineAd: false,
})

const updateSelect = event => {
  state.gender = event?.key || event?.text
}

const regValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const validEmailCheck = value => value.trim().match(regValidEmail)

const regValidPhone = /^[0-9]{8,10}$/
const validPhoneCheck = value => value.trim().match(regValidPhone)

const rules = computed(() => {
  return {
    name: {
      required: helpers.withMessage('Please enter your name', required),
    },
    gender: {
      required: helpers.withMessage('Please select your gender', required),
    },
    dob: {
      required: helpers.withMessage('Please enter date of birth', required),
    },
    email: {
      required: helpers.withMessage(
        'Please enter your e-mail address',
        required,
      ),
      validEmail: helpers.withMessage(
        'Please enter a valid e-mail address',
        validEmailCheck,
      ),
    },
    phone: {
      required: helpers.withMessage(
        'Please enter your mobile number',
        required,
      ),
      validPhone: helpers.withMessage(
        'Please enter a valid mobile number',
        validPhoneCheck,
      ),
    },
  }
})


const v$ = useValidate(rules, state)
const errors = computed(() => {
  return {
    name: v$.value?.name?.$errors[0]?.$message || '',
    gender: v$.value?.gender?.$errors[0]?.$message || '',
    dob: v$.value?.dob?.$errors[0]?.$message || '',
    email: v$.value?.email?.$errors[0]?.$message || '',
    phone: v$.value?.phone?.$errors[0]?.$message || '',
  }
})

const submit = () => {
  v$.value.$validate()
  console.log(state)
}

const previewImage = (event) => {
  let input = event.target
  if (input.files && input.files[0]) {
    // create a new FileReader to read this image and convert to base64 format
    var reader = new FileReader()
    // Define a callback function to run, when FileReader finishes its job
    reader.onload = (e) => {
      // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
      // Read image as base64 and set to imageData
      imageData.value = e.target.result
    }
    // Start the reader job - read file as a data url (base64 format)
    reader.readAsDataURL(input.files[0])
  }
}

const save = () => {
  Inertia.visit(route('member.myProfile'))
}

</script>

<template>

  <Head title="Register" />
  <StandardLayout :showBack="true">
    <div class="min-h-screen flex flex-col">
      <h1 class="font-tccc-head text-2xl font-bold">Edit Profile</h1>
      <div class="flex mt-10">
        <div class="image-upload">
          <div class="image-preview">
            <picture class="block w-14 h-14 rounded-full">
              <img class="preview" :src="
                imageData.length > 0 ? imageData : '/images/profile-photo.png'
              " alt="Profile Photo" />
              <!-- <img src="/images/profile-photo.png" alt="Profile Photo"> -->
            </picture>
            <div class="file-upload-form">
              <input type="file" @change="previewImage" accept="image/*" />
              <!-- <Icons icon="upload-photo" class="icon-upload" /> -->
            </div>
          </div>
        </div>
      </div>

      <div class="w-full mt-8 mb-12">
        <Input class="mt-5" label="Name" placeholder=" namnPhoebe Chen" :isError="v$.name.$error"
          :errorMsg="errors.name" v-model="state.name" />
        <SelectDropdown class="mt-5" label="Gender" :options="genders" @selectOption="updateSelect" />
        <Input class="mt-5" isIcon="birth-day" label="Month of Birth" placeholder="Enter date of birth"
          :isError="v$.dob.$error" :errorMsg="errors.dob" v-model="state.dob" />
        <Input class="mt-5" isIcon="email" label="E-mail" placeholder="Enter email" :isError="v$.email.$error"
          :errorMsg="errors.email" v-model="state.email" />
        <Input class="mt-5" isIcon="phone" label="Mobile Number" placeholder="0905088123" hasBtnValidator="true"
          :isError="v$.phone.$error" :errorMsg="errors.phone" v-model="state.phone">
        <template #btnValidator>
          <div class="flex items-center justify-between pl-1">
            <button
              class="h-11 flex items-center justify-center px-4 bg-white border border-transparent rounded-lg font-bold text-xl text-black focus:outline-none">
              Verify
            </button>
          </div>
        </template>
        </Input>
        <div class="mt-9">
          <div class="font-tccc-head text-base font-normal">
            I agree Coca-cola China Limited to use my name and contact details
            to send product information, service, offers, news, and promotion of
            brands of Coca-cola.
          </div>
          <div class="flex items-center mt-4">
            <div class="flex items-center">
              <input type="checkbox"
                class="apearance-none w-6 h-6 border-2 border-white rounded bg-transparent focus:outline-none checked:outline-none"
                value="email" id="cb_email" v-model="state.emailAd" />
              <label for="cb_email" class="ml-2">Email</label>
            </div>
            <div class="flex items-center ml-8">
              <input type="checkbox"
                class="apearance-none w-6 h-6 border-2 border-white rounded bg-transparent focus:outline-none checked:outline-none"
                value="sms" id="cb_sms" v-model="state.smsAd" />
              <label for="cb_sms" class="ml-2">SMS</label>
            </div>
            <div class="flex items-center ml-8">
              <input type="checkbox"
                class="apearance-none w-6 h-6 border-2 border-white rounded bg-transparent focus:outline-none checked:outline-none"
                value="sms" id="cb_line" v-model="state.lineAd" />
              <label for="cb_line" class="ml-2">LINE</label>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between py-6">
        <Button class="w-full" @click="save">save</Button>
      </div>
    </div>
  </StandardLayout>
</template>

<style lang="scss">
.min-h-screen  {
  padding: 0 20px;
}
.image-upload {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.image-preview {
  width: 3.5rem;
  position: relative;
}

.file-upload-form {
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: -1.5rem; //24px
    bottom: 0;
    opacity: 0;
    z-index: 2;
  }
}

.icon-upload {
  position: absolute;
  bottom: -0.5rem;
  right: -1rem; //24px
  width: 2rem; //26px
}

.profile-infor {
  .profile-item {
    padding-top: 1.75rem;

    &:first-child {
      padding-top: 1rem;
    }
  }
}

.gender-select {
  display: flex;
  justify-content: space-between;
}

.radio-item {
  display: flex;

  input {
    display: none;

    &:checked {
      +label {

        /* background-color: red; */
        &::after {
          /* transform: scale(1); */
          opacity: 1;
        }
      }
    }
  }

  label {
    position: relative;
    padding-left: 1.5rem;
    cursor: pointer;
    display: block;

    &:before {
      content: '';
      display: block;
      width: 1.25rem; //20px
      height: 1.25rem; //20px
      border: 1px solid #ffffff;
      border-radius: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::after {
      content: '';
      display: block;

      width: 0.8rem; //16px
      height: 0.8rem; //16px
      border-radius: 100%;
      position: absolute;
      top: 0.225rem;
      left: 0.225rem;
      background-color: #fff;
      transform-origin: center;
      transition: all ease-in-out 0.3s;
      /* transform: scale(0.5); */
      opacity: 0;
    }
  }
}

.has-btn-validator {
  display: flex;
}

.profile-setting {
  h2 {
    font-size: 18px;
  }
}

.pro-setting-list {
  .setting-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
}

.pro-nav-list {
  .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
}

.select-native {
  select {
    padding: 5px 0;
    width: 100%;
    border: none;
    color: #fff;
    outline: none;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;

    background: transparent url('/images/icons/arrow-right.svg');
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: right;
    background-origin: content-box;
    border-bottom: 1.5px solid #fff;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  option {
    color: #000;
  }
}
</style>

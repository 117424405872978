<script setup>
import Button from "@/Components/Button.vue";
import Icons from "@/Components/Icons.vue";
import { Head, Link, usePage } from "@inertiajs/inertia-vue3";
import { Inertia } from "@inertiajs/inertia";
import Modal from "@/Components/Modal/Modal.vue";
import StandardLayout from "@/Layouts/Standard.vue";
import Input from "@/Components/Input.vue";
import { ref, onMounted, computed, reactive } from "vue";
import { Html5Qrcode, Html5QrcodeScanner } from "html5-qrcode";
import useVuelidate from "@vuelidate/core";
import uploadReceipt from "@/services/uploadReceipt";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { pushGTM, prepareAPIInfomation } from "@tools/app";
// import { HK_OCP_TRACKING_CAMPAIGN_ID } from "@constants/main";

const $page = usePage();
const $store = useStore();
const isScaning = ref(false);
const loading = ref(false);
const showGuideModal = ref(true);
const showModal = ref(false);
const modalMessage = ref("");
const modalIcon = ref("face-smile");
const canvas = ref(null);
const inputFile = ref(null);
const { t } = useI18n();
const showResult = ref(false);
const capturedPicture = ref("");
const starEarn = ref(false);
let video, ctx, track;

const openCamera = () => {
    showGuideModal.value = false;
    loading.value = true;
    const mode = "environment";
    navigator.mediaDevices
        .getUserMedia({
            audio: false,
            video: {
                facingMode: mode,
            },
        })
        .then((stream) => {
            video = document.createElement("video");
            video.autoplay = true;
            video.playsInline = true;
            track = stream.getTracks()[0];
            if (track) {
                const setting = track.getSettings();
                const width = setting.width;
                const height = setting.height;
                const scale = window.innerWidth / width;
                video.width = width || window.innerWidth;
                video.height = height || window.innerHeight;
                canvas.value.width = width * (scale || 1);
                canvas.value.height = height * (scale || 1);
                video.srcObject = stream;
                isScaning.value = true;
                inputFile.value.value = "";
                video.play();
                scanLoop();
                loading.value = false;
            }
        })
        .catch((err) => {
            console.log(err);
            showModal.value = true;
            modalMessage.value = t("scan.camera_permission_denied");
            loading.value = false;
        });
};

const takePicture = () => {
    if (canvas.value) {
        isScaning.value = false;
        showResult.value = true;
        const dataUrl = canvas.value.toDataURL();
        capturedPicture.value = dataUrl;
        if (track) {
            track.stop();
        }
    }
};

const retakePicture = () => {
    showResult.value = false;
    openCamera();
};

const renderImageFromFile = () => {
    isScaning.value = false;
    showResult.value = true;
    if (track) {
        track.stop();
    }
    //
    const file = inputFile.value.files[0];
    if (file) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const base64 = reader.result;
            capturedPicture.value = base64;
        });
        reader.readAsDataURL(file);
    }
};

const submitPhoto = async () => {
    loading.value = true;
    let file = dataURItoBlob(capturedPicture.value);
    uploadReceipt(file).then(async (res) => {
        console.log(res);
        loading.value = false;
        const { uuid } = await prepareAPIInfomation();
        // pushGTM({
        //     event: "event_receiptupload",
        //     page_meta: "Camera",
        //     page_title: "Receipt",
        //     clickId: uuid,
        //     clickClasses: "member_receipt_uploads",
        //     clickStatus: res.success ? "success" : "fail",
        //     campaign_id: HK_OCP_TRACKING_CAMPAIGN_ID,
        //     submit_type: "receipt",
        // });
        if (res.success) {
            // case success
            // $store.dispatch("setOnlyEarnStar", {
            //     amount: res.data.member_earn_star_count || 0,
            // });
            // starEarn.value = true;
            return;
        }
        // case error
        showModal.value = true;
        modalMessage.value = res.message;
        retakePicture();
    });
};

const dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
        type: "image/jpeg",
        name: "receipt-generated.jpg",
    });
};

const scanLoop = () => {
    if (video && isScaning.value === true) {
        if (!ctx) {
            ctx = canvas.value.getContext("2d");
        }
        ctx.drawImage(video, 0, 0);
        ctx.drawImage(
            video,
            0,
            0,
            video.width,
            video.height,
            0,
            0,
            canvas.value.width,
            canvas.value.height
        );
        setTimeout(() => {
            scanLoop();
        }, 100);
    }
};
onMounted(() => {
});
</script>

<template>
    <StandardLayout :isLoading="loading" :showBack="true">
        <template #topBar>
            <Icons
                class="ml-auto mt-auto mb-auto mr-[20px] rounded-full w-[25px] h-[25px] bg-white flex justify-center items-center"
                icon="question-mark-small"
                @click="showGuideModal = true"
            />
        </template>
        <div class="min-h-screen flex flex-col">
            <h1 class="font-tccc-head text-2xl font-bold px-4">
                {{ $t("receipt.upload_receipt") }}
            </h1>
            <div class="flex mt-10 w-full h-full relative">
                <div v-show="!showResult" class="scanner">
                    <canvas id="canvas" ref="canvas"></canvas>
                </div>
                <div
                    v-show="!showResult"
                    class="functions fixed left-0 right-0 bottom-0 p-4"
                >
                    <div class="flex justify-between items-center">
                        <label>
                            <input
                                ref="inputFile"
                                class="hidden"
                                type="file"
                                accept="image/*"
                                @change="renderImageFromFile"
                            />
                            <Icons icon="pictures" />
                        </label>
                        <div class="screenshot" @click="takePicture"></div>
                        <div>
                            <Icons icon="flash-off" />
                        </div>
                    </div>
                </div>
                <div
                    v-show="showResult"
                    class="captured absolute left-0 top-0 w-full h-full p-4"
                >
                    <div class="bg-white rounded-lg flex flex-col py-8 px-4">
                        <h2
                            class="font-tccc-head text-2xl font-bold text-black"
                        >
                            {{ $t("receipt.good_to_go") }}
                        </h2>
                        <p class="text-sm text-black">
                            {{ $t("receipt.please_capture_receipt_in_full") }}
                        </p>
                        <div
                            class="img-preview pt-2 mt-2 w-full flex justify-center items-center"
                        >
                            <img class="w-1/2" :src="capturedPicture" />
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <Button
                            type="primary-sm"
                            class="mt-20 w-full"
                            @click="submitPhoto"
                            >{{ $t("receipt.upload") }}</Button
                        >
                        <Button
                            type="primary-sm"
                            class="mt-4 w-full"
                            @click="retakePicture"
                            >{{ $t("receipt.retake_photo") }}</Button
                        >
                    </div>
                </div>
            </div>
            <Modal type="dark" :show="showGuideModal" @close="openCamera">
                <div
                    class="flex flex-col w-full justify-center align-center text-left"
                >
                    <h2
                        class="font-tccc-head text-2xl font-bold text-black flex items-center gap-2"
                    >
                        <Icons class="icon" icon="question-mark" />
                        <span>{{ $t("receipt.take_photo_receipt") }}</span>
                    </h2>
                    <p class="text-sm text-black mt-2">
                        {{ $t("receipt.please_make_sure") }}
                    </p>
                    <p
                        class="text-sm text-black mt-2"
                        v-html="
                            $t('receipt.if_you_commit_camera_issue', [
                                route('faq'),
                            ])
                        "
                    ></p>
                    <div class="img-preview pt-2 mt-2 w-full flex flex-col">
                        <img class="w-full" src="/images/upload-receipt.png" />
                        <p class="text-sm text-black mt-2">
                            {{ $t("receipt.blurry_images") }}
                        </p>
                    </div>
                </div>
            </Modal>
            <Modal
                :icon="modalIcon"
                type="dark"
                :show="showModal"
                @close="showModal = false"
            >
                {{ modalMessage }}
            </Modal>
        </div>
    </StandardLayout>
</template>
<style lang="scss" scoped>
.screenshot {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    border-radius: 100%;
    background: #ffffff;
    position: relative;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        left: 6px;
        top: 6px;
        right: 6px;
        bottom: 6px;
        width: calc(100% - 12px);
        height: calc((100% - 12px));
        border-radius: 100%;
        border: 2px solid #000000;
    }
}
.functions {
    border-radius: 12px 12px 0 0;
}
.img-preview {
    border-top: 1px solid rgba(#000000, 0.08);
    img {
        max-height: 40vmax;
    }
}
.icon {
    width: 65px;
    height: 65px;
    flex: 0 0 65px;
    border-radius: 100%;
    background: #000000;
    align-items: center;
    display: flex;
    justify-content: center;
}
#canvas {
    width: 100%;
    height: auto;
}
</style>

<script setup>
import { Head, Link } from '@inertiajs/inertia-vue3';
import { Inertia } from "@inertiajs/inertia";
import StandardLayout from '@/Layouts/StandardLayout.vue';
import Button from '@/Components/Button.vue';
import { useStore } from 'vuex'
import { onMounted, ref, reactive } from 'vue';
import { useLocalStorage, msalClearAuthStatus} from '@tools/app';
import { useI18n } from 'vue-i18n';
import { usePage } from '@inertiajs/inertia-vue3';
import { CAMPAIGN_CURRENT } from "@constants/main";

const { t } = useI18n()
const $store = useStore()
const $page = usePage()
const $localStorage = useLocalStorage()
const isLoading = ref(true)
const currentCampaign = ref('')

const login = () => {
    msalClearAuthStatus()
    isLoading.value = true
    $store.state.app.msal.loginRedirect({
        authority: $store.state.app.azure.policyAuthorities.signUpSignIn.authority[currentCampaign.value],
        extraQueryParameters: {
            ui_locales: "zh-hant"
        },
    })
}

onMounted(() => {
    try {
        if ($page.props.value.mission === 'real-leaf') {
            currentCampaign.value = 'realLeaf'
        } else if ($page.props.value.mission === 'coke-zero') {
            currentCampaign.value = 'cokeZero'
        } else {
            Inertia.visit(route('ocp.mainDashboard'))
            return
        }
        $localStorage.set(CAMPAIGN_CURRENT, currentCampaign.value)
        login()
    } catch (error) {
        console.log(error)
        Inertia.visit(route('ocp.mainDashboard'))
    }
    // isLoading.value = false
})
</script>

<style scoped>

</style>

<template>

    <Head title="Register" />

    <StandardLayout :isLoading="isLoading" footer>
        <!-- <h1 class="font-tccc-head text-2xl font-bold px-5">{{t('sign_up_log_in')}}</h1>
        <p class="text-lg font-medium mt-1 px-5">{{t('sign_up_to_win_exclusive_rewards')}}</p>
        <p class="text-lg font-medium mt-1 px-5">{{t('icoke_members_can_use_the_same_account_details_to_log_in')}}</p>
        <div class="px-5 mt-auto mb-8 pb-8">
            <Button class="w-full" @click="login">{{t('continue_width_phone_number')}}</Button>
        </div> -->
    </StandardLayout>
</template>

import { useConsole, useLocalStorage, getBEToken, createUpdate, clearCookies } from '@tools/app';
import { LOCAL_STORAGE_ACCOUNT_ID_KEY_NAME, LOCAL_STORAGE_IDTOKEN_KEY_NAME, LOCAL_STORAGE_ACCESSTOKEN_KEY_NAME } from '@constants/localStorage';
import { ACTION_LOGOUT } from '@constants/main';

export default {
  // namespaced: true,
  state: () => ({
    msal_account: null,
    full_account: null,
    member: {
      user_id: null,
      kocid: null,
      email: null,
      name: null,
      family_name: null,
      given_name: null,
      mobile: null,
      birthday: null,
    },
  }),
  getters: {
    is_member_logged_in: (state) => {
      return !!state.member?.user_id
    },
  },
  mutations: {
    msalAccount(state, value) {
      state.msal_account = value;
    },
    fullAccount(state, value) {
      state.full_account = value;
    },
    setMemberInfo(state, value) {
      state.member.user_id = value.sub;
      state.member.kocid = value.kocid;
      state.member.email = value.email;
      state.member.name = value.name;
      state.member.mobile = value.mobile;
      state.member.birthday = value.birthday;
      state.member.family_name = value.family_name;
      state.member.given_name = value.given_name;
    },
    clearMemberInfo(state) {
      state.member.user_id = null;
      state.member.kocid = null;
      state.member.email = null;
      state.member.name = null;
      state.member.mobile = null;
      state.member.birthday = null;
      state.member.family_name = null;
      state.member.given_name = null;
    },
  },
  actions: {
    login({ commit, dispatch, state }, { msal, accountId, idToken, accessToken, utm, event }) {
      return new Promise(async (resolve, reject) => {
        const $console = useConsole();
        const $localStorage = useLocalStorage();
        // const $tracking = useTracking();
        const accounts = msal.getAllAccounts();
        let result = accounts === null || accounts.length === 0 ? false : true;

        // Set values in local storage for can re-login when refresh the page.
        $localStorage.set(LOCAL_STORAGE_ACCOUNT_ID_KEY_NAME, accountId);
        $localStorage.set(LOCAL_STORAGE_ACCESSTOKEN_KEY_NAME, accessToken);
        $localStorage.set(LOCAL_STORAGE_IDTOKEN_KEY_NAME, idToken);

        if (result) {
          const account = msal.getAccountByHomeId(accountId);
          dispatch('getMemberInfo', account)

          try {
            const beToken = await getBEToken()
            const res = await createUpdate(beToken.token, {
              member_uuid: account.idTokenClaims.sub,
              azure_auth_time: account.idTokenClaims.auth_time,
              member_kocid: account.idTokenClaims.kocid,
              status: "1",
              azure_verified: "1",
              activity_user_agent: window.navigator.userAgent,
              event: event || "login",
              language: "tw",
              utm
            })
            if (res.status === 'success') {
              resolve({ ...res.data, success: true })
            } else {
              resolve(res)
            }
          } catch (error) {
            console.log(error)
            reject({ message: 'Failed to login' });
            $localStorage.remove(LOCAL_STORAGE_ACCOUNT_ID_KEY_NAME);
            $localStorage.remove(LOCAL_STORAGE_ACCESSTOKEN_KEY_NAME);
            $localStorage.remove(LOCAL_STORAGE_IDTOKEN_KEY_NAME);
          }
        } else {
          reject({ message: 'Failed to login' });
          $localStorage.remove(LOCAL_STORAGE_ACCOUNT_ID_KEY_NAME);
          $localStorage.remove(LOCAL_STORAGE_ACCESSTOKEN_KEY_NAME);
          $localStorage.remove(LOCAL_STORAGE_IDTOKEN_KEY_NAME);
        }
      });
    },
    logout({ commit, state }, { msal, route }) {
      return new Promise(async () => {
        const $localStorage = useLocalStorage();
        // const $tracking = useTracking();

        // Clear account id from local storage.
        $localStorage.remove(LOCAL_STORAGE_ACCOUNT_ID_KEY_NAME);
        $localStorage.remove(LOCAL_STORAGE_ACCESSTOKEN_KEY_NAME);
        $localStorage.remove(LOCAL_STORAGE_IDTOKEN_KEY_NAME);
        $localStorage.set('action', ACTION_LOGOUT);

        // Log the user account out in MSAL instance.

        // Clear all MSAL data from VueX store.
        commit('clearMemberInfo');
        commit('fullAccount');
        commit('msalAccount');
        await clearCookies()
        msal.logout({
          postLogoutRedirectUri: route('ocp.mainDashboard')
        });
      });
    },
    getMemberInfo({ commit, state, rootState }, account) {
      const $localStorage = useLocalStorage();
      const $console = useConsole();
      commit('fullAccount', account);
      commit('setMemberInfo', account.idTokenClaims);
    },
    msalAccount({ commit, state, rootState }, { account }) {
      commit('msalAccount', account)
    },
  },
}
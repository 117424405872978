<script setup>
import { computed } from "vue";
const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  type: {
    default: 'default'
  }
});

const $emit = defineEmits(['clickOut', 'out'])
const classes = computed(() => {
  switch (props.type) {
    default:
    case 'default':
      return `bg-primary-02`
    case 'dark':
      return `bg-level-04`
  }
})
</script>

<template>
  <transition name="modal">
    <div class="fixed inset-0 w-full flex justify-center items-center bg-overlay p-5 z-[1002]" v-show="show" 
      @click="event => $emit('out', event)">
      <transition name="modal-container">
        <div class="w-96 max-w-full m-auto px-3 py-10 rounded-lg shadow-modal" v-show="show" :class="classes">
          <slot />
        </div>
      </transition>
    </div>
  </transition>
</template>

<style scoped>
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: all .5s ease;
}

.modal-container-enter-from,
.modal-container-leave-to {
  transform: scale(0.8);
  transition: all .3s ease;
}

.modal-container-enter-active,
.modal-container-leave-active {
  transition: all .3s cubic-bezier(.51, .92, .24, 1.25);
}
</style>
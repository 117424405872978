<script setup></script>
<template>
    <div class="tc-container">
        <h1 style="text-align: center">原萃開瓶掃碼週週抽活動辦法</h1>

        <div>
            <strong
                ><span style="text-decoration: underline"
                    >活動期間：</span
                ></strong
            >
        </div>
        <div>
            2022/10/05 00:00 至 2022/11/15 23:59 內
            (皆以主辦單位電腦系統時間為主)
        </div>
        <div>&nbsp;</div>
        <div>
            <span style="text-decoration: underline"
                ><strong>活動辦法：</strong></span
            >
        </div>
        <div>
            凡活動期間購買任一附有活動貼紙之金蓋原萃『原萃日式綠茶、綠茶-玉露入り、日式焙茶商品』，打開
        </div>
        <div>
            瓶蓋掃描 QR code，進入 Coke+平台並成功註冊成為
            Coke+會員後登錄序號，即可參加抽獎。
        </div>
        <div>&nbsp;</div>
        <div>
            <span style="text-decoration: underline"
                ><strong>活動平台／官方帳號搜尋方式：</strong></span
            >
        </div>
        <div>
            打開手機 LINE
            程式，在【官方帳號】項目下，搜尋【原萃】，加入並成為好友後，進入
            Coke+平台並
        </div>
        <div>成功註冊成為 Coke+會員即可參加活動。</div>
        <div>&nbsp;</div>
        <div>
            <span style="text-decoration: underline"
                ><strong>活動獎項：</strong></span
            >
        </div>
        <ol>
            <li>
                頭獎：日本旅遊奢華行程，市值 NTD$80,000 元，共 1 名
                抽獎門檻：於活動期間內，累積登錄 15 筆序號，即可獲得一次抽獎機會
            </li>
            <li>
                貳獎：日本機+酒-雙人自由行，市值 $60,000 元，共 1 名
                抽獎門檻：於活動期間內，累積登錄 5 筆序號，即可獲得一次抽獎機會
            </li>
            <li>
                三獎：日本雙人來回機票，市值 $40,000 元，共 4 名
                抽獎門檻：活動期間內，登錄 1 筆序號，即可獲得 1 次抽獎機會
            </li>
        </ol>
        <p>
            <span style="text-decoration: underline"
                >※每個 LINE 帳號，各獎項僅限中獎乙次，各獎項不得重複中獎。
            </span>
        </p>
        <p>
            <span style="text-decoration: underline"
                >※獎項將以【旅遊抵用券】形式發放。
            </span>
        </p>
        <p>
            <span style="text-decoration: underline"
                >※抽獎序號可累積直到中獎為止</span
            >
        </p>
        <p>
            <strong
                ><span style="text-decoration: underline"
                    >抽獎方式：</span
                ></strong
            >
        </p>
        <p>&nbsp;</p>
        <table>
            <tr>
                <td>週別</td>
                <td>登錄期間</td>
                <td>獎項</td>
                <td>抽獎日</td>
                <td>中獎名單公布日</td>
            </tr>
            <tr>
                <td>第一週</td>
                <td>2022/10/05-2022/10/11</td>
                <td>三獎-1 名</td>
                <td>2022/10/12(三)</td>
                <td>2022/10/19(三)</td>
            </tr>
            <tr>
                <td>第二週</td>
                <td>2022/10/05-2022/10/18</td>
                <td>三獎-1 名</td>
                <td>2022/10/19(三)</td>
                <td>2022/10/26(三)</td>
            </tr>
            <tr>
                <td>第三週</td>
                <td>2022/10/05-2022/10/25</td>
                <td>三獎-1 名</td>
                <td>2022/10/26(三)</td>
                <td>2022/11/02(三)</td>
            </tr>
            <tr>
                <td>第四週</td>
                <td>2022/10/05-2022/11/01</td>
                <td>三獎-1 名</td>
                <td>2022/11/02(三)</td>
                <td>2022/11/09(三)</td>
            </tr>
            <tr>
                <td>第五週</td>
                <td>2022/10/05-2022/11/08</td>
                <td>貳獎-1 名</td>
                <td>2022/11/09(三)</td>
                <td>2022/11/16(三)</td>
            </tr>
            <tr>
                <td>第六週</td>
                <td>2022/10/05-2022/11/15</td>
                <td>頭獎-1 名</td>
                <td>2022/11/16(三)</td>
                <td>2022/11/23(三)</td>
            </tr>
        </table>

        <p>
            <span style="text-decoration: underline"
                ><strong>中獎通知： </strong></span
            >
        </p>
        <p>中獎名單公布：【原萃 LINE 帳號】記事本</p>
        <p>中獎通知：將透過【原萃 LINE 帳號】推播中獎通知給中獎者</p>
        <p>
            <span style="text-decoration: underline"
                ><strong>旅遊抵用券使用方式：</strong></span
            >
        </p>
        <ol>
            <li>
                得獎者可憑旅遊兌換券依所得獎項金額能兌換（東南旅行社-日本線-台灣出發國際機票，日本訂
                房、日本旅遊行程）。
            </li>
            <li>
                國際機票兌換補充說明：限-台灣出發前往日本來回機票限:中華航空/華信航空/長榮航空/立榮航空/
                國泰航空/日本航空/全日空航空/台灣-日本線的航點均可以適用，廉價航空與包機除外，抵用券無
                法折抵稅金，若機票單價過低則需補齊稅金差。不適用東南官網機票有位最低價訂票系統，僅能透
                過專人訂票折抵使用。
            </li>
            <li>
                國內外訂房兌換補充說明：限東南網站訂房網站內可搜出來配合之飯店或民宿，若無合約則無法訂
                購。
            </li>
            <li>
                國外行程兌換補充說明：限東南旅行社包裝國外(日本)團體旅遊，東南 I
                自由包裝機+酒行程(票券
                不適用於國外機+酒自由配，聯營行程，航空假期，包機行程）
            </li>
            <li>
                本獎項將以東南旅行社等值旅遊劵贈出，每張旅遊券限乙次兌換完畢，不得兌換現金或找零，金額
                不足之部分請自行補足價差，多出之部分則視同放棄。
            </li>
            <li>旅遊券不得經由其他旅行同業報名參團。</li>
            <li>
                此旅遊券已開立旅行社專用收據「代收轉付」，兌換時不再另開立收據；惟補差額部分仍將開立
                「代收轉付」予客戶。
            </li>
            <li>
                本旅遊券採無記名式，兌獎人應妥善保管本抵用券，若有遺失、被竊、毀損或喪失抵用券之占有，
                恕無法補發，亦不得要求補償。
            </li>
            <li>
                本活動係主辦單位委由東南旅行社辦理，得獎者應自行與東南旅行社服務專員聯繫。本券兌換後如
                造成商品之糾紛時，與主辦單位無關。
                得獎者同意主辦單位無需為此負任何責任。亦不再另行補 發。
            </li>
            <li>詳細使用規範依兌換劵上條款為主。</li>
            <li>
                本兌換劵有效使用期限為 2023 年 10 月 05 日止，逾期不得兌換使用。
            </li>
        </ol>
        <p>
            <span style="text-decoration: underline"
                ><strong>活動須知：</strong></span
            >
        </p>
        <ol>
            <li>
                活動日期： 2022/10/05 00:00 至 2022/11/15 23:59 內
                (皆以主辦單位電腦系統時間為主)
            </li>
            <li>活動通路 : 全通路</li>
            <li>
                活動門檻：購買任一附有活動貼紙之金蓋原萃『原萃日式綠茶、綠茶-玉露入り、日式焙茶商品』
            </li>
            <li>
                購買日期與登錄日期不必為同一天（登錄日期須在本次活動期間內）。
            </li>
            <li>每個瓶蓋 QR code 序號僅限登錄乙次。</li>
            <li>
                瓶蓋 QR code 序號一經登錄後，不得要求以任何形式要求取消或刪除。
            </li>
            <li>
                本活動限設籍中華民國之本國公民參加，參加者必須年滿 13 足歲。
            </li>
            <li>
                本活動如因不可抗力之特殊原因以致活動無法執行、需調整相關活動日期/活動內容等，主辦單位有
                權決定取消、終止、暫停本活動或修改相關活動內容概要，如有變動皆以活動網站公告為主。
            </li>
        </ol>
        <p>
            <span style="text-decoration: underline"
                ><strong>兌獎流程： </strong></span
            >
        </p>
        <ul>
            <li>兌獎收件截止時間：2022 /11/30（以郵戳為憑）</li>
            <li>
                領獎信函下載：<a href="https://reurl.cc/qN0eLN"
                    >https://reurl.cc/qN0eLN
                </a>
            </li>
        </ul>
        <ol>
            <li>
                中獎者請自行下載領獎信函，將填妥後「領獎信函」+「身分證正反面影本」，於兌獎收件截止時間
                前將兌獎資料送達【10476 台北市中山區復興北路 368 號 5
                樓-原萃開瓶掃碼週週抽活動小組收】，
                逾期視為放棄該得獎權利，不得向主辦單位要求任何補償。
            </li>
            <li>
                本活動限設籍中華民國之本國公民始得參加。參加者必須年滿 13
                足歲。中獎者若未滿 20 足歲，除
                中獎者之身分證正反面影本外，需另附法定代理人之身分證正反面影本協同辦理領獎及一切相關手
                續方能領獎。
            </li>
            <li>
                依中華民國稅法規定，中獎價值在新台幣 1,001
                元以上者，需繳交身分證正反面影本供報稅使
                用，年度報稅時將計入個人所得。贈品價值超過 NTD$20,001
                元(含)以上者，依法需繳交 10％稅
                金。財政部臺北國稅局實施「憑單無紙化」，自 2014
                年起所得稅各式憑單符合一定情形者，憑單
                填發單位如期向國稅局辦理憑單申報後，原則上免寄發憑單給納稅義務人。中獎者需於隔年
                5 月底 前完成申報。
            </li>
            <li>中獎者資料經審核無誤後，獎項將於2022/12/22前寄送完成。</li>
            <li>
                本公司依得獎者提供之地址寄送獎項，如因個人因素導致寄送二次均無法順利送交，得獎者將被視為
                自動放棄兌獎權益，主辦單位將不再另行配送。未收到獎項或是獎項收到有問題者，請於2022/12/31
                前來電，逾期視同獎項收件無誤。
            </li>
            <li>
                活動詳情以活動網頁公告為準，或洽活動客服專線 02-2517-1326
                #13，服務時間週一～週五 10:00～17:00
                （國定例假日恕不提供接聽服務）。
            </li>
        </ol>
        <p>
            <span style="text-decoration: underline"
                ><strong>注意事項： </strong></span
            >
        </p>
        <ol>
            <li>
                本次活動主辦單位為香港商可口可樂有限公司台灣分公司及英屬維京群島商太古食品
                (股) 公司台
                灣分公司，並委託奧美廣告股份有限公司、尚藍資訊股份有限公司為活動協辦機構。主辦單位、活
                動協辦機構及配合廠商之員工均不得參加本活動，以示公允。
            </li>
            <li>參加者被理解為已閱讀，了解並同意本次促銷活動之條款和條件。</li>
            <li>
                參加者同意所有參加者所留存或產生之任何參與活動的資料及記錄，皆以主辦單位的電腦系統與時
                間的紀錄為主，如有任何因電腦、網路、電話、技術或不可歸責於主辦單位之事由，而使參加者所
                寄出或登錄之資料有延遲、遺失、錯誤、無法辨識或毀損之情況，主辦單位不負任何法律責任，參
                加者亦不得因此異議。
            </li>
            <li>
                參加者填寫或提出之資料應屬真實、正確且未冒用或盜用他人資料，如有不實或不正確資訊導致主
                辦單位無法聯絡參加者，則視同放棄兌換資格，主辦單位無需負任何責任；倘有損害主辦單位或其
                他任何第三方之行為，參加者尚應就此負一切民、刑事責任。
                Classified - Confidential
            </li>
            <li>
                主辦單位有權檢視參加者之活動參與行為是否涉嫌人為操作、蓄意偽造、短時間異常多筆參與行
                為、透過任何電腦程式參與活動、詐欺或以任何其他不正當的方式意圖以進行不實或虛偽活動參與
                行為，參加者因上述情形所獲得之活動資格及獎項，主辦單位保有取消得獎資格的權利，並保留法
                律追訴權。
            </li>
            <li>
                圖片僅供參考，活動獎項以實物為準。本活動之所有獎項不得轉換、轉讓或折換現金，主辦單位保
                留修改活動與活動獎項細節的權利，無須事前通知，並有權對本活動所有事宜作出解釋或裁決。如
                本活動因不可抗力之特殊原因無法執行時，主辦單位有權決定取消、終止、修改或暫停本活動，如
                有變動請以活動網站公告為主。
            </li>
            <li>
                參加者同意主辦單位對所有因領取或使用獎項之後果概無須負責，參加者須同意遵守各奬項供應商
                所列有關獎項之各項條款及細則，倘若發生產品瑕疵及相關保固問題，均由該奬項供應商維修與保
                固。
            </li>
            <li>
                除非法律另有要求，主辦單位不負責活動獎項/服務之品質或適用性，亦不會對任何人因使用活動獎
                項／服務而受傷或損失負責任。如對有關活動獎項或服務有任何爭議或投訴，客戶應直接聯絡有關
                供應商。所有活動獎項或服務之使用須受有關供應商所訂定之條款及細則約束（如適用）。
            </li>
            <li>
                參加者參加本活動之同時，將視為同意接受本活動所有內容及細則之規範，如有違反本活動注意事
                項之行為，活動小組得取消其兌換資格，並對於任何破壞本活動之行為保留法律追訴權。
            </li>
            <li>
                本活動網頁之所有內容由主辦單位擁有所有版權，未經許可，不可抄襲、拷貝與複製相關圖片與文
                字，主辦單位保留所有侵權事宜之法律追訴權利。
            </li>
            <li>
                主辦單位僅使用參加者所提供之個人資料於此次活動用途，且遵守【個人資料保護法】相關規定，
                以維護參加者權益。參加者參與本活動即同意相關個人資訊由主辦單位於活動範圍內進行蒐集、電
                腦處理及利用，但不做其他用途，參加者並授權主辦單位公開公佈部分姓名。
            </li>
        </ol>
    </div>
</template>

<style lang="scss" scoped>
.tc-container::v-deep {
    color: #9496a1;
    font-size: 14px;
    line-height: 22px;

    p,
    ul,
    ol,
    table {
        margin-bottom: 1rem;
        font-family: inherit;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul,
    ol {
        padding-left: 2rem;
    }

    ul li {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    a {
        word-break: break-all;
        text-decoration: underline;
    }

    table {
        width: 100%;

        td {
            border: 1px solid #9496a1;
            padding: 0.5rem;
        }
    }
}
</style>

<script setup>
import { Head, Link } from "@inertiajs/inertia-vue3";
import { computed, ref } from "vue";
import Icons from "../Icons.vue";
import ButtonPrimary from "@/Components/ButtonPrimary.vue";
import TooltipItem from "@/Components/Tooltips/TooltipItem.vue";
import ModalMenu from "@/Components/ModalMenu.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const $store = useStore();
const menuModal = ref(false);
const hasGift = localStorage.getItem("newPrize") || false;
const props = defineProps({});

const isLogin = computed(() => {
    return $store.getters.is_member_logged_in;
});
const userName = computed(() => {
    return `${$store.state?.auth?.member?.given_name || ""} ${
              $store.state?.auth?.member?.family_name || ""
          }`
});
const profileRedirectURL = computed(() => {
    return route("member.myProfile") 
});

const checkGiftRedirect = () => {
    localStorage.removeItem("newPrize");
    return route("myRewards");
};
</script>

<template>
    <div>
        <div class="top-bar">
            <div class="nav-profile">
                <div class="flex justify-between items-center">
                    <div>
                        <Link v-if="isLogin" :href="profileRedirectURL">
                            <div class="flex profile-name items-center">
                                <img
                                    class="w-10 h-10rounded-full"
                                    src="/images/profile-photo.png"
                                    alt="Profile Photo"
                                />
                                <div class="ml-2">
                                    <span>{{ userName }}</span>
                                </div>
                            </div>
                        </Link>

                        <div v-if="!isLogin" class="flex profile-name items-center">
                            <img
                                class="w-10 h-10rounded-full"
                                src="/images/profile-photo.png"
                                alt="Profile Photo"
                            />
                            <div class="ml-2">
                                <span>{{ t("unlogin") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="js--tourguide" style="display: none">
                    <div class="nav-tooltips">
                        <TooltipItem
                            class="left-arr"
                            v-html="t('tooltips.top_bar')"
                        ></TooltipItem>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.top-bar {
    color: white;
    background-color: #1d1d1d;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    position: relative;
    margin-bottom: 26px;

    &.activeEarnStar {
        padding-bottom: 10px;
    }
}

.nav-profile {
    position: relative;
    padding: 16px 20px 16px;
}

.profile-name {
    /* font-style: normal; */
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}

.hint {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    text-align: center;
}

btn-action {
    position: relative;
    z-index: 1;
}

.nav-tooltips {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    left: 0;
    display: flex;
    transform: translateY(calc(100% + 20px));
    padding: 0 9px;

    > .tool-tip {
        flex: 0 0 calc(50% - 12px);
        margin: 0 6px;
    }
}

.scan-tooltips {
    position: absolute;
    top: calc(100% + 50px);
    left: 0;
    padding: 0 20px;
}

.scan-types {
    > div {
        min-height: 200px;
        padding-left: 10px;

        &:first-child {
            border-right: 1px solid #5b5d6b;
            padding: 0 10px 0 0;
        }
    }

    h3 {
        font-size: 14px;
        font-weight: 700;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        padding-top: 10px;
    }

    span {
        margin-top: auto;
        font-weight: 400;
        font-size: 12px;
        /* or 150% */

        color: #8a8a8a;
    }
}

.has-gift {
    position: relative;
    &:before {
        content: "";
        display: block;
        background-color: #e41e2a;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: 0px;
    }
}

.nav-item {
    flex: 0 0 115px;
    margin: 10px 30px;

    &:first-child {
        margin: 10px 30%;
        .top & {
            margin: 10px 30px;
        }
    }

    .rounded-full {
        width: 80px;
        height: 80px;
        border-width: 2px;
        border: 2px solid #fff;
    }
}
</style>

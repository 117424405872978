<script setup>
import { ref, onMounted, reactive, computed } from "vue";
import { useStore } from "vuex";
import App from "@/Layouts/App.vue";
import TopBar from "@/Components/MainDashBoard/TopBar.vue";
import MissionSelect from "@/Components/MainDashBoard/MissionSelect.vue";
import Tooltips from "@/Components/Tooltips/Tooltips.vue";
import ModalBlank from "@/Components/Modal/ModalBlank.vue";
import Button from "@/Components/Button.vue";

const $store = useStore();
const isLoading = ref(false);
let apiLanguage = localStorage.getItem("locale") || "en";
apiLanguage == "zh-hk" ? (apiLanguage = "hk") : "";

const member = computed(() => {
    return $store.state?.auth?.member || {};
});

const isLogin = computed(() => {
    return $store.getters.is_member_logged_in;
});

const userCheck = computed(() => {
    return ~~route().params.isNewUser;
});

const store = useStore();
const state = reactive({
    count: 0,
    chest_info: {},
    next_chest: {},
    loading: true,
    memberId: "",
});

function runTourGuide() {
    const tourGuideRun = localStorage.getItem("tourGuideRun");
    if (!tourGuideRun) {
        showTour.value = true;
    }
}

onMounted(() => {
    runTourGuide();

    state.memberId = state.memberId || member.value.user_id;
    if (route().params?.code) {
        promptRewards.value = true;
    }
});

</script>

<template>
    <App footer>
        <div class="main-dash-board min-h-full">
            <TopBar />
            <MissionSelect />
        </div>
        <div
            v-if="isLoading"
            class="min-h-screen w-full flex justify-center items-center bg-primary-01 fixed z-50"
        >
            <picture class="block w-20">
                <img src="/images/loading.png" alt="" class="w-full" />
            </picture>
        </div>
    </App>
</template>

<style lang="scss" scoped>
.main-dash-board {
    background-color: #000;
}

.mission-reward-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 4;
}
</style>

<script setup>
import { ref } from "vue";

const props = defineProps({});
const emit = defineEmits(["change"]);
const activeTab = ref("tab1");
const changeTab = (e) => {
  const target = e.target;
  [...target.parentNode.children].forEach((c) => c.classList.remove("active"));
  target.classList.add("active");
  activeTab.value = target.href.split("#")[1];
  emit("change", activeTab.value);
};
</script>

<template>
  <div class="tabs flex flex-col w-full">
    <div class="tab-top flex items-center justify-around">
      <a class="active" @click.prevent="changeTab" href="#tab1">
        <slot name="tab1-title" />
      </a>
      <a @click.prevent="changeTab" href="#tab2">
        <slot name="tab2-title" />
      </a>
    </div>
    <div class="tab-content">
      <div v-if="activeTab == 'tab1'" id="tab1">
        <slot name="tab1-content" />
      </div>
      <div v-if="activeTab == 'tab2'" id="tab2">
        <slot name="tab2-content" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tab-top {
  height: 50px;
  background: #1d1d1d;

  a {
    color: #3a3a3a;
    border-bottom: 1px solid #3a3a3a;
    padding-bottom: 8px;
    flex: 0 0 40%;
    text-align: center;
    transition: all ease 0.3s;

    &.active {
      color: #ffffff;
      border-bottom: 1px solid #ffffff;
    }
  }
}
</style>

<script setup>
import { Head, Link } from "@inertiajs/inertia-vue3";
import { ref, onMounted, computed, onBeforeMount } from "vue";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import ButtonPrimary from "@/Components/ButtonPrimary.vue";
import TooltipItem from "@/Components/Tooltips/TooltipItem.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Icons from "../Icons.vue";
const { t } = useI18n();
const $store = useStore();
const firstItem = ref("");
const secondItem = ref("");

gsap.registerPlugin(MotionPathPlugin);

const isLogin = computed(() => {
    return $store.getters.is_member_logged_in;
});

const btnMission = (id) => {
    if (id === "item1") {
        return "red-bg";
    }
    if (id === "item2") {
        return "green-bg";
    }
    return;
};

const isSliding = ref(false);

const positions = ref({
    active: 0.415,
    secondActive: 0.58,
    inActive: 0.7,
    hidden: 1,
});

const active = ref(0);

const items = ref([
    {
        id: "item1",
        url: "locked",
        link: "#",
        position: 0.7,
        isActive: false,
        isLocked: true,
    },
    // {
    //     id: "item2",
    //     url: "mission-real-leaf",
    //     link: route("realLeaf.landing"),
    //     position: 0.7,
    //     showToolTip: true,
    //     isActive: true,
    //     isLocked: false,
    // },
    {
        id: "item3",
        url: "locked",
        link: "#",
        position: 0.7,
        isActive: false,
        isLocked: true,
    },
]);
const $el = ref(null);
const randomBoolen = ~~(Math.random() * 10) >= 5;

function setPosition() {
    const activeItem = items.value[active.value];
    const seconActiveItem = items.value[active.value + 1] || items.value[0];
    // const next = items.value[active.value + 2] || items.value[0];
    // firstItem.value = randomBoolen ? activeItem : seconActiveItem;
    // secondItem.value = !randomBoolen ? activeItem : seconActiveItem;

    console.log(active.value)
    console.log(items.value)

    move(activeItem, positions.value.active, 1);
    move(seconActiveItem, positions.value.inActive, 0.6);
    // move(next, positions.value.inActive);

    // items.value.forEach((item) => {
    //     if (firstItem.value.id === item.id) {
    //         item.order = 1;
    //     }
    //     if (secondItem.value.id === item.id) {
    //         item.order = 2;
    //     }
    //     if (item !== activeItem && item !== seconActiveItem && item !== next) {
    //         move(item, positions.value.hidden);
    //     }
    // });
}

function move(item, position, scale) {
    isSliding.value = true;
    gsap.to(`#${item.id}`, {
        duration: 1,
        ease: "power1.inOut",
        scale: scale || 0.4,
        motionPath: {
            path: ".js--eclipse",
            align: ".js--eclipse",
            alignOrigin: [0.3, 0.3],
            start: item.position,
            end: position,
        },
        onComplete: () => {
            isSliding.value = false;
            item.position = position < 0 ? 1 + position : position % 1;
        },
    });
}

function slide(margin) {
    if (isSliding.value) return;
    margin > 0 ? slideNext() : slidePrev();
}

function slideNext() {
    move(secondItem.value, positions.value.active,1);
    move(firstItem.value, positions.value.secondActive,0.6);
    items.value.forEach((item) => {
        if (firstItem.value.id === item.id) {
            item.order = 2;
        }
        if (secondItem.value.id === item.id) {
            item.order = 1;
        }
    });
}

function slidePrev() {
    move(firstItem.value, positions.value.active ,1);
    move(secondItem.value, positions.value.secondActive,0.6);
    items.value.forEach((item) => {
        if (firstItem.value.id === item.id) {
            item.order = 1;
        }
        if (secondItem.value.id === item.id) {
            item.order = 2;
        }
    });
}

function onScroll(deltaY) {
    // slide(deltaY > 0 ? 1 : -1);
}

function registerEvent() {
    let touchY;

    $el.value.addEventListener(getWheelEvent($el.value), (e) => {
        const deltaY = e.deltaY || -e.wheelDelta;
        onScroll(deltaY);
    });

    $el.value.addEventListener("touchstart", (e) => {
        touchY = e.touches[0].clientY;
    });

    $el.value.addEventListener("touchend", (e) => {
        const deltaY = -(e.changedTouches[0].clientY - touchY);
        if (deltaY === 0) return;
        onScroll(deltaY);
    });
}

// detect available wheel event
function getWheelEvent(el) {
    if ("onwheel" in el) {
        return "wheel";
    } else if (el.onmousewheel !== undefined) {
        return "mousewheel";
    } else {
        return "DOMMouseScroll";
    }
}
onMounted(() => {
    setPosition();
    registerEvent();
});
</script>

<template>
    <div class="viewport" ref="$el">
        <svg
            class="path-line"
            viewBox="0 0 365 362"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                class="js--eclipse"
                d="M694.312 279.828C700 259.96 696.236 238.153 684.096 215.547C671.948 192.924 651.497 169.685 624.199 147.203C569.609 102.245 488.092 60.6898 392.575 33.344C297.057 5.99831 205.899 -1.88117 135.788 7.37615C100.728 12.0055 71.0777 20.9009 48.7973 33.6673C26.5339 46.424 11.7993 62.9353 6.11126 82.8036C0.423184 102.672 4.1875 124.479 16.327 147.085C28.4757 169.708 48.9261 192.947 76.2248 215.429C130.814 260.387 212.331 301.942 307.849 329.288C403.367 356.634 494.525 364.513 564.635 355.256C599.696 350.627 629.346 341.731 651.626 328.965C673.89 316.208 688.624 299.697 694.312 279.828Z"
                stroke="url(#paint0_angular_2878_49705)"
                stroke-opacity="0.2"
                stroke-width="6"
            />
            <defs>
                <radialGradient
                    id="paint0_angular_2878_49705"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(350.212 181.316) rotate(-74.0241) scale(156.917 360.924)"
                >
                    <stop stop-color="#82E1FF" />
                    <stop offset="0.171236" stop-color="#78E6A0" />
                    <stop offset="0.353531" stop-color="#FFB400" />
                    <stop offset="0.520201" stop-color="#FF8700" />
                    <stop offset="0.666037" stop-color="#FF5A00" />
                    <stop offset="0.832706" stop-color="#F40000" />
                    <stop offset="1" stop-color="#82E1FF" />
                </radialGradient>
            </defs>
        </svg>

        <div
            v-for="item in items"
            :key="item.id"
            :id="item.id"
            class="item-container"
            :class="{
                active: item.isActive,
                first: item.order === 1,
                second: item.order === 2,
            }"
        >
            <div class="item-content">
                <a v-if="item.isActive" :href="item.link">
                    <img :src="`/images/mission/${item.url}.png`" alt="" />
                    <ButtonPrimary
                        clss="btn-primary"
                        :class="btnMission(item.id)"
                    >
                        {{ $t("play_now") }}
                    </ButtonPrimary>
                    <div v-if="item.isLocked" class="lock-zone">
                        <Icons icon="lock" class="lock-icon" />
                        <span v-html="t('lock_campaign')"></span>
                    </div>
                </a>
                <div v-else class="item-comming-soon">
                    <img :src="`/images/mission/${item.url}.png`" alt="" />
                    <p class="text-comming-soon">
                        <span>{{ $t("coming_soon") }} </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="js--tourguide" style="display: none">
            <div class="mission-tooltips">
                <TooltipItem v-html="t('tooltips.campaign')"></TooltipItem>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.st0 {
    fill: none;
    stroke: url(#SVGID_1_);
    stroke-width: 6;
    stroke-opacity: 0.2;
}
.viewport {
    width: 100%;
    /* max-width: 480px; */
    margin: auto;
    overflow: hidden;
    /* transform: translate3d(0, 0, 0); */
    padding: calc(175 / 390 * min(100vw, 768px)) 0 15% 5%;
    position: relative;

    &.activeElementTour {
        &:before {
            content: "";
            position: absolute;
            top: 25%;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.7);
        }

        .item-container {
            z-index: -1;

            &.active {
                z-index: 1;
            }
        }
    }
}

.path-line {
    width: 100%;
    left: 0;
    top: 0;
}

.item-container {
    /* width: 66.66666%; */
    width: calc(260 / 390 * min(100vw, 768px));
    height: calc(260 / 390 * min(100vw, 768px));
    position: absolute;

    /* .circle {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  } */

    .item-content {
        transform: translateY(-9%);
    }

    .btn-primary {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        min-width: 160px;
        box-shadow: 0px 0px 12px #ffffff;
    }
}

.btn-primary {
    // display: none;
}
.first.item-container {
    .item-content {
        transform: translateY(-34%);
    }

    .btn-primary {
        display: block;
    }
}
.second.item-container {
    .item-content {
        transform: translateY(-2%);
    }
}

.item-comming-soon {
    img {
        width: 100%;
    }
}

.mission-tooltips {
    position: absolute;
    top: 62%;
    left: 0;
    padding: 0 20px;
    width: 100%;

    .tool-tip {
        padding: 12px 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        z-index: 2;
    }
}

.text-comming-soon {
    position: absolute;
    /* left: -3%; */
    bottom: 32%;
    /* transform: translateX(-50%); */
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 23px;
    /* line-height: 130%; */
    /* span {
    display: inline-block;
    width: 50%;
  } */
}

.lock-zone {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .lock-icon {
        width: calc(26.6 / 390 * min(100vw, 768px));
        padding-bottom: 5px;
    }

    span {
        display: inline-block;
        width: calc(144 / 390 * min(100vw, 768px));
        font-weight: 700;
        font-size: 16px;
        text-align: center;
    }
}
</style>

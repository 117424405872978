<template>
  <div class="tool-tip">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
  .tool-tip {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(9px);
    border-radius: 24px;
    padding: 12px 10px;
    color: #000;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    position: relative;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 16px 8px;
      border-color: transparent transparent #ffffff transparent;

      position: absolute;
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
    }

    &.left-arr {
      &:before {
        left: 16px;
        transform: none;
      }
    }

    &.right-arr {
      text-align: right;
      &:before {
        left: auto;
        right: 16px;
        transform: none;
      }
    }
  }
</style>

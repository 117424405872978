<script setup>
import { onMounted, ref, computed } from 'vue';
import Icons from './Icons.vue';

defineProps(['modelValue', 'label', 'placeholder', 'disabled', 'type', 'isError', 'errorMsg', 'isIcon', 'hasBtnValidator']);

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

const classInput = computed(() => {
    return `w-full border-b border-white bg-transparent focus:outline-0 bg-transparent font-normal px-0 py-1`
})
</script>

<template>
    <div :class="{'text-disabled': disabled, 'input-icon': isIcon}">
        <label v-if="label" class="text-xs font-md">{{ label }}</label>
        <div :class=" `input-wrapper ${hasBtnValidator ? 'has-btn-validator' : ''}`">
            <div v-if="isIcon" :class="{'icon-svg' : isIcon }"><Icons :icon="isIcon"/></div>
            <input :type="type" :class="classInput" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
                :placeholder="placeholder || label" :disabled="disabled" ref="input">
            <slot name="btnValidator"/>
        </div>
        <div v-if="isError && errorMsg" class="text-xs text-error mt-1">{{ errorMsg }}</div>

    </div>
</template>

<style lang="scss" scoped>
    .input-icon {
        position: relative;
        /* padding-left: 20px; */

        .icon-svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 1rem;
            left: 0.25rem;
            display: block;
        }

        input {
            padding-left: 1.75rem;
        }

        .input-wrapper {
            position:relative;
        }
    }
</style>

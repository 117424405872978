import { useLocalStorage } from '@tools/app';

const $localStorage = useLocalStorage()

export default {
  // namespaced: true,
  state: () => ({
    version: process.env.MIX_GIT_VERSION,
    is_app_ready: false,
    is_mobile: false,
    msal: null,
    current_campaign: null,
    azure: {
      config: {
      },
      logoutUrl: null,
      credential: null,
      policyBaseUrl: null,
      oauthScopesUrl: null,
      policyAuthorities: {
        signUpSignIn: { authority: {} },
        forgotPassword: { authority: null },
        changePassword: { authority: null },
        editProfile: { authority: null },
        signUp: { authority: null },
      },
      loginRequest: { scopes: [] },
    },
    api_auth: {
      api_auth_email: null,
      api_auth_password: null
    }
  }),
  getters: {
    currentLocale: (state) => {
      return state.i18n?.global?.locale
    },
    apiAuth: (state) => {
      return state.api_auth
    },
    isAppReady: (state) => {
      return state.is_app_ready
    },
    getCurrentCampaign(state) {
      return state.current_campaign
    }
  },
  mutations: {
    appReady(state, value) {
      state.is_app_ready = value;
    },
    isMobile(state, value) {
      state.is_mobile = value;
    },
    setCampaign(state, value) {
      state.current_campaign = value
    },
    setAzureConfig(state, config) {
      state.azure.config = config
      state.azure.logoutUrl = config.redirect
      state.azure.policyBaseUrl = config.policyBaseUrl || `https://${config.tenantName}.b2clogin.com/${config.tenantName}.onmicrosoft.com`;
      state.azure.oauthScopesUrl = `https://${config.tenantName}.onmicrosoft.com/oauth-service/oauth.coke`;
      Object.keys(config.policyNames.signUpSignIn).forEach(key => {
        state.azure.policyAuthorities.signUpSignIn.authority[key] = `${state.azure.policyBaseUrl}/${config.policyNames.signUpSignIn[key]}`
      })
      state.azure.policyAuthorities.editProfile.authority = `${state.azure.policyBaseUrl}/${config.policyNames.editProfile}`
      state.azure.loginRequest.scopes = [state.azure.oauthScopesUrl];
    },
    azureLogoutUrl(state, url) {
      state.azure.logoutUrl = url;
    },
    azureSignUpSignInExtraQueryParameters(state, value) {
      state.azure.policyAuthorities.signUpSignIn.extraQueryParameters = value;
    },
    azureChangePasswordExtraQueryParameters(state, value) {
      state.azure.policyAuthorities.changePassword.extraQueryParameters = value;
    },
    azureForgotPasswordExtraQueryParameters(state, value) {
      state.azure.policyAuthorities.forgotPassword.extraQueryParameters = value;
    },
    setMsal(state, value) {
      state.msal = value
    },
    setI18n(state, value) {
      state.i18n = value
    },
    setLocale(state, value) {
      state.i18n.global.locale = value
      $localStorage.set('locale', value)
    },
    setApiAuth(state, value) {
      state.api_auth.api_auth_email = value.api_auth_email
      state.api_auth.api_auth_password = value.api_auth_password
    }
  },
  actions: {
    checkIfIsMobile({ commit }) {
      commit('isMobile', document.body.clientWidth <= 768);
    },
    setCampaign({ commit }, value) {
      commit('setCampaign', value);
    },
    setMsal({ commit }, msal) {
      commit('setMsal', msal);
    },
    setLocale({ commit }, value) {
      commit('setLocale', value);
    },
    setApiAuth({ commit }, value) {
      commit('setApiAuth', value);
    },
    appReady({ commit }, value) {
      commit('appReady', value);
    },
  }
};

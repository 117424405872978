<script setup>
import { ref, computed } from "vue";
import { Head, Link } from "@inertiajs/inertia-vue3";
import ButtonPrimary from "@/Components/ButtonPrimary.vue";
import Icons from "@/Components/Icons.vue";
import TermsCoinditions from "@/Components/RealLeaf/TermsCoinditions.vue";
import { useI18n } from "vue-i18n";
import { RGB_CAMPAIGN_LICENCE } from "@constants/main";

const { t } = useI18n();
const isShowAllPrizes = ref(false);
const isShowTerm = ref(false);
function showAllPrizes() {
    isShowAllPrizes.value = true;
}
function showAllTerm() {
    isShowTerm.value = !isShowTerm.value;
}

const prizeNewSrc = computed(() => {
    return t("real_leaf.prizes_new");
});
</script>

<template>
    <div class="text-center pt-3 pb-3" v-if="!isShowAllPrizes">
        <a class="btn-explore" @click="showAllPrizes"
            >{{ $t("real_leaf.see_all_prizes") }}
            <span class="scroll-icon"
                ><span class="unu w-2"><Icons icon="arr-down" /></span>
                <span class="doi w-2"><Icons icon="arr-down" /></span></span
        ></a>
        <!-- <div class="text-center text-[12px] font-tccc opacity-50 mt-2">
            {{ t("licence", [RGB_CAMPAIGN_LICENCE]) }}
        </div> -->
    </div>
    <div class="allprizes-section" v-if="isShowAllPrizes">
        <img
            :src="`/images/mission/${prizeNewSrc}.jpg`"
            class="mx-auto mt-4"
            alt="All Prizes"
        />
        <p class="pt-4 px-4 text-[14px]" v-html="t('real_leaf.rule')"></p>
        <div class="notice px-4 p-4">
            <h3>
                <span>{{ $t("real_leaf.mission_information") }}</span>
            </h3>
            <p class="flex justify-center items-center text-center py-4">
                <Icons icon="time-white" class="w-4 mr-2" />2022.10.05 - 11.15
            </p>
            <ol class="list-decimal ml-4" v-html="$t('real_leaf.steps')"></ol>
            <!-- <p class="pt-4">{{ $t("licence", [RGB_CAMPAIGN_LICENCE]) }}</p> -->
            <!-- <p class="pt-4">*指定商戶包括各大餐廳食店、指定便利店與零售點或太古可口可樂香港e-Shop</p> -->
        </div>
        <a :href="route('realLeaf.tnc')" class="mt-2 p-4 link-tnc relative w-full block">{{ $t("real_leaf.tnc") }}</a>
    </div>
</template>

<style lang="scss" scoped>
.link-tnc {
    background:#1d1d1d;
    &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        display: block;
        top: 45%;
        right: 20px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg) translateY(-50%);
    }
}

.btn-explore {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #b3b5bd;
    display: inline-block;
}

.btn-view-more {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #b3b5bd;
    display: inline-block;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;

    .show-term & {
        .scroll-icon {
            transform: rotate(180deg);
        }
    }
}

.scroll-icon {
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
    width: 8px;
    transform-origin: center;
    transition: rotate ease-in-out 0.3s;
    span {
        display: block;
        margin-bottom: -1px;
    }

    .doi {
        margin-bottom: 0;
    }

    .unu,
    .doi {
        animation: mouse-scroll 1s infinite;
    }

    .unu {
        animation-delay: 0.1s;
        animation-direction: alternate;
    }

    .doi {
        animation-delay: 0.2s;
        animation-direction: alternate;
    }
}

.allprizes-section {
    h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        padding: 0 20px;
        &:after {
            content: "";
            display: block;
            height: 2px;
            background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 50.42%,
                rgba(255, 255, 255, 0) 96.8%
            );
        }

        span {
            display: inline-block;
            padding: 0 8px 8px 8px;
        }
    }
}

.txt-above-cta {
    width: calc(275 / 390 * min(100vw, 768px));
}

.notice {
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }
}

.star-tuts {
    margin: 0 16px;

    .inner {
        background: #292929;
        border-radius: 16px;
        padding: 15px 0;
    }

    h4.hl {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        background: linear-gradient(101.66deg, #ffd600 13.47%, #ff4d00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #b3b5bd;
    }
}

.term {
    position: relative;
    .content {
        height: 140px;
        overflow-y: scroll;
        padding-bottom: 20px;
        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 70%;
            background: linear-gradient(
                to top,
                rgb(0, 0, 0) 0%,
                rgb(0, 0, 0) 20%,
                rgba(0, 0, 0, 0.54) 57%,
                rgba(0, 0, 0, 0) 100%
            );
        }
    }
    &.show-term .content {
        height: auto;
        &:after {
            display: none;
        }
    }
    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #9496a1;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #b3b5bd;
    }
}

@keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
</style>

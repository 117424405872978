<script setup>
import Icons from '@/Components/Icons.vue';
import { computed } from 'vue';
import App from './App.vue';

const prop = defineProps({
    showBack: {
        type: Boolean,
        default: false
    },
    showNav: {
        type: Boolean,
        default: true
    },
    headTitle: {
        type: String,
        default: ''
    },
    isLoading: {
        type: Boolean,
        default: false
    }
})

const back = () => {
    window.history.back()
}

console.log(route().params)

const navBarStyle = computed(() => {
    return prop.headTitle && "nav-fixed"
})

</script>
<style scoped lang="scss">
$cBlack: #191919;
$cWhite: #FFFFFF;

.ocp-nav {
    flex: 0 0 auto;
    min-height: 35px;
}

.ocp-page {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
}

.btn-back {
    width: 35px;
}

.nav-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 53px;
    background: #1d1d1d;
    z-index: 20;
    box-shadow: 0 0 20px #222;

    h2 {
        position: absolute;
        top: 13px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        max-width: 80%;
    }

    .btn-back {
        position: absolute;
        top: 10px;
        left: 12px;
    }
}

h2 {
    font-size: 17px;
}
</style>
<template>
    <App>
        <div class="ocp-nav pt-4 flex flex-nowrap" v-if="showNav" :class="navBarStyle">
            <Icons v-if="showBack" class="btn-back ml-3" icon="arrow-left" @click="back" />
            <h2 v-if="headTitle" class="font-tccc font-bold">{{ headTitle }}</h2>
            <slot name="topBar" />
        </div>
        <div class="ocp-page">
            <slot />
        </div>
        <div v-if="isLoading" class="min-h-screen w-full flex justify-center items-center bg-primary-01 fixed z-50">
            <picture class="block w-20">
                <img src="/images/loading.png" alt="" class="w-full">
            </picture>
        </div>
    </App>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps({
    firstEntry: {
        type: Number,
        default: 0,
    },
    secondEntry: {
        type: Number,
        default: 0,
    },
    thirdEntry: {
        type: Number,
        default: 0,
    },
    checkWinner: {
        type: Boolean,
        default: false,
    },
});

</script>

<template>
    <div class="cap-tracker-container relative">
        <div class="absolute left-4 top-2 note text-[12px]">
            {{ t("real_leaf.note") }}
        </div>
        <h3 class="text-center text-[12px] font-bold">
            {{ props.checkWinner ? t("real_leaf.winner_note") : t("real_leaf.tracker", [props.thirdEntry]) }}
        </h3>
        <div class="flex justify-center text-center mt-3">
            <div class="cap-item">
                <div class="relative">
                    <img
                        src="/images/mission/third-entry.png"
                        alt="third"
                        v-if="!props.checkWinner"
                    />
                    <img
                        src="/images/mission/third-entry-g.png"
                        alt="third"
                        v-if="props.checkWinner"
                    />
                    <span
                        class="absolute bottom-0 right-0 rounded-full text-[12px] w-5 h-5 flex justify-center bg-white text-black font-bold font-tccc"
                        >{{ props.firstEntry }}</span
                    >
                </div>
                <p
                    class="text-[10px] mt-2"
                    v-html="t('real_leaf.third_entry')"
                ></p>
            </div>
            <div class="cap-item">
                <div class="relative">
                    <img
                        src="/images/mission/second-entry.png"
                        alt="second"
                        v-if="!props.checkWinner"
                    />
                    <img
                        src="/images/mission/second-entry-g.png"
                        alt="second"
                        v-if="props.checkWinner"
                    />
                    <span
                        class="absolute bottom-0 right-0 rounded-full text-[12px] w-5 h-5 flex justify-center bg-white text-black font-bold font-tccc"
                        >{{ props.secondEntry }}</span
                    >
                </div>
                <p
                    class="text-[10px] mt-2"
                    v-html="t('real_leaf.second_entry')"
                ></p>
            </div>
            <div class="cap-item">
                <div class="relative">
                    <img
                        src="/images/mission/first-entry.png"
                        alt="first"
                        v-if="!props.checkWinner"
                    />
                    <img
                        src="/images/mission/first-entry-g.png"
                        alt="first"
                        v-if="props.checkWinner"
                    />
                    <span
                        class="absolute bottom-0 right-0 rounded-full text-[12px] w-5 h-5 flex justify-center bg-white text-black font-bold font-tccc"
                        >{{ props.thirdEntry }}</span
                    >
                </div>
                <p
                    class="text-[10px] mt-2"
                    v-html="t('real_leaf.first_entry')"
                ></p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.note {
    color: #9e9e9e;
}
.bg-layer-star {
    opacity: 0.85;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
}
.cap-tracker-container {
    padding: 8px 6px;
    margin: 0 15px;
    background: #2c2c2c;
    border-radius: 16px;
}
.cap-item {
    margin: 0 1.125rem;
    position: relative;

    .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        color: #8a8a8a;
    }

    .amount {
        position: absolute;
        width: 1.125rem;
        height: 1.125rem;
        right: 4px;
        bottom: 4px;
        background-color: #fff;
        border-radius: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 11px;
        line-height: 1.125rem;
        color: #000;
    }
}

.cap-active {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.burst-wrapper {
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.burst-inner {
    animation: burst-scale 1s ease-in-out;
    width: calc(455 / 390 * min(100vw, 768px));
    height: calc(455 / 390 * min(100vw, 768px));
    transform-origin: center;
}

.burst {
    position: static;
    width: calc(455 / 390 * min(100vw, 768px));
    height: calc(455 / 390 * min(100vw, 768px));
    max-width: none;
    animation: burst-reward-rotate 18s cubic-bezier(0.47, -0.54, 0.54, 0.56)
        infinite;
}

@keyframes burst-scale {
    0% {
        transform: scale(0.3);
    }

    100% {
        transform: scale(1);
    }
}
@keyframes burst-reward-rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
